import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Progress,
    CustomInput
} from 'reactstrap';
import Select from "../../../components/properlytics/select";
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getDefaultCapitalGrowth,
    getDefaultInflation,
    getSelectedYear,
    getSelectedYearsOption,
    getLoanType,
    getEntityId,
    getTypeOfProperty
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import config from "config/config";
import {
    formatMoney,
    getNotificationObject,
    setEconomicTabState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import ExtraRepayment from "../sections/extra-repayment";
import PercentageField from "../../../components/numbers/PercentageField";
import axios from "../../../services/axios";
import ReactTooltip from 'react-tooltip';
import RefreshProjection from "../../../components/properlytics/refresh-projection";

export default class EconomicAssumption extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        this.state = {
            is_edit: false,
            property_id: '',
            capital_growth_rate: getDefaultCapitalGrowth(),
            inflation: getDefaultInflation(),
            year1: '',
            year2: '',
            year3: '',
            year4: '',
            year5: '',
            year10: '',
            selected_year: getSelectedYear().value,
            selected_price: 0,
            has_rental_income: true,
            texable_income: true,
            living_expenses: true,
            price: '',

            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            interest_term: 5,
            to_year: 30,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            interest_term_back: 5,
            to_year_back: 30,
            loan_type_back: getLoanType().value,
            loans: [],

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            redirect_to_dashboard: false,

            is_existing_property: true,
            refreshLoading: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onYearChange = this.onYearChange.bind(this);
        this.onCheckBoxClick = this.onCheckBoxClick.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.calculateAmount = this.calculateAmount.bind(this);
        this.calcAmount = this.calcAmount.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.refreshProjection = this.refreshProjection.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        if (!isNaN(event.target.value) || event.target.value === '') {
            this.setState({
                [event.target.name]: event.target.value
            });

            if (event.target.name === 'capital_growth_rate') {
                this.calculateAmount(this.state.price, event.target.value, this.state.selected_year);
            }
        }
    }

    onYearChange(selected) {
        this.setState({
            selected_year: selected.value,
        });
        this.calculateAmount(this.state.price, this.state.capital_growth_rate, selected.value);
    }

    onCheckBoxClick(event) {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    calculateAmount(amount, rate, selected_value) {
        this.setState({
            year1: '$' + this.calcAmount(amount, rate, 1),
            year2: '$' + this.calcAmount(amount, rate, 2),
            year3: '$' + this.calcAmount(amount, rate, 3),
            year4: '$' + this.calcAmount(amount, rate, 4),
            year5: '$' + this.calcAmount(amount, rate, 5),
            year10: '$' + this.calcAmount(amount, rate, 10),
            selected_price: '$' + this.calcAmount(amount, rate, selected_value),
        })
    }

    calcAmount(amount, rate, year) {
        amount = parseFloat(amount);
        for (let i = 1; i <= year; i++) {
            amount = amount + (amount * rate) / 100;
        }
        return formatMoney(amount, 2);
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    refreshProjection() {
        this.saveData(true, true);
    }

    saveData(isNext = true, fromRefresh = false) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        if (fromRefresh) {
            loadingState = 'refreshLoading';
        }

        this.setState({
            [loadingState]: true
        })

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);
        postData.append('capital_growth_rate', (this.state.capital_growth_rate) ? this.state.capital_growth_rate : 0);
        postData.append('inflation', (this.state.inflation) ? this.state.inflation : 0);
        postData.append('selected_year', this.state.selected_year);
        postData.append('has_rental_income', (this.state.has_rental_income) ? 1 : 0);
        postData.append('texable_income', (this.state.texable_income) ? 1 : 0);
        postData.append('living_expenses', (this.state.living_expenses) ? 1 : 0);

        axios.post(config.add_economic_assumption, postData).then(res => {
            if (res.success) {
                if (fromRefresh) {
                    this.setState({
                        overview: res.data.property.overview,
                        property: res.data.property
                    });
                } else {
                    this.showNotification(res.message, 'success');
                    this.setState({
                        [goToState]: true,
                        property: res.data.property
                    })
                }
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setEconomicTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        })
        this.calculateAmount(stateData.price, stateData.capital_growth_rate, stateData.selected_year);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.economic_assumption + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    axios.get(config.get_property + property_id).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let client_id = (getClientId()) || '';
            let entity_id = (getEntityId()) || '';
            let type_of_property = (getTypeOfProperty()) || '';
            if (client_id === '' || entity_id === '' || type_of_property === '') {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.deduction + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.investment_expenses + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <Row>
                <Col xl="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className="plisting_form rect_bx flip_bx">
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green">Economic Assumptions</h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>

                                    <RefreshProjection
                                        show={this.state.is_edit}
                                        loading={this.state.refreshLoading}
                                        onClick={this.refreshProjection}
                                    />
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Capital Growth Rate</h4>
                                                <PercentageField
                                                    placeholder="Enter Capital Growth Rate"
                                                    name="capital_growth_rate"
                                                    value={this.state.capital_growth_rate}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        YR 1
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="year1"
                                                                value={this.state.year1}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        YR 2
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="year2"
                                                                value={this.state.year2}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        YR 3
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="year3"
                                                                value={this.state.year3}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        YR 4
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="year4"
                                                                value={this.state.year4}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        YR 5
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="year5"
                                                                value={this.state.year5}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        YR 10
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="year10"
                                                                value={this.state.year10}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        <div className="crita select-value">
                                                            <Select
                                                                defaultValue={getSelectedYear()}
                                                                options={getSelectedYearsOption()}
                                                                value={getSelectedYear(this.state.selected_year)}
                                                                onChange={this.onYearChange}
                                                            />
                                                        </div>
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="selected_price"
                                                                value={this.state.selected_price}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Inflation (CPI)</h4>
                                                <PercentageField
                                                    placeholder="Enter Inflation Rate"
                                                    name="inflation"
                                                    value={this.state.inflation}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                    Apply to:
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Rental Expenses
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Form>
                                                                <div className="form-check form-check-inline">
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        name="has_rental_income"
                                                                        id="has_rental_income"
                                                                        checked={(this.state.has_rental_income)}
                                                                        onClick={this.onCheckBoxClick}
                                                                    />
                                                                </div>
                                                            </Form>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Taxable Income
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Form>
                                                                <div className="form-check form-check-inline">
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        name="texable_income"
                                                                        id="texable_income"
                                                                        checked={(this.state.texable_income)}
                                                                        onClick={this.onCheckBoxClick}
                                                                    />
                                                                </div>
                                                            </Form>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Living Expenses
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Form>
                                                                <div className="form-check form-check-inline">
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        name="living_expenses"
                                                                        id="living_expenses"
                                                                        checked={(this.state.living_expenses)}
                                                                        onClick={this.onCheckBoxClick}
                                                                    />
                                                                </div>
                                                            </Form>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious}  loading={this.state.loading_previous}/>

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col xl={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        interest_term={this.state.interest_term}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        interest_term_back={this.state.interest_term_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                        loans={this.state.loans}
                        is_existing_property={this.state.is_existing_property}
                    />
                </Col>
            </Row>
        </div>

    }
}