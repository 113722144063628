import config from '../config/config';
import {getHeaders, clearLocalStorage, storeInLocalStorage} from "./helper";
const axios = require('axios');

//base service call
export const callAuthService = (url, postData) => {
    return axios.post(config.base_url + url, postData)
        .then(function (response) {
            // handle success
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            return false;
        });
}

// log linkedin
export const loginWithLinkedIn = (postData) => {
    return callAuthService(config.linkedin_login, postData);
}

// singup
export const performSingup = (postData) => {
    return callAuthService(config.register, postData);
}

//base service call
export const callService = (url, postData) => {
    return axios.post(config.base_url + url, postData, getHeaders())
        .then(function (response) {
            //redirect to the login screen if api token is invalid
            if (!response.data.success) {
                if (response.data.error) {
                    if (typeof response.data.error !== 'undefined' && typeof response.data.error.code !== 'undefined' &&
                        response.data.error.code === 401
                    ) {
                        clearLocalStorage();
                        window.location.reload();
                    }

                    if (typeof response.data.error !== 'undefined' && typeof response.data.error.expired_code !== 'undefined' &&
                        response.data.error.expired_code === 401
                    ) {
                        getProfile();
                        window.location.reload();
                    }
                }
            }

            // handle success
            return response.data;
        })
        .catch(function (error) {
            // handle error
            return false;
        });
}

export const isSuperAdmin = () => {
    return callService(config.get_profile, {}).then(res => {
        if (res.success) {
            storeInLocalStorage(res.data.profile_details);

            if (res.data.profile_details.is_super_admin) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    });
}

export const getProfile = () => {
    return callService(config.get_profile, {}).then(res => {
        if (res.success) {
            storeInLocalStorage(res.data.profile_details);
        }
    });
}

export const getProperties = (postData) => {
    return callService(config.get_properties, postData);
}

export const deleteProperty = (postData) => {
    return callService(config.delete_property, postData);
}

export const getProperty = (postData) => {
    return callService(config.get_property, postData);
}

export const exportReport = (postData) => {
    return callService(config.export_report, postData);
}

/*Admin apis*/
export const getAdminDashboard = (postData) => {
    return callService(config.admin_dashboard, postData);
}

export const addUserFromAdmin = (postData) => {
    return callService(config.admin_add_user, postData);
}

export const getUserFromAdmin = (postData) => {
    return callService(config.admin_get_user, postData);
}

export const editUserFromAdmin = (postData) => {
    return callService(config.admin_edit_user, postData);
}

export const deleteUserFromAdmin = (postData) => {
    return callService(config.admin_delete_user, postData);
}

export const getAdminUserList = (postData) => {
    return callService(config.admin_user_list, postData);
}
