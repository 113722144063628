import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
    Row,
    Col,
    Form,
    Progress,
    Input,
} from 'reactstrap';
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getDefaultDPLife,
    getDefaultDiminishingValue,
    getDefaultDepreciationOfBuilding,
    getLoanType,
    getDefaultDepreciationMethod,
    getEntityId,
    getTypeOfProperty,
    getNumberOptions,
    getNumber,
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import config from "config/config";
import {
    getNotificationObject,
    setDeductionTabState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import DepreciationOfFittings from "./dialogs/depreciation-of-fitting";
import ExtraRepayment from "../sections/extra-repayment";
import PriceField from "../../../components/numbers/PriceField";
import PercentageField from "../../../components/numbers/PercentageField";
import Select from "../../../components/properlytics/select";
import moment from 'moment';
import axios from "../../../services/axios";
import ReactTooltip from 'react-tooltip';
import RefreshProjection from "../../../components/properlytics/refresh-projection";

export default class Deduction extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        let lifes = getDefaultDPLife();
        let year = parseInt(moment().format('YYYY'));
        this.state = {
            is_edit: false,
            property_id: '',
            cash_deduction: 0,
            depreciation_of_building: getDefaultDepreciationOfBuilding(),
            building_cost: 0,
            depreciation_amount: 0,
            depreciation_fitting: 0,
            loan_cost: 0,
            renovation_year: year,
            renovation_cost: 0,
            //dp
            stove_value: 0,
            stove_life: lifes.stove_life,
            furniture_value: 0,
            furniture_life: lifes.furniture_life,
            hot_water_sys_value: 0,
            hot_water_sys_life: lifes.hot_water_sys_life,
            extra: [],
            total: 0,
            year_one_depreciation: 0,
            depreciation_method: getDefaultDepreciationMethod(),
            diminishing_value: getDefaultDiminishingValue(),

            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            interest_term: 5,
            to_year: 30,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            interest_term_back: 5,
            to_year_back: 30,
            loan_type_back: getLoanType().value,
            loans: [],

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog: false,
            redirect_to_dashboard: false,

            build_year: '',
            min_build_year: year - 10,
            max_build_year: year,
            min_reno_year: year - 10,
            max_reno_year: year + 10,

            is_existing_property: true,
            refreshLoading: false,
        };

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.hideShowDialog = this.hideShowDialog.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.getDeduction = this.getDeduction.bind(this);
        this.calDepreciationAmount = this.calDepreciationAmount.bind(this);
        this.calDP = this.calDP.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onBuildYearChange = this.onBuildYearChange.bind(this);

        this.addFittings = this.addFittings.bind(this);
        this.removeFittings = this.removeFittings.bind(this);
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.refreshProjection = this.refreshProjection.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onBuildYearChange(selected) {
        this.setState({
            build_year: selected.value,
        });
    }

    onYearChange(selected) {
        this.setState({
            renovation_year: selected.value,
        });

        this.calDepreciationAmount(this.state.depreciation_of_building, this.state.building_cost, this.state.renovation_cost, selected.value, this.state.depreciation_fitting);
    }

    hideShowDialog() {
        if (this.state.property_id === '') {
            this.showNotification('Please first enter property detail', 'danger')
        } else {
            this.setState({
                show_dialog: (!this.state.show_dialog)
            })
            window.scrollTo(0, 0);
        }
    }

    onBlur(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.calDP(name, value);
    }

    addFittings() {
        let extra = this.state.extra;

        extra.push({
            name: '',
            value: 0,
            years: 0,
        });

        this.setState({
            extra: extra
        })
    }

    removeFittings(index) {
        let extra = this.state.extra;
        extra.splice(index,1);
        this.setState({
            extra: extra
        });
    }

    handleOnInputChange = (key, name, event) => {

        let extra = this.state.extra;
        for (let i=0;i<extra.length;i++) {
            if (typeof extra[key] !== 'undefined') {
                let value = event.target.value;
                if (name === 'name') {
                    extra[key][name] = value;
                } else {
                    if (!isNaN(value) || value === '') {
                        extra[key][name] = value;
                    }
                }
            }
        }

        if (event.type === 'blur') {
            this.calDP('extra', extra);
        }

        this.setState({
            extra: extra
        })
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (!isNaN(value) || value === '') {
            this.setState({
                [name]: value
            });

            if (name === 'depreciation_of_building') {
                this.calDepreciationAmount(value, this.state.building_cost, this.state.renovation_cost, this.state.renovation_year, this.state.depreciation_fitting);
            }
            if (name === 'building_cost') {
                this.calDepreciationAmount(this.state.depreciation_of_building, value, this.state.renovation_cost, this.state.renovation_year, this.state.depreciation_fitting);
            }
            if (name === 'renovation_cost') {
                let renovation_year = this.state.renovation_year;
                if (parseInt(value) > 0 && renovation_year === '') {
                    renovation_year = parseInt(moment().format('YYYY'));
                }
                this.setState({
                    renovation_year: renovation_year
                });

                this.calDepreciationAmount(this.state.depreciation_of_building, this.state.building_cost, value, this.state.renovation_year, this.state.depreciation_fitting);
            }

            //change dp function call
            if (name === 'depreciation_method') {
                this.calDP(name, value);
            }
        }
    }

    getDeduction(property_id) {
        if (property_id !== '') {
            axios.get(config.get_deduction + property_id).then(res => {
                if (res.success) {
                    let build_year = parseInt(res.data.deduction_details.build_year);
                    let min_build_year = this.state.min_build_year;
                    let max_build_year = parseInt(res.data.deduction_details.purchase_year);

                    if (this.state.is_existing_property) {
                        min_build_year = 1990;
                    } else {
                        min_build_year = (parseInt(moment().format('YYYY')) > build_year) ? build_year : parseInt(moment().format('YYYY'));
                        max_build_year = parseInt(moment().format('YYYY')) + 20;
                    }

                    this.setState({
                        cash_deduction: res.data.deduction_details.cash_deduction,
                        loan_cost: res.data.deduction_details.loan_cost,
                        build_year: build_year,
                        min_reno_year: (build_year - 20),
                        min_build_year: min_build_year,
                        max_build_year: max_build_year,
                    })
                }
            });
        }
    }

    calDepreciationAmount(depreciation_of_building, building_cost, renovation_cost, renovation_year, depreciation_fitting) {
        let depreciation_amount = parseFloat(depreciation_fitting);
        let current_year = parseInt(moment().format('YYYY'));
        let year_build = (this.state.build_year) ? parseInt(this.state.build_year) : 0;
        renovation_year = parseInt(renovation_year);

        if (year_build >= 1987 && (year_build + 40) >= current_year) {
            if (building_cost !== '' && depreciation_of_building !== '') {
                depreciation_amount += (parseFloat(building_cost) * parseFloat(depreciation_of_building)) / 100;
            }
        }

        if (renovation_year >= 1992 && (renovation_year + 40) >= current_year) {
            if (renovation_cost !== '' && depreciation_of_building !== '') {
                depreciation_amount += (parseFloat(renovation_cost) * parseFloat(depreciation_of_building)) / 100;
            }
        }

        this.setState({
            depreciation_amount: parseFloat(depreciation_amount).toFixed(2)
        })
    }

    calDP(name, value) {
        if (this.state.is_edit) {
            let dp_total = 0;

            let stove_value = (this.state.stove_value !== '') ? parseFloat(this.state.stove_value) : 0;
            if (name === 'stove_value') {
                stove_value = (value !== '') ? parseFloat(value) : 0;
            }
            let stove_life = (this.state.stove_life !== '') ? this.state.stove_life : 0;
            if (name === 'stove_life') {
                stove_life = (value !== '') ? value : 0;
            }
            dp_total += parseFloat(stove_value);

            let hot_water_sys_value = (this.state.hot_water_sys_value !== '') ? parseFloat(this.state.hot_water_sys_value) : 0;
            if (name === 'hot_water_sys_value') {
                hot_water_sys_value = (value !== '') ? parseFloat(value) : 0;
            }
            let hot_water_sys_life = (this.state.hot_water_sys_life !== '') ? this.state.hot_water_sys_life : 0;
            if (name === 'hot_water_sys_life') {
                hot_water_sys_life = (value !== '') ? value : 0;
            }
            dp_total += parseFloat(hot_water_sys_value);

            let furniture_value = (this.state.furniture_value !== '') ? parseFloat(this.state.furniture_value) : 0;
            if (name === 'furniture_value') {
                furniture_value = (value !== '') ? parseFloat(value) : 0;
            }
            let furniture_life = (this.state.furniture_life !== '') ? this.state.furniture_life : 0;
            if (name === 'furniture_life') {
                furniture_life = (value !== '') ? value : 0;
            }
            dp_total += parseFloat(furniture_value);

            let extra = this.state.extra;
            if (name === 'extra') {
                extra = value || [];
            }
            for (let i=0;i<extra.length;i++) {
                if (extra[i].value !== '' && extra[i].value !== 0 && extra[i].years !== '' && extra[i].years !== 0) {
                    dp_total += parseFloat(extra[i].value);
                }
            }

            let total = dp_total;
            if (name === 'total') {
                total = (value !== '') ? value : 0;
            }
            let depreciation_method = this.state.depreciation_method;
            if (name === 'depreciation_method') {
                depreciation_method = value;
            }
            let diminishing_value = (this.state.diminishing_value !== '') ? this.state.diminishing_value : 0;
            if (name === 'diminishing_value') {
                diminishing_value = (value !== '') ? value : 0;
            }
            if (name !== 'total' && name !== 'diminishing_value' && name !== 'depreciation_method') {
                this.setState({
                    total: parseFloat(dp_total).toFixed(2)
                })
            }

            let postData = {};
            postData.property_id = this.state.property_id;
            postData.stove_value = stove_value;
            postData.stove_life = stove_life;
            postData.hot_water_sys_value = hot_water_sys_value;
            postData.hot_water_sys_life = hot_water_sys_life;
            postData.furniture_value = furniture_value;
            postData.furniture_life = furniture_life;
            postData.total = total;
            postData.diminishing_value = diminishing_value;
            postData.depreciation_method = depreciation_method;
            postData.extra = extra;

            axios.post(config.cal_depreciation_of_fittings, postData).then(res => {
                if (res.success) {
                    this.setState({
                        year_one_depreciation: res.data.depreciation_fitting,
                        depreciation_fitting: res.data.depreciation_fitting,
                    });

                    this.calDepreciationAmount(this.state.depreciation_of_building, this.state.building_cost, this.state.renovation_cost, this.state.renovation_year, res.data.depreciation_fitting);
                } else {
                    this.showNotification(res.message, 'danger')
                }
            });
        }
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    refreshProjection() {
        this.saveData(true, true);
    }

    saveData(isNext = true, fromRefresh = false) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        if (fromRefresh) {
            loadingState = 'refreshLoading';
        }

        this.setState({
            [loadingState]: true
        });

        let depreciation_fittings = {
            stove_value: (this.state.stove_value) ? this.state.stove_value : 0,
            stove_life: (this.state.stove_life) ? this.state.stove_life : 0,
            hot_water_sys_value: (this.state.hot_water_sys_value) ? this.state.hot_water_sys_value : 0,
            hot_water_sys_life: (this.state.hot_water_sys_life) ? this.state.hot_water_sys_life : 0,
            furniture_value: (this.state.furniture_value) ? this.state.furniture_value : 0,
            furniture_life: (this.state.furniture_life) ? this.state.furniture_life : 0,
            extra: (this.state.extra) ? this.state.extra : [],
            total: (this.state.total) ? this.state.total : 0,
            year_one_depreciation: (this.state.year_one_depreciation) ? this.state.year_one_depreciation : 0,
            diminishing_value: (this.state.diminishing_value) ? this.state.diminishing_value : 0,
            depreciation_method: this.state.depreciation_method
        };

        let renovation_year = '';
        if (this.state.renovation_cost && this.state.renovation_year) {
            renovation_year = this.state.renovation_year;
        }

        let postData = {
            property_id: this.state.property_id,
            year_build: this.state.build_year,
            cash_deduction: (this.state.cash_deduction) ? this.state.cash_deduction : 0,
            depreciation_of_building: (this.state.depreciation_of_building) ? this.state.depreciation_of_building : 0,
            building_cost: (this.state.building_cost) ? this.state.building_cost : 0,
            depreciation_amount: (this.state.depreciation_amount) ? this.state.depreciation_amount : 0,
            depreciation_fitting: (this.state.depreciation_fitting) ? this.state.depreciation_fitting : 0,
            loan_cost: (this.state.loan_cost) ? this.state.loan_cost : 0,
            renovation_year: renovation_year,
            renovation_cost: (this.state.renovation_cost) ? this.state.renovation_cost : 0,
            depreciation_fittings: depreciation_fittings
        };

        axios.post(config.add_deduction, postData).then(res => {
            if (res.success) {
                if (fromRefresh) {
                    this.setState({
                        overview: res.data.property.overview,
                        property: res.data.property
                    });
                } else {
                    this.showNotification(res.message, 'success');
                    this.setState({
                        [goToState]: true,
                        property: res.data.property
                    })
                }
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setDeductionTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        })
        this.getDeduction(stateData.property_id);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.deduction + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    axios.get(config.get_property + property_id).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let client_id = (getClientId()) || '';
            let entity_id = (getEntityId()) || '';
            let type_of_property = (getTypeOfProperty()) || '';
            if (client_id === '' || entity_id === '' || type_of_property === '') {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.income + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.economic_assumption + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <ReactTooltip  textColor='#000000' backgroundColor='#ffffff' border borderColor='#cccccc' effect='solid'/>

            <Row>
                <Col xl="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className="plisting_form rect_bx flip_bx">
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green">Deductions</h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>

                                    <RefreshProjection
                                        show={this.state.is_edit}
                                        loading={this.state.refreshLoading}
                                        onClick={this.refreshProjection}
                                    />
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        <div className="tp_row dark-row pr-0">
                                            <div className="hd_row  d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w t_black items-center d-flex">
                                                    <span className="w-nowrap">Division 43 - Capital Works Deduction </span>
                                                    
                                                    <Popup
                                                        trigger={<span className="info-icon"> <i className="fa fa-info-circle" /> </span>}
                                                        modal
                                                        nested
                                                    >
                                                        {close => (
                                                        <div className="modal-popup">
                                                            <button className="close" onClick={close}>
                                                            &times;
                                                            </button>
                                                           
                                                            <div className="content">
                                                            {' '}
                                                            <p>Division 43 -
                                                                Capital works refer to the depreciation of the structure of the building, 
                                                                usually objects that aren't removable.</p>
                                                                <p className="mbtm0">Capital works include:</p>
                                                                <ul>
                                                                    <li>Buildings or extensions</li>
                                                                    <li>Alterations or improvements to a building</li>
                                                                    <li>Alterations or improvements to a leased building</li>
                                                                    <li>Structural improvements</li>
                                                                    <li>Earthworks for environmental protection</li>
                                                                    <li>Capital works may also be known as Building Write-Off  or Capital Works Allowance.</li>
                                                                </ul>
                                                                <p className="mbtm0">
                                                                Residential properties built after the 15th September 1987 
                                                                    are eligible to claim division 43 capital works 
                                                                    deductions over a 40-year period which will be 
                                                                    depreciated as a straight line at 2.5% per annum. 
                                                                    When construction costs are unknown, a qualified specialist 
                                                                        such as a Quantity Surveyor will be responsible
                                                                        for estimating the building.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </Popup>

                                                    {/* <p data-tip data-for='division43' className="tooltip-pop">
                                                        <i className="fa fa-info-circle" />
                                                    </p> */}

                                                    
                                                </h4>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            {/* Remove / Hide: Change request Aug 2021 */}
                                            {/* <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w t_black t_upper">Non-cash deductions</h4>
                                            </div> */}

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Depreciation of Building
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PercentageField
                                                                className="form-control text-right"
                                                                placeholder="0%"
                                                                name="depreciation_of_building"
                                                                value={this.state.depreciation_of_building}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            {/* Remove / Hide: Change request Aug 2021 */}
                                            <div className="hd_row d-flex inner_row hide">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Purchase Price
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="purchase_price"
                                                                value={this.state.purchase_price}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Cost of Build
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="building_cost"
                                                                value={this.state.building_cost}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Year of Build
                                                    </h4>

                                                    <div className="slct value ">
                                                        <Select
                                                            defaultValue={getNumber(this.state.min_build_year, this.state.max_build_year)}
                                                            options={getNumberOptions(this.state.min_build_year, this.state.max_build_year)}
                                                            onChange={this.onBuildYearChange}
                                                            value={getNumber(this.state.min_build_year, this.state.max_build_year, this.state.build_year)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Cost of Renovation
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="renovation_cost"
                                                                value={this.state.renovation_cost}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={this.state.renovation_cost > 0 ? "hide" : "hd_row d-flex inner_row"}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height60 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Year of Renovation
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                className="form-control text-right"
                                                                placeholder="N/A"
                                                                name="renovation_year_na"
                                                                value="N/A"
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={this.state.renovation_cost > 0 ? "hd_row d-flex inner_row" : "hide"}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height60 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                    Year of Renovation
                                                    </h4>

                                                    <div className="slct value ">
                                                        <Select
                                                            defaultValue={getNumber(this.state.min_reno_year, this.state.max_reno_year)}
                                                            options={getNumberOptions(this.state.min_reno_year, this.state.max_reno_year)}
                                                            onChange={this.onYearChange}
                                                            value={getNumber(this.state.min_reno_year, this.state.max_reno_year, this.state.renovation_year)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    

                                            {/* Include New: Change request Aug 2021 */}
                                            <div className="tp_row dark-row pr-0">
                                                <div className="hd_row  d-flex pr-40 justify-content-between">
                                                    <h4 className="sm_tit fx_w t_black items-center w-nowrap d-flex">Division 40 - Plant &amp; Equipment
                                                    <Popup
                                                        trigger={<span className="info-icon"> <i className="fa fa-info-circle" /> </span>}
                                                        modal
                                                        nested
                                                    >
                                                        {close => (
                                                        <div className="modal-popup">
                                                            <button className="close" onClick={close}>
                                                            &times;
                                                            </button>
                                                           
                                                            <div className="content">
                                                            {' '}
                                                            <p>Division 40 - Plant and equipment refer to items that are fixtures and fittings, usually known as easily removable assets.</p>
                                                        
                                                        <p>
                                                         Each item has an effective life that is measured in years which is set out by the ATO.
                                                         This can be found within the document ‘Taxation Ruling 
                                                         TR 2019/5 – Income tax: effective life of depreciation assets’.
                                                        </p>

                                                        <p className="mbtm0">
                                                          With Division 40 items, you can depreciate them using either the diminishing value or the prime cost method.
                                                         Although the end value is the same, many individuals select the diminishing value method for depreciation as your
                                                          items depreciate at a more rapid rate within the first few years.
                                                        </p>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </Popup>
                                                    </h4>

                                                    {/* <ReactTooltip id='division40' aria-haspopup='true' >
                                                        <p>Division 40 - Plant and equipment refer to items that are fixtures and fittings, usually known as easily removable assets.</p>
                                                        
                                                        <p>
                                                         Each item has an effective life that is measured in years which is set out by the ATO.
                                                         This can be found within the document ‘Taxation Ruling 
                                                         TR 2019/5 – Income tax: effective life of depreciation assets’.
                                                        </p>

                                                        <p>
                                                          With Division 40 items, you can depreciate them using either the diminishing value or the prime cost method.
                                                         Although the end value is the same, many individuals select the diminishing value method for depreciation as your
                                                          items depreciate at a more rapid rate within the first few years.
                                                        </p>
                                                    </ReactTooltip> */}
                                                </div>
                                            </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w w-nowrap d-flex justify-content-between items-center">
                                                        Cost of Fittings
                                                        <span className="txt-gray act_icon">
                                                            <i className="f20 icon-plus" onClick={this.hideShowDialog} />
                                                        </span>
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="depreciation_fitting"
                                                                value={this.state.depreciation_fitting}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Costs
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="loan_cost"
                                                                value={this.state.loan_cost}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0">
                                            <div className="hd_row justify-content-between d-flex">
                                                <h4 className="sm_tit fx_w">Cash Deductions (P/Y)</h4>
                                                <span className="d-flex  height60">
                                                    <span className="value t_black pmedium">
                                                        <PriceField
                                                            placeholder="$0"
                                                            name="cash_deduction"
                                                            value={this.state.cash_deduction}
                                                            readOnly={true}
                                                            className="form-control text-right"
                                                        />
                                                    </span>
                                                </span>        
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0">
                                            <div className="hd_row d-flex justify-content-between">
                                                    <h4 className="sm_tit w-nowrap fx_w d-flex">
                                                        {this.state.is_existing_property ? "Depreciation Amount Current Year" : "Depreciation Amount at Purchase Year"} <p data-tip="This is the depreciation amount calculated for the current year." className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="depreciation_amount"
                                                                value={this.state.depreciation_amount}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                        </div>

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious}  loading={this.state.loading_previous}/>

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            <DepreciationOfFittings
                                stove_value={this.state.stove_value}
                                stove_life={this.state.stove_life}
                                hot_water_sys_value={this.state.hot_water_sys_value}
                                hot_water_sys_life={this.state.hot_water_sys_life}
                                furniture_value={this.state.furniture_value}
                                furniture_life={this.state.furniture_life}
                                extra={this.state.extra}
                                total={this.state.total}
                                year_one_depreciation={this.state.year_one_depreciation}
                                depreciation_method={this.state.depreciation_method}
                                diminishing_value={this.state.diminishing_value}
                                show_dialog={this.state.show_dialog}
                                onInputChange={this.onInputChange}
                                onBlur={this.onBlur}
                                hideShowDialog={this.hideShowDialog}
                                addFittings={this.addFittings}
                                removeFittings={this.removeFittings}
                                handleOnInputChange={this.handleOnInputChange}
                            />
                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col xl={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        interest_term={this.state.interest_term}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        interest_term_back={this.state.interest_term_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                        loans={this.state.loans}
                        is_existing_property={this.state.is_existing_property}
                    />
                </Col>
            </Row>
        </div>

    }
}