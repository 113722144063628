import React from 'react';
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
    Row,
    Col,
} from 'reactstrap';

import { Redirect } from 'react-router';
import { storeInLocalStorage } from '../../services/helper';
import config from 'config/config';
import validator from './login-validators';
import RightButton from "../../components/properlytics/right-button";
import axios from "../../services/axios";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            email: '',
            password: '',
            error: null,
            loading: false,
        }

        this.validators = validator;
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onLogin = this.onLogin.bind(this);
    }


    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (field === 'email' || field === 'password') {
                if (!this.validators[field].valid) {
                    status = false;
                }
            }
        });
        return status;
    }

    onLogin() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            const postData ={
                email: this.state.email,
                password: this.state.password,
            };

            axios.get(config.csrf)
                .then(response => {
                    axios.post(config.login, postData).then(res => {
                        if (res.success) {
                            storeInLocalStorage(res.data);
                            this.setState({
                                redirect: true,
                            })
                        } else {
                            this.props.showNotification(res.message, 'danger')

                            this.setState({
                                loading: false
                            })
                        }
                    })
                });
        } else {
            this.props.showNotification('Enter email and/or password', 'danger')
        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <Row>
                <Col xs="12">
                    <Form id="loginform" action="">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Email*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter your email"
                                name="email"
                                id="email"
                                value={this.state.email}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Password*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="password"
                                placeholder="Enter your password"
                                name="password"
                                id="password"
                                value={this.state.password}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <div className="d-flex no-block align-items-cente auth-footer">

                            {/*Hide social login as per client request*/}

                            {/*<SocialLogin
                                callbackFB={this.props.callbackFB}
                                callbackLinkedIn={this.props.callbackLinkedIn}
                                callbackLinkedInFail={this.props.callbackLinkedInFail}
                                is_signup={false}
                            />*/}

                            <div className="right_bx d-flex">
                            </div>


                            <RightButton text="Login" onClick={this.onLogin} loading={this.state.loading} />
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>;
    }
}

export default LoginForm;
