import React from 'react';
import {
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardTitle,
    CardBody,
} from 'reactstrap';
import classnames from 'classnames';
import config from 'config/config';
import { Redirect } from 'react-router';
import LoginForm from "./login-form";
import SignupForm from "./signup-form";
import {getNotificationObject, storeInLocalStorage} from "../../services/helper";
import {loginWithLinkedIn} from "../../services/services";
import ReactNotification from "react-notifications-component";
import { isAndroid, isIOS } from 'react-device-detect';
import mobileImage from 'assets/images/logo-mobile.png';
import axios from "../../services/axios";

class Login extends React.Component {
    constructor(props) {
        super(props);

        let activeTab = '1';
        let accountType = 1;
        if (props.match.url === config.site_url.register || props.match.url === config.site_url.personalTrial || props.match.url === config.site_url.businessTrial) {
            activeTab = '2';

            if (props.match.url === config.site_url.businessTrial) {
                accountType = 2;
            }
        }

        this.state = {
            activeTab: activeTab,
            accountType: accountType,
            login: false,
            setupProfile: false,
            data: [],
            loading: false,

            show_top_bar: true,
        };

        this.toggle = this.toggle.bind(this);
        this.callbackFB = this.callbackFB.bind(this);
        this.callbackLinkedIn = this.callbackLinkedIn.bind(this);
        this.callbackLinkedInFail = this.callbackLinkedInFail.bind(this);
        this.hideDownloadTab = this.hideDownloadTab.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                'activeTab': tab
            });
        }
    }

    hideDownloadTab() {
        if (this.state.show_top_bar) {
            this.setState({
                show_top_bar: false
            });
        }
    }

    callbackFB(response) {
        console.log(response);

        if (response.email) {
            this.setState({
                loading: true
            })
            let postData = {
                email: response.email,
                first_name: response.name,
            };

            axios.get(config.csrf)
                .then(response => {
                    axios.post(config.login, postData).then(res => {
                        if (res.success) {
                            storeInLocalStorage(res.data);
                            this.setState({
                                login: true
                            });
                        } else {
                            this.showNotification(res.message, 'danger')
                        }
                        this.setState({
                            loading: false
                        })
                    })
                });
        }
    }

    callbackLinkedIn(data) {
        if (data.code) {
            this.setState({
                loading: true
            })
            let postData = {
                code: data.code,
                redirect_uri: config.linkedin_redirect_uri
            }
            loginWithLinkedIn(postData).then(res => {
                if (res.success) {
                    if (res.data.login) {
                        storeInLocalStorage(res.data.profile.profile_details);
                        this.setState({
                            login: true
                        });
                    } else {
                        //redirect to setup profile
                        this.setState({
                            setupProfile: true,
                            data: res.data
                        })
                    }
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        }
    }

    callbackLinkedInFail(error) {
        this.showNotification(error.errorMessage, 'danger')
    }

    render() {

        if (this.state.login) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        if (this.state.setupProfile) {
            return <Redirect to={{
                pathname: config.site_url.setup_profile,
                state: this.state.data
            }} />;
        }

        //FIXME: Remove Download slider from top and integrate Apple Safari Metadata
        // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/PromotingAppswithAppBanners/PromotingAppswithAppBanners.html

        if (isAndroid || isIOS) {
            let app_url = 'https://apps.apple.com/au/app/properlytics/id1450136764';
            if (isAndroid) {
                app_url = 'https://play.google.com/store/apps/details?id=au.com.wlth.app.prpty';
            }

            return <div className="mobile-screen">
                <div className={isAndroid ? "download-tab" : "hide"}>
                    <div className={this.state.show_top_bar ? "download-content" : "hide"}>
                        <i
                            className="f20 txt-gray icon-close"
                            onClick={this.hideDownloadTab}
                        />
                        <img src={mobileImage} alt="Properlytics" />
                        <div className="header">
                            Properlytics
                        </div>

                        <a className="download" href={app_url}>
                            Download
                        </a>
                    </div>
                </div>

                <div className="content">
                    <img src={mobileImage} alt="Properlytics" />

                    <div className="header">
                        Properlytics is best<br/>
                        viewed on the app.
                    </div>

                    <a className="download" href={app_url}>
                        Download now
                    </a>
                </div>
            </div>;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className={this.state.loading ? `page-loader show` : `page-loader hide`} />

                <div className={(this.state.loading) ? 'auth-box hide' : 'auth-box'}>
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Welcome
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">{ (this.state.activeTab === '1') ? 'Login to Properlytics' : 'Create a Properlytics account' }</p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                'active': this.state.activeTab === '1'
                                            })}
                                            onClick={() => {
                                                this.toggle('1');
                                            }}
                                        >
                                            Login
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                'active': this.state.activeTab === '2'
                                            })}
                                            onClick={() => {
                                                this.toggle('2');
                                            }}
                                        >
                                            Sign up
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <LoginForm
                                            toggleTab={this.toggle}
                                            showNotification={this.showNotification}
                                            callbackFB={this.callbackFB}
                                            callbackLinkedIn={this.callbackLinkedIn}
                                            callbackLinkedInFail={this.callbackLinkedInFail}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <SignupForm
                                            accountType={this.state.accountType}
                                            toggleTab={this.toggle}
                                            showNotification={this.showNotification}
                                            callbackFB={this.callbackFB}
                                            callbackLinkedIn={this.callbackLinkedIn}
                                            callbackLinkedInFail={this.callbackLinkedInFail}
                                        />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>

                        <div className={(this.state.activeTab === '2') ? "hide" : "forgot-password"}>
                            <a href={config.hash + config.site_url.forgot_password} >
                                Forgot Password?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Login;
