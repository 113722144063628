import React from 'react';
import ReactTooltip from 'react-tooltip';

export default class RefreshProjection extends React.Component {

	render() {
        let {loading, onClick, show} = this.props;

        return (
            <div>
                <ReactTooltip id='refresh' aria-haspopup='true' >
                    <span>Refresh Projection</span>
                </ReactTooltip>

                <div
                    onClick={onClick}
                    className={(show && !loading) ? "refresh" : "hide"}
                    data-tip data-for="refresh"
                >
                    <i className="icon-reload" aria-hidden="true" />
                </div>

                <div className={loading ? 'refresh-loader' : 'hide'} />
            </div>
        );
	}
}