import React from 'react';
import config from 'config/config';
import {
    Button,
    Row,
    Col,
    Input,
} from 'reactstrap';
import {formatMoney, getConfig} from 'services/helper';
import Select from "../../components/properlytics/select";
import users from "assets/images/user-icon.png"
import clients from "assets/images/client-icon.png"

export default class DashboardTabs extends React.Component {

    constructor(props) {
        super(props);

        let searchOptions = [];
        this.state = {
            searchOptions: searchOptions,
            groupedOptions: [
                {
                    options: searchOptions,
                },
            ],

            search_by: 1,
            search_text: '',
        }

        this.onSelectChange = this.onSelectChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    onSelectChange(selected) {
        this.props.changeFilter({
            search_by: selected.value,
            search_text: this.state.search_text
        })
        this.setState({
            search_by: selected.value
        });
    }

    onTextChange(e) {
        this.props.changeFilter({
            search_by: this.state.search_by,
            search_text: e.target.value
        })
        this.setState({
            search_text: e.target.value
        });
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        let config = getConfig();
        if (config) {
            let searchOptions = config.filters.admin;
            this.setState({
                searchOptions: searchOptions,
                groupedOptions: [
                    {
                        options: searchOptions,
                    },
                ]
            })
        }
    }

    render() {
        let {dashboard} = this.props;

        return (
            <Row>
                <Col md="6">
                    <Row>
                        <Col md="6">
                            <div className="ttl_prpty sm_bx">
                                <a href={config.hash + config.site_url.admin_dashboard}>
                                    <div className="bx_info">
                                        <h5 className="pmedium fnormal">
                                            <img src={users} className="dashboard-user-icon" alt="Icon" />
                                            Users
                                        </h5>

                                        <div className="al_btm">
                                            <h6 className="num">
                                                {dashboard.users}
                                                <p className="f16 mbtm0">+{dashboard.last_days_users} in last 7 days</p>
                                            </h6>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>

                        <Col md="6">
                            <div className="ttl_client sm_bx">
                                <a href={config.hash + config.site_url.admin_dashboard}>
                                    <div className="bx_info">
                                        <h5 className="pmedium t_black fnormal">
                                            <img src={clients} className="dashboard-user-icon" alt="Icon" />
                                            Clients
                                        </h5>

                                        <div className="al_btm">
                                            <h6 className="num">
                                                {dashboard.clients}
                                                <p className="f16 mbtm0">+{dashboard.last_days_clients} in last 7 days</p>
                                            </h6>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col md="6">
                    <div className="wt_bx">
                        <div className="top_part pad0">
                            <div className="d-flex top_dtl mtop0">
                                <div className="ttl_count">
                                    <span className="pbold t_black f16">Properties</span>
                                    <h5 className="ttl_num pmedium mbtm0">{dashboard.properties}</h5>
                                    <span className="pmedium  f16">+{dashboard.last_days_properties} in last 7 days</span>
                                </div>

                                <div className="ttl_count">
                                    <span className="pbold t_black f16">Payments</span>
                                    <h5 className="ttl_num pmedium mbtm0">${formatMoney(dashboard.payments, 0)}</h5>
                                    <span className="pmedium  f16">/week</span>
                                </div>
                            </div>
                        </div>

                        <div className="text-center middle_part">
                            <a
                                href={config.google_analytics_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="t_green fnormal mbtm0  pmedium"
                            >
                                Launch Analytics
                            </a>
                        </div>

                        <div className="btm_part">
                            <div className="d-flex btm-dtl">
                                <div className="search_by">
                                        <span className="t_green mbtm0 fnormal pmedium">
                                            Search via
                                        </span>

                                    <Select
                                        defaultValue={this.state.searchOptions[0]}
                                        options={this.state.groupedOptions}
                                        onChange={this.onSelectChange}
                                    />
                                </div>

                                <div className="q_search">
                                    <Input
                                        type="text"
                                        id="search_text"
                                        name="search_text"
                                        onChange={this.onTextChange}
                                        value={this.state.search_text}
                                        placeholder="SEARCH" />
                                    <Button type="submit">
                                        <i aria-hidden="true" className="fa fa-search" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
		);
	}
}