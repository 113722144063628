import React from 'react';
import {
    Row,
    Col,
    Form,
    Progress,
    CustomInput,
    Input
} from 'reactstrap';
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getLoanType,
    getEntityId,
    getTypeOfProperty
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import config from "config/config";
import {
    getNotificationObject,
    setIncomeTabState,
    setTaxBenefitState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import ExtraRepayment from "../sections/extra-repayment";
import CurrentTaxableIncome from "./dialogs/current-taxable-income";
import MiddleButton from "../../../components/properlytics/middle-button";
import TaxBenefits from "./dialogs/tax-benefits";
import TaxScales from "./dialogs/tax-scales";
import TaxOffset from "./dialogs/tax-offset";
import PercentageField from "../../../components/numbers/PercentageField";
import PriceField from "../../../components/numbers/PriceField";
import axios from "../../../services/axios";
import ReactTooltip from 'react-tooltip';
import RefreshProjection from "../../../components/properlytics/refresh-projection";

export default class Income extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        this.state = {
            is_edit: false,
            property_id: '',

            is_joint: false,
            investor: 100,
            partner: 0,
            i_salary: 0,
            p_salary: 0,
            salary: 0,
            i_other_income: 0,
            p_other_income: 0,
            other_income: 0,
            i_work_expenses: 0,
            p_work_expenses: 0,
            work_expenses: 0,
            i_other_expenses: 0,
            p_other_expenses: 0,
            other_expenses: 0,
            i_tax_offset: 0,
            p_tax_offset: 0,
            tax_offset: 0,
            i_nt_income: 0,
            p_nt_income: 0,
            nt_income: 0,
            i_non_taxable: 0,
            p_non_taxable: 0,
            non_taxable: 0,
            total_net_income: 0,

            //tb
            i_income: 0,
            p_income: 0,
            income: 0,
            i_rental_income: 0,
            p_rental_income: 0,
            rental_income: 0,
            i_total_income: 0,
            p_total_income: 0,
            total_income: 0,
            i_rental_deduction: 0,
            p_rental_deduction: 0,
            rental_deduction: 0,
            i_new_tax_income: 0,
            p_new_tax_income: 0,
            new_tax_income: 0,
            i_present_tax: 0,
            p_present_tax: 0,
            present_tax: 0,
            i_new_tax: 0,
            p_new_tax: 0,
            new_tax: 0,
            i_tax_saving: 0,
            p_tax_saving: 0,
            tax_saving: 0,
            it_type: 1,

            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            interest_term: 5,
            to_year: 30,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            interest_term_back: 5,
            to_year_back: 30,
            loan_type_back: getLoanType().value,
            loans: [],

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog1: false,
            show_dialog2: false,
            show_dialog3: false,
            show_dialog4: false,
            redirect_to_dashboard: false,

            is_existing_property: true,
            refreshLoading: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.calculateTaxBenefits = this.calculateTaxBenefits.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.changeITType = this.changeITType.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.changeTaxCredit = this.changeTaxCredit.bind(this);

        this.hideShowDialog1 = this.hideShowDialog1.bind(this);
        this.hideShowDialog2 = this.hideShowDialog2.bind(this);
        this.hideShowDialog3 = this.hideShowDialog3.bind(this);
        this.hideShowDialog4 = this.hideShowDialog4.bind(this);
        this.refreshProjection = this.refreshProjection.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    hideShowDialog1() {
        if (this.state.property_id === '') {
            this.showNotification('Please first enter property detail', 'danger')
        } else {
            this.setState({
                show_dialog1: (!this.state.show_dialog1)
            })
            window.scrollTo(0, 0);
        }
    }

    hideShowDialog2() {
        if (this.state.property_id === '') {
            this.showNotification('Please first enter property detail', 'danger')
        } else {
            this.setState({
                show_dialog2: (!this.state.show_dialog2)
            })
            window.scrollTo(0, 0);
        }
    }

    hideShowDialog3() {
        if (this.state.property_id === '') {
            this.showNotification('Please first enter property detail', 'danger')
        } else {
            this.setState({
                show_dialog3: (!this.state.show_dialog3)
            })
            window.scrollTo(0, 0);
        }
    }

    hideShowDialog4() {
        if (this.state.property_id === '') {
            this.showNotification('Please first enter property detail', 'danger')
        } else {
            this.setState({
                show_dialog4: (!this.state.show_dialog4)
            })
            window.scrollTo(0, 0);
        }
    }

    changeITType(it_type) {
        this.setState({
            it_type: it_type
        });

        this.calculateTaxBenefits(
            this.state.property_id,
            this.state.is_joint,
            this.state.investor,
            this.state.partner,
            this.state.i_income,
            this.state.p_income,
            it_type
        );
    }

    changeTaxCredit() {
        this.calculateTaxBenefits(
            this.state.property_id,
            this.state.is_joint,
            this.state.investor,
            this.state.partner,
            this.state.i_income,
            this.state.p_income,
            this.state.it_type
        );
    }

    onBlur(event) {
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'investor' || name === 'partner') {
            let investor = this.state.investor;
            let partner = this.state.partner;
            let is_joint = this.state.is_joint;
            if (this.state.is_joint) {
                if (name === 'investor') {
                    if (value > 100) {
                        investor = 100;
                        partner = 0;
                        is_joint = false;
                    } else {
                        investor = value;
                        partner = 100 - investor;
                    }
                } else {
                    if (value > 100) {
                        investor = 50;
                        partner = 50;
                        is_joint = true;
                    } else {
                        partner = value;
                        investor = 100 - partner;
                    }
                }
            } else {
                investor = 100;
                partner = 0;
                is_joint = false;
            }

            this.setState({
                is_joint: is_joint,
                investor: investor,
                partner: partner
            });

            this.calculateTaxBenefits(
                this.state.property_id,
                is_joint,
                investor,
                partner,
                this.state.i_salary,
                this.state.p_salary,
                this.state.it_type
            );

        } else if (name === 'i_salary') {
            this.setState({
                [name]: value
            });

            this.calculateTaxBenefits(
                this.state.property_id,
                this.state.is_joint,
                this.state.investor,
                this.state.partner,
                value,
                this.state.p_salary,
                this.state.it_type
            );
        } else if (name === 'p_salary') {
            this.setState({
                [name]: value
            });

            this.calculateTaxBenefits(
                this.state.property_id,
                this.state.is_joint,
                this.state.investor,
                this.state.partner,
                this.state.i_salary,
                value,
                this.state.it_type
            );
        }
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (!isNaN(value) || value === '') {
            this.setState({
                [name]: value
            });
            this.calculateTotal(name, value, this.state.tax_offset);
        }
    }

    onCheckboxChange(name, event) {
        event.preventDefault();
        let state = this.state;
        let checked = !(state[name]);

        let investor = this.state.investor;
        let partner = this.state.partner;
        if (checked) {
            investor = 50;
            partner = 50;
        } else {
            investor = 100;
            partner = 0;
        }

        this.setState({
            [name]: checked,
            investor: investor,
            partner: partner
        });

        this.calculateTaxBenefits(
            this.state.property_id,
            checked,
            investor,
            partner,
            this.state.i_salary,
            this.state.p_salary,
            this.state.it_type
        );

        if (checked) {
            alert("Please note, selecting a joint owner will impact the ability to determine individual borrowing capacity and available equity at a portfolio level");
        }
    }

    calculateTaxBenefits(property_id, is_joint, investor, partner, i_income, p_income, it_type) {
        if (property_id !== '') {
            let postData = new FormData();
            postData.append('property_id', property_id);
            postData.append('is_joint', (is_joint) ? 1 : 0);
            postData.append('investor', investor);
            postData.append('partner', partner);
            postData.append('i_income', i_income);
            postData.append('p_income', p_income);
            postData.append('option', it_type);

            axios.post(config.cal_tax_benefits, postData).then(res => {
                if (res.success) {
                    let stateData  = setTaxBenefitState(res.data.income_total);
                    this.setState(stateData);

                    this.calculateTotal('', 0, stateData.tax_offset);
                }
            });
        }
    }

    calculateTotal(name = '', value = 0, tax_offset = 0) {
        let i_salary = (name === 'i_salary') ? value : this.state.i_salary;
        let p_salary = 0;
        if (this.state.is_joint) {
            p_salary = (name === 'p_salary') ? value : this.state.p_salary;
        }
        let salary = parseFloat(i_salary) + parseFloat(p_salary);

        let i_other_income = (name === 'i_other_income') ? value : this.state.i_other_income;
        let p_other_income = 0;
        if (this.state.is_joint) {
            p_other_income = (name === 'p_other_income') ? value : this.state.p_other_income;
        }
        let other_income = parseFloat(i_other_income) + parseFloat(p_other_income);

        let i_work_expenses = (name === 'i_work_expenses') ? value : this.state.i_work_expenses;
        let p_work_expenses = 0;
        if (this.state.is_joint) {
            p_work_expenses = (name === 'p_work_expenses') ? value : this.state.p_work_expenses;
        }
        let work_expenses = parseFloat(i_work_expenses) + parseFloat(p_work_expenses);

        let i_other_expenses = (name === 'i_other_expenses') ? value : this.state.i_other_expenses;
        let p_other_expenses = 0;
        if (this.state.is_joint) {
            p_other_expenses = (name === 'p_other_expenses') ? value : this.state.p_other_expenses;
        }
        let other_expenses = parseFloat(i_other_expenses) + parseFloat(p_other_expenses);

        let i_nt_income = (name === 'i_nt_income') ? value : this.state.i_nt_income;
        let p_nt_income = 0;
        if (this.state.is_joint) {
            p_nt_income = (name === 'p_nt_income') ? value : this.state.p_nt_income;
        }
        let nt_income = parseFloat(i_nt_income) + parseFloat(p_nt_income);

        let i_non_taxable = (name === 'i_non_taxable') ? value : this.state.i_non_taxable;
        let p_non_taxable = 0;
        if (this.state.is_joint) {
            p_non_taxable = (name === 'p_non_taxable') ? value : this.state.p_non_taxable;
        }
        let non_taxable = parseFloat(i_non_taxable) + parseFloat(p_non_taxable);

        tax_offset = parseFloat(tax_offset);

        let total = parseFloat((salary + other_income + tax_offset + nt_income + non_taxable) - (work_expenses + other_expenses)).toFixed(2);

        this.setState({
            salary: salary,
            other_income: other_income,
            other_expenses: other_expenses,
            work_expenses: work_expenses,
            nt_income: nt_income,
            non_taxable: non_taxable,
            total_net_income: total
        })
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    refreshProjection() {
        this.saveData(true, true);
    }

    saveData(isNext = true, fromRefresh = false) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        if (fromRefresh) {
            loadingState = 'refreshLoading';
        }

        this.setState({
            [loadingState]: true
        })

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);
        postData.append('is_joint', (this.state.is_joint) ? 1 : 0);
        postData.append('investor', (this.state.investor) ? this.state.investor : 100);
        postData.append('partner', (this.state.partner) ? this.state.partner : 0);
        postData.append('i_salary', (this.state.i_salary) ? this.state.i_salary : 0);
        postData.append('p_salary', (this.state.p_salary) ? this.state.p_salary : 0);
        postData.append('salary', (this.state.salary) ? this.state.salary : 0);
        postData.append('i_other_income', (this.state.i_other_income) ? this.state.i_other_income : 0);
        postData.append('p_other_income', (this.state.p_other_income) ? this.state.p_other_income : 0);
        postData.append('other_income', (this.state.other_income) ? this.state.other_income : 0);
        postData.append('i_work_expenses', (this.state.i_work_expenses) ? this.state.i_work_expenses : 0);
        postData.append('p_work_expenses', (this.state.p_work_expenses) ? this.state.p_work_expenses : 0);
        postData.append('work_expenses', (this.state.work_expenses) ? this.state.work_expenses : 0);
        postData.append('i_other_expenses', (this.state.i_other_expenses) ? this.state.i_other_expenses : 0);
        postData.append('p_other_expenses', (this.state.p_other_expenses) ? this.state.p_other_expenses : 0);
        postData.append('other_expenses', (this.state.other_expenses) ? this.state.other_expenses : 0);
        postData.append('i_tax_offset', (this.state.i_tax_offset) ? this.state.i_tax_offset : 0);
        postData.append('p_tax_offset', (this.state.p_tax_offset) ? this.state.p_tax_offset : 0);
        postData.append('tax_offset', (this.state.tax_offset) ? this.state.tax_offset : 0);
        postData.append('i_nt_income', (this.state.i_nt_income) ? this.state.i_nt_income : 0);
        postData.append('p_nt_income', (this.state.p_nt_income) ? this.state.p_nt_income : 0);
        postData.append('nt_income', (this.state.nt_income) ? this.state.nt_income : 0);
        postData.append('i_non_taxable', (this.state.i_non_taxable) ? this.state.i_non_taxable : 0);
        postData.append('p_non_taxable', (this.state.p_non_taxable) ? this.state.p_non_taxable : 0);
        postData.append('non_taxable', (this.state.non_taxable) ? this.state.non_taxable : 0);
        postData.append('total_net_income', (this.state.total_net_income) ? this.state.total_net_income : 0);

        //tb
        postData.append('i_income', (this.state.i_income) ? this.state.i_income : 0);
        postData.append('p_income', (this.state.p_income) ? this.state.p_income : 0);
        postData.append('income', (this.state.income) ? this.state.income : 0);
        postData.append('i_rental_income', (this.state.i_rental_income) ? this.state.i_rental_income : 0);
        postData.append('p_rental_income', (this.state.p_rental_income) ? this.state.p_rental_income : 0);
        postData.append('rental_income', (this.state.rental_income) ? this.state.rental_income : 0);
        postData.append('i_total_income', (this.state.i_total_income) ? this.state.i_total_income : 0);
        postData.append('p_total_income', (this.state.p_total_income) ? this.state.p_total_income : 0);
        postData.append('total_income', (this.state.total_income) ? this.state.total_income : 0);
        postData.append('i_rental_deduction', (this.state.i_rental_deduction) ? this.state.i_rental_deduction : 0);
        postData.append('p_rental_deduction', (this.state.p_rental_deduction) ? this.state.p_rental_deduction : 0);
        postData.append('rental_deduction', (this.state.rental_deduction) ? this.state.rental_deduction : 0);
        postData.append('i_new_tax_income', (this.state.i_new_tax_income) ? this.state.i_new_tax_income : 0);
        postData.append('p_new_tax_income', (this.state.p_new_tax_income) ? this.state.p_new_tax_income : 0);
        postData.append('new_tax_income', (this.state.new_tax_income) ? this.state.new_tax_income : 0);
        postData.append('i_present_tax', (this.state.i_present_tax) ? this.state.i_present_tax : 0);
        postData.append('p_present_tax', (this.state.p_present_tax) ? this.state.p_present_tax : 0);
        postData.append('present_tax', (this.state.present_tax) ? this.state.present_tax : 0);
        postData.append('i_new_tax', (this.state.i_new_tax) ? this.state.i_new_tax : 0);
        postData.append('p_new_tax', (this.state.p_new_tax) ? this.state.p_new_tax : 0);
        postData.append('new_tax', (this.state.new_tax) ? this.state.new_tax : 0);
        postData.append('tax_saving', (this.state.tax_saving) ? this.state.tax_saving : 0);
        postData.append('it_type', (this.state.it_type) ? this.state.it_type : 0);

        axios.post(config.add_income, postData).then(res => {
            if (res.success) {
                if (fromRefresh) {
                    this.setState({
                        overview: res.data.property.overview,
                        property: res.data.property
                    });
                } else {
                    this.showNotification(res.message, 'success');
                    this.setState({
                        [goToState]: true,
                        property: res.data.property
                    })
                }
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setIncomeTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        })
        this.calculateTaxBenefits(
            stateData.property_id,
            stateData.is_joint,
            stateData.investor,
            stateData.partner,
            stateData.i_salary,
            stateData.p_salary,
            stateData.it_type
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.income + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    axios.get(config.get_property + property_id).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let client_id = (getClientId()) || '';
            let entity_id = (getEntityId()) || '';
            let type_of_property = (getTypeOfProperty()) || '';
            if (client_id === '' || entity_id === '' || type_of_property === '') {
                this.setState({
                    redirect_to_dashboard: true,
                })
            } else {
                this.setState({
                    is_existing_property: (parseInt(type_of_property) === 1)
                })
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.cashflow + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.deduction + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <ReactTooltip  textColor='#000000' backgroundColor='#ffffff' border borderColor='#cccccc' effect='solid'/>

            <Row>
                <Col xl="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper income-form blocks">
                        <div className="block">
                            <div className={(this.state.show_dialog1 || this.state.show_dialog2) ? "plisting_form rect_bx flip_bx go" : "plisting_form rect_bx flip_bx"}>
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green items-center d-flex">Income 
                                    <p data-tip="Please note, selecting a joint owner will impact the ability to determine individual borrowing capacity and available equity at a portfolio level" className="tooltip-pop"><i className="fa fa-info-circle" /></p></h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>

                                    <RefreshProjection
                                        show={this.state.is_edit}
                                        loading={this.state.refreshLoading}
                                        onClick={this.refreshProjection}
                                    />
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w t_upper group-switch">
                                                    <span className="switch-left">Single</span>
                                                    <label className="switch" onClick={this.onCheckboxChange.bind(this, 'is_joint')}>
                                                        <CustomInput
                                                            type="checkbox"
                                                            name="is_joint"
                                                            checked={this.state.is_joint}
                                                        />
                                                        <span className="slider round1"/>
                                                    </label>
                                                    <span className="switch-right">Joint</span>
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <Input
                                                               type="text"
                                                               className="form-control lpad0"
                                                               value="Investor"
                                                           />
                                                        </span>
                                                    </span>

                                                    <span className={(this.state.is_joint) ? 'd-flex  height60' : 'hide'}>
                                                        <span className="t_black pmedium">
                                                           <Input
                                                               type="text"
                                                               className="form-control rpad0"
                                                               value="Partner"
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Ownership
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PercentageField
                                                                className="form-control lpad0"
                                                                placeholder="100%"
                                                                name="investor"
                                                                value={this.state.investor}
                                                                onChange={this.onInputChange}
                                                                onBlur={this.onBlur}
                                                            />
                                                        </span>
                                                    </span>

                                                    <span className={(this.state.is_joint) ? 'd-flex  height60' : 'hide'}>
                                                        <span className="t_black pmedium">
                                                            <PercentageField
                                                                className="form-control rpad0"
                                                                placeholder="0%"
                                                                name="partner"
                                                                value={this.state.partner}
                                                                onChange={this.onInputChange}
                                                                onBlur={this.onBlur}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Salary / Wages
                                                    <span className="flipbtn t_black rotate" onClick={this.hideShowDialog1}>
                                                        <i className="icon-arrow-up-circle"/>
                                                    </span>
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control lpad0"
                                                                placeholder="$0"
                                                                name="i_salary"
                                                                value={this.state.i_salary}
                                                                onChange={this.onInputChange}
                                                                onBlur={this.onBlur}
                                                            />
                                                        </span>
                                                    </span>

                                                    <span className={(this.state.is_joint) ? 'd-flex  height60' : 'hide'}>
                                                        <span className="t_black pmedium">
                                                            <PriceField
                                                                className="form-control rpad0"
                                                                placeholder="$0"
                                                                name="p_salary"
                                                                value={this.state.p_salary}
                                                                onChange={this.onInputChange}
                                                                onBlur={this.onBlur}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Tax Saving
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control lpad0"
                                                                placeholder="$0"
                                                                name="i_tax_offset"
                                                                value={this.state.i_tax_offset}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>

                                                    <span className={(this.state.is_joint) ? 'd-flex  height60' : 'hide'}>
                                                        <span className="t_black pmedium">
                                                            <PriceField
                                                                className="form-control rpad0"
                                                                placeholder="$0"
                                                                name="p_tax_offset"
                                                                value={this.state.p_tax_offset}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Non-taxable Income
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control lpad0"
                                                                placeholder="$0"
                                                                name="i_nt_income"
                                                                value={this.state.i_nt_income}
                                                                onChange={this.onInputChange}
                                                                onBlur={this.onBlur}
                                                            />
                                                        </span>
                                                    </span>

                                                    <span className={(this.state.is_joint) ? 'd-flex  height60' : 'hide'}>
                                                        <span className="t_black pmedium">
                                                            <PriceField
                                                                className="form-control rpad0"
                                                                placeholder="$0"
                                                                name="p_nt_income"
                                                                value={this.state.p_nt_income}
                                                                onChange={this.onInputChange}
                                                                onBlur={this.onBlur}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Total Net Income
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <span className="d-flex  height60">
                                                        <span className="t_black pmedium">
                                                            <PriceField
                                                                className="form-control lpad0"
                                                                placeholder="$0"
                                                                name="total_net_income"
                                                                value={this.state.total_net_income}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious} loading={this.state.loading_previous} />

                                            <MiddleButton text="Tax Benefits" onClick={this.hideShowDialog2}/>

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            <CurrentTaxableIncome
                                show_dialog1={this.state.show_dialog1}
                                is_joint={this.state.is_joint}
                                investor={this.state.investor}
                                partner={this.state.partner}
                                i_other_income={this.state.i_other_income}
                                p_other_income={this.state.p_other_income}
                                other_income={this.state.other_income}
                                i_work_expenses={this.state.i_work_expenses}
                                p_work_expenses={this.state.p_work_expenses}
                                work_expenses={this.state.work_expenses}
                                i_other_expenses={this.state.i_other_expenses}
                                p_other_expenses={this.state.p_other_expenses}
                                other_expenses={this.state.other_expenses}
                                onInputChange={this.onInputChange}
                                hideShowDialog1={this.hideShowDialog1}
                            />

                            <TaxBenefits
                                show_dialog2={this.state.show_dialog2}
                                show_dialog3={this.state.show_dialog3}
                                is_joint={this.state.is_joint}
                                investor={this.state.investor}
                                partner={this.state.partner}
                                i_income={this.state.i_income}
                                p_income={this.state.p_income}
                                income={this.state.income}
                                i_rental_income={this.state.i_rental_income}
                                p_rental_income={this.state.p_rental_income}
                                rental_income={this.state.rental_income}
                                i_total_income={this.state.i_total_income}
                                p_total_income={this.state.p_total_income}
                                total_income={this.state.total_income}
                                i_rental_deduction={this.state.i_rental_deduction}
                                p_rental_deduction={this.state.p_rental_deduction}
                                rental_deduction={this.state.rental_deduction}
                                i_new_tax_income={this.state.i_new_tax_income}
                                p_new_tax_income={this.state.p_new_tax_income}
                                new_tax_income={this.state.new_tax_income}
                                i_present_tax={this.state.i_present_tax}
                                p_present_tax={this.state.p_present_tax}
                                present_tax={this.state.present_tax}
                                i_new_tax={this.state.i_new_tax}
                                p_new_tax={this.state.p_new_tax}
                                new_tax={this.state.new_tax}
                                i_tax_saving={this.state.i_tax_saving}
                                p_tax_saving={this.state.p_tax_saving}
                                tax_saving={this.state.tax_saving}
                                hideShowDialog2={this.hideShowDialog2}
                                hideShowDialog3={this.hideShowDialog3}
                            />

                            <TaxScales
                                show_dialog3={this.state.show_dialog3}
                                show_dialog4={this.state.show_dialog4}
                                property_id={this.state.property_id}
                                it_type={this.state.it_type}
                                changeITType={this.changeITType}
                                hideShowDialog3={this.hideShowDialog3}
                                hideShowDialog4={this.hideShowDialog4}
                                showNotification={this.showNotification}
                                changeTaxCredit={this.changeTaxCredit}
                            />

                            <TaxOffset
                                show_dialog4={this.state.show_dialog4}
                                property_id={this.state.property_id}
                                it_type={this.state.it_type}
                                changeITType={this.changeITType}
                                hideShowDialog4={this.hideShowDialog4}
                                showNotification={this.showNotification}
                            />
                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col xl={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        interest_term={this.state.interest_term}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        interest_term_back={this.state.interest_term_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                        loans={this.state.loans}
                        is_existing_property={this.state.is_existing_property}
                    />
                </Col>
            </Row>
        </div>

    }
}