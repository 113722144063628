import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { getNotificationObject, getSubscriptionPageData} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import {Redirect} from 'react-router';
import config from 'config/config';
import classnames from 'classnames';

class Subscription extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subscription_type: 'PERSONAL',
            subscription_interval: 'MONTHLY_INTERVAL',

            //plan prices
            personal_monthly: 0,
            personal_yearly: 0,
            professional_monthly: 0,
            professional_yearly: 0,
            enterprise_monthly: 0,
            enterprise_yearly: 0,
            has_trial: true,
            redirect_to_purchase: false,
            redirect_to_manage: false,
            redirect_to_dashboard: false,
            loading: false,
        }


        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onContinue = this.onContinue.bind(this);
        this.changeSubscriptionType = this.changeSubscriptionType.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onContinue(tab) {
        this.changeSubscriptionType(tab);
        this.setState({
            redirect_to_purchase: true
        });
    }

    handleSwitch() {
        let subscription_interval = 'MONTHLY_INTERVAL';
        if (this.state.subscription_interval !== 'YEARLY_INTERVAL') {
            subscription_interval = 'YEARLY_INTERVAL';
        }

        this.setState({
            subscription_interval: subscription_interval
        })
    }

    changeSubscriptionType = (tab) => {
        if (this.state.subscription_type !== tab) {
            this.setState({
                subscription_type: tab
            });
        }
    }

    componentDidMount() {
        let subscription = getSubscriptionPageData();
        if (subscription) {
            this.setState(subscription);
        }
    }

    render() {

        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        if (this.state.redirect_to_purchase) {
            return <Redirect
                to={{
                    pathname: config.site_url.subscription_purchase,
                    subscription_type: this.state.subscription_type,
                    subscription_interval: this.state.subscription_interval,
                    has_trial: this.state.has_trial
                }}
            />;
        } else {
            if (this.state.redirect_to_manage) {
                return <Redirect to={config.site_url.subscription_manage} />;
            }
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box subscription-plan">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Choose your subscription
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">There's something for everyone.</p>
                        </Col>
                    </Row>

                    <div id="subscription">
                        <Card>
                            <CardBody className="">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                'active': this.state.subscription_interval === 'MONTHLY_INTERVAL'
                                            })}
                                            onClick={this.handleSwitch.bind(this)}
                                        >
                                            Monthly Billing
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                'active': this.state.subscription_interval === 'YEARLY_INTERVAL'
                                            })}
                                            onClick={this.handleSwitch.bind(this)}
                                        >
                                             Annually Billing

                                             <span className={"offer"}>Save 25% </span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <Row>
                                    <Col xs="12 d-flex justify-content-center">
                                        <div className="sub_bx bx-1 text-center">

                                            <h3 className="sub_tit plight f32 t_gray">Personal</h3>

                                            <p className={"short-desc"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>

                                            <h4 className="charge">
                                                <span className="dlr">$</span>
                                                <span className="amnt">{this.state.subscription_interval === 'MONTHLY_INTERVAL' ? this.state.personal_monthly : this.state.personal_yearly}</span>
                                                <span className="per"> per <br/> {this.state.subscription_interval === 'MONTHLY_INTERVAL' ? 'month' : 'year'} </span>
                                            </h4>

                                            <Button
                                                size="lg btn jumbo_btn"
                                                type="button"
                                                onClick={this.onContinue.bind(this, 'PERSONAL')}
                                                block
                                            >
                                                choose personal
                                            </Button>


                                            <ul className="plan_list">
                                                <li>7 days free trial</li>
                                                <li>Supports 3 devices</li>
                                                <li>Up to 10 properties</li>
                                                <li>Manage personal portfolio</li>
                                                <li></li>
                                            </ul>
                                        </div>

                                        <div className="sub_bx bx-2 text-center">
                                            <h3 className="sub_tit plight f32 t_gray">Professional</h3>

                                            <p className={"short-desc"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>

                                            <h4 className="charge">
                                                <span className="dlr">$</span>
                                                <span className="amnt">{this.state.subscription_interval === 'MONTHLY_INTERVAL' ? this.state.professional_monthly : this.state.professional_yearly}</span>
                                                <span className="per"> per <br/> {this.state.subscription_interval === 'MONTHLY_INTERVAL' ? 'month' : 'year'} <br/>/user </span>
                                            </h4>

                                            <Button
                                                size="lg btn jumbo_btn"
                                                type="button"
                                                onClick={this.onContinue.bind(this, 'PROFESSIONAL')}
                                                block
                                            >
                                                choose professional
                                            </Button>

                                            <ul className="plan_list">
                                                <li>7 days free trial</li>
                                                <li>Supports 3 devices</li>
                                                <li>Unlimited clients</li>
                                                <li>Unlimited properties</li>
                                                <li>Unlimited reports</li>
                                            </ul>

                                        </div>

                                        {/*<div className="sub_bx bx-3 text-center">
                                            <h3 className="sub_tit plight f32 t_gray">Enterprise</h3>
                                            <p className={"short-desc"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>

                                            <h4 className="charge">
                                                <span className="dlr">$</span>
                                                <span className="amnt">{this.state.subscription_interval === 'MONTHLY_INTERVAL' ? this.state.enterprise_monthly : this.state.enterprise_yearly}</span>
                                                <span className="per"> per <br/> {this.state.subscription_interval === 'MONTHLY_INTERVAL' ? 'month' : 'year'} <br/>/user </span>
                                            </h4>

                                            <Button
                                                size="lg btn jumbo_btn"
                                                type="button"
                                                onClick={this.onContinue.bind(this, 'ENTERPRISE')}
                                                block
                                            >
                                                choose Enterprise
                                            </Button>

                                            <ul className="plan_list">
                                                <li>7 days free trial</li>
                                                <li>Supports 3 devices</li>
                                                <li>Unlimited clients</li>
                                                <li>Unlimited properties</li>
                                                <li>Unlimited reports</li>
                                            </ul>

                                        </div>*/}
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>

                        <p className="mt-1 txt-gray text-center">
                            <a className="custom-ac-link" href={config.hash + config.site_url.subscription_custom}>Need custom account?</a><br/>
                            Need help? Contact us {config.contact_no}
                        </p>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Subscription;
