import React from 'react';
import ReactDOM from 'react-dom';
//import { createBrowserHistory } from 'history';
import indexRoutes from './routes/index.jsx';
import {Route, Switch, BrowserRouter, HashRouter} from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import config from './config/config'

import './assets/scss/style.css';
import './assets/scss/all/prpty.scss';

const stripePromise = loadStripe(config.stripe_key);
//const hist = createBrowserHistory();

ReactDOM.render(
    <div>
        <Elements stripe={stripePromise}>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/linkedin" component={LinkedInPopUp} />
                </Switch>
            </BrowserRouter>

            <HashRouter>
                <Switch>
                    {indexRoutes.map((prop, key) => {
                        return <Route path={prop.path} key={key} component={prop.component}/>;
                    })}
                </Switch>
            </HashRouter>
        </Elements>
    </div>
    , document.getElementById('root')
);
