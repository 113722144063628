import React from 'react';
import {canAddProperty, getMyProfile, getNotificationObject} from "../../services/helper";
import {Redirect} from 'react-router';
import config from 'config/config';
import DashboardTabs from "../../components/dashboard/dashboard-tabs";
import PropertyTable from "../../components/dashboard/property-table";
import ReactNotification from "react-notifications-component";
import {getClientId} from "../../services/config-helper";
import axios from "../../services/axios";

class PropertyList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect_to_dashboard: false,
            dashboard: {
                clients: 0,
                properties: 0,
                recent_properties: [],
                account_type: 1,
            },
            can_add: true,

            loading: false,
            properties: [],
            paginator: {},

            filter: {
                search_by: '',
                search_text: '',
            }
        };

        this.fetchProperties = this.fetchProperties.bind(this);
        this.setProperties = this.setProperties.bind(this);
        this.changeFilter = this.changeFilter.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    fetchProperties(page, filter) {
        if (page !== '') {
            this.setState({
                loading: true
            });

            let search_by = '';
            let search_text = '';
            if (typeof filter !== 'undefined' && typeof filter.search_by !== 'undefined') {
                if (filter.search_by !== '' && filter.search_text !== '') {
                    search_by = (filter.search_by !== '') ? filter.search_by : '';
                    search_text = (filter.search_text !== '') ? filter.search_text : '';
                }
            } else {
                if (this.state.filter.search_by !== '' && this.state.filter.search_text !== '') {
                    search_by = (this.state.filter.search_by !== '') ? this.state.filter.search_by : '';
                    search_text = (this.state.filter.search_text !== '') ? this.state.filter.search_text : '';
                }
            }

            let postData = {
                client_id: getClientId() || '',
                page: page,
                search_by: search_by,
                search_text: search_text,
            };

            axios.post(config.get_properties, postData).then(res => {
                if (res.success) {
                    this.setProperties(res);
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                });
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    setProperties(res) {

        let current_properties = res.data.property_list.length;
        let can_add = canAddProperty(current_properties, this.state.account_type);
        this.setState({
            properties: res.data.property_list,
            paginator: res.data.pagination,
            can_add: can_add
        })
    }

    changeFilter(filter) {
        this.fetchProperties(1, filter);

        this.setState({
            filter: filter
        })
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        let profile = getMyProfile();
        if (profile) {
            this.setState({
                account_type: profile.account_type
            });

            if (profile.account_type === 2) {
                let client_id = getClientId() || '';
                if (client_id === '') {
                    this.setState({
                        redirect_to_dashboard: true,
                    })
                }
            }
        }

        axios.get(config.dashboard).then(res => {
            if (res.success) {
                this.setState({
                    dashboard: res.data
                })
            } else {
                this.showNotification(res.message, 'danger')
            }
        });

        this.fetchProperties(1);
    }

    render() {

        //if personal account redirect to property screen
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        return (<div className="page-content no-gutters  container-fluid">

                <ReactNotification ref={this.notificationDOMRef} />

                <div className="client_wrap">

                    <DashboardTabs
                        dashboard={this.state.dashboard}
                        account_type={this.state.account_type}
                        page="property"
                        changeFilter={this.changeFilter}
                    />

                    <PropertyTable
                        can_add={this.state.can_add}
                        properties={this.state.properties}
                        paginator={this.state.paginator}
                        loading={this.state.loading}
                        client_id={getClientId() || ''}
                        fetchProperties={this.fetchProperties}
                        showNotification={this.showNotification}
                        setProperties={this.setProperties}
                    />
                </div>
            </div>
        );
    }
}

export default PropertyList;