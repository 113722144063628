import React from 'react'
import {Redirect} from 'react-router';
import {isLogin} from "../services/helper";
import config from 'config/config';
import { storeInLocalStorage } from '../services/helper';
import axios from "../services/axios";

export default function (ComposedComponent) {

    class AuthMiddleware extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                authenticate: true
            }

            this.getProfile = this.getProfile.bind(this);
        }

        // Push to login route if not authenticated on mount
        UNSAFE_componentWillMount() {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            } else {
                this.getProfile();
            }
        }

        // Push to login route if not authenticated on update
        UNSAFE_componentWillUpdate(nextProps) {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            } else {
                this.getProfile();
            }
        }

        getProfile() {
            axios.get(config.get_profile).then(res => {
                if (res.success) {
                    storeInLocalStorage(res.data);
                }
            })
        }

        // Otherwise render the original component
        render() {
            if (this.state.authenticate === true) {
                return <ComposedComponent {...this.props}/>
            } else {
                return <Redirect to={config.site_url.login}/>;
            }
        }
    }

    return AuthMiddleware

}