import React from 'react';
import {
    Row,
    Col,
    Form,
    Progress,
    Input,
    CustomInput,
    Label
} from 'reactstrap';
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getDefaultAgentComissionRate,
    getLoanType,
    getEntityId,
    getTypeOfProperty
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import config from "config/config";
import {
    getNotificationObject, setExpensesTabState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import ExtraRepayment from "../sections/extra-repayment";
import PercentageField from "../../../components/numbers/PercentageField";
import PriceField from "../../../components/numbers/PriceField";
import axios from "../../../services/axios";
import ReactTooltip from 'react-tooltip';
import RefreshProjection from "../../../components/properlytics/refresh-projection";

export default class InvestmentExpenses extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        this.state = {
            is_edit: false,
            property_id: '',
            has_property_manager: true,
            property_manager: '',
            agent_commission: 0,
            agent_commision_per: getDefaultAgentComissionRate(),
            letting_fee: 0,
            rates: 0,
            has_insurance: true,
            has_building_ins: true,
            building_ins_name: '',
            building_ins_amount: 0,
            has_landlord_ins: false,
            landlord_ins_name: '',
            landlord_ins_amount: 0,
            maintenance: 0,
            body_corporate_amount: 0,
            has_other_expenses: false,
            cleaning: 0,
            post_control: 0,
            mowing: 0,
            other_expenses: 0,
            total_expenses: 0,

            show_alert: true,

            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            interest_term: 5,
            to_year: 30,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            interest_term_back: 5,
            to_year_back: 30,
            loan_type_back: getLoanType().value,
            loans: [],

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog: false,
            redirect_to_dashboard: false,

            is_existing_property: true,
            refreshLoading: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.getAgentCommission = this.getAgentCommission.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.refreshProjection = this.refreshProjection.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    closeAlert() {
        this.setState({
            show_alert: false
        })
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'building_ins_name' || name === 'landlord_ins_name' || name === 'property_manager') {
            this.setState({
                [name]: value
            });
        } else {
            if (!isNaN(value) || value === '') {
                this.setState({
                    [name]: value
                });

                if (name === 'agent_commision_per') {
                    this.getAgentCommission(this.state.property_id, value);
                } else {
                    this.calculateTotal(name, value);
                }
            }
        }
    }

    onCheckboxChange(name, event) {
        event.preventDefault();
        let state = this.state;
        let checked = !(state[name]);

        this.setState({
            [name]: checked
        });
        this.calculateTotal(name, checked);
    }

    getAgentCommission(property_id, agent_commision_per) {
        if (property_id !== '') {
            let postData = new FormData();
            postData.append('property_id', property_id);
            postData.append('agent_commision_per', agent_commision_per);

            axios.post(config.cal_agent_commission, postData).then(res => {
                if (res.success) {
                    this.setState({
                        agent_commission: res.data.agent_commission,
                    })
                    this.calculateTotal('agent_commission', res.data.agent_commission);
                }
            });
        }
    }

    calculateTotal(name, value) {

        let total = 0;

        let has_property_manager = (name === 'has_property_manager') ? value : this.state.has_property_manager;
        if (has_property_manager) {
            let agent_commission = (this.state.agent_commission !== '') ? parseFloat(this.state.agent_commission) : 0;
            if (name === 'agent_commission') {
                agent_commission = (value !== '') ? parseFloat(value) : 0;
            }
            total += agent_commission;

            let letting_fee = (this.state.letting_fee !== '') ? parseFloat(this.state.letting_fee) : 0;
            if (name === 'letting_fee') {
                letting_fee = (value !== '') ? parseFloat(value) : 0;
            }
            total += letting_fee;

            let rates = (this.state.rates !== '') ? parseFloat(this.state.rates) : 0;
            if (name === 'rates') {
                rates = (value !== '') ? parseFloat(value) : 0;
            }
            total += rates;
        }

        let has_insurance = (name === 'has_insurance') ? value : this.state.has_insurance;
        if (has_insurance) {
            let has_building_ins = (name === 'has_building_ins') ? value : this.state.has_building_ins;
            if (has_building_ins) {
                let building_ins_amount = (this.state.building_ins_amount !== '') ? parseFloat(this.state.building_ins_amount) : 0;
                if (name === 'building_ins_amount') {
                    building_ins_amount = (value !== '') ? parseFloat(value) : 0;
                }
                total += building_ins_amount;
            }

            let has_landlord_ins = (name === 'has_landlord_ins') ? value : this.state.has_landlord_ins;
            if (has_landlord_ins) {
                let landlord_ins_amount = (this.state.landlord_ins_amount !== '') ? parseFloat(this.state.landlord_ins_amount) : 0;
                if (name === 'landlord_ins_amount') {
                    landlord_ins_amount = (value !== '') ? parseFloat(value) : 0;
                }
                total += landlord_ins_amount;
            }
        }

        let body_corporate_amount = (this.state.body_corporate_amount !== '') ? parseFloat(this.state.body_corporate_amount) : 0;
        if (name === 'body_corporate_amount') {
            body_corporate_amount = (value !== '') ? parseFloat(value) : 0;
        }
        total += body_corporate_amount;

        let has_other_expenses = (name === 'has_other_expenses') ? value : this.state.has_other_expenses;
        if (has_other_expenses) {
            let maintenance = (this.state.maintenance !== '') ? parseFloat(this.state.maintenance) : 0;
            if (name === 'maintenance') {
                maintenance = (value !== '') ? parseFloat(value) : 0;
            }
            total += maintenance;

            let cleaning = (this.state.cleaning !== '') ? parseFloat(this.state.cleaning) : 0;
            if (name === 'cleaning') {
                cleaning = (value !== '') ? parseFloat(value) : 0;
            }
            total += cleaning;

            let post_control = (this.state.post_control !== '') ? parseFloat(this.state.post_control) : 0;
            if (name === 'post_control') {
                post_control = (value !== '') ? parseFloat(value) : 0;
            }
            total += post_control;

            let mowing = (this.state.mowing !== '') ? parseFloat(this.state.mowing) : 0;
            if (name === 'mowing') {
                mowing = (value !== '') ? parseFloat(value) : 0;
            }
            total += mowing;

            let other_expenses = (this.state.other_expenses !== '') ? parseFloat(this.state.other_expenses) : 0;
            if (name === 'other_expenses') {
                other_expenses = (value !== '') ? parseFloat(value) : 0;
            }
            total += other_expenses;
        }

        this.setState({
            total_expenses: parseFloat(total).toFixed(2)
        })
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    refreshProjection() {
        this.saveData(true, true);
    }

    saveData(isNext = true, fromRefresh = false) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        if (fromRefresh) {
            loadingState = 'refreshLoading';
        }

        this.setState({
            [loadingState]: true
        })

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);
        postData.append('has_property_manager', (this.state.has_property_manager) ? 1 : 0);
        postData.append('property_manager', this.state.property_manager);
        postData.append('agent_commision_per', (this.state.agent_commision_per) ? this.state.agent_commision_per : 0);
        postData.append('agent_commission', (this.state.agent_commission) ? this.state.agent_commission : 0);
        postData.append('letting_fee', (this.state.letting_fee) ? this.state.letting_fee : 0);
        postData.append('rates', (this.state.rates) ? this.state.rates : 0);
        postData.append('has_insurance', (this.state.has_insurance) ? 1 : 0);
        postData.append('has_building_ins', (this.state.has_building_ins) ? 1 : 0);
        postData.append('building_ins_name', (this.state.building_ins_name) ? this.state.building_ins_name : '');
        postData.append('building_ins_amount', (this.state.building_ins_amount) ? this.state.building_ins_amount : 0);
        postData.append('has_landlord_ins', (this.state.has_landlord_ins) ? 1 : 0);
        postData.append('landlord_ins_name', (this.state.landlord_ins_name) ? this.state.landlord_ins_name : '');
        postData.append('landlord_ins_amount', (this.state.landlord_ins_amount) ? this.state.landlord_ins_amount : 0);
        postData.append('has_body_corporate', 1);
        postData.append('body_corporate_amount', (this.state.body_corporate_amount) ? this.state.body_corporate_amount : 0);
        postData.append('has_other_expenses', (this.state.has_other_expenses) ? 1 : 0);
        postData.append('maintenance', (this.state.maintenance) ? this.state.maintenance : 0);
        postData.append('cleaning', (this.state.cleaning) ? this.state.cleaning : 0);
        postData.append('post_control', (this.state.post_control) ? this.state.post_control : 0);
        postData.append('mowing', (this.state.mowing) ? this.state.mowing : 0);
        postData.append('other_expenses', (this.state.other_expenses) ? this.state.other_expenses : 0);
        postData.append('total_expenses', (this.state.total_expenses) ? this.state.total_expenses : 0);

        axios.post(config.add_investment_expenses, postData).then(res => {
            if (res.success) {
                if (fromRefresh) {
                    this.setState({
                        overview: res.data.property.overview,
                        property: res.data.property
                    });
                } else {
                    this.showNotification(res.message, 'success');
                    this.setState({
                        [goToState]: true,
                        property: res.data.property
                    })
                }
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setExpensesTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        })
        this.getAgentCommission(stateData.property_id, stateData.agent_commision_per);
        this.calculateTotal('letting_fee', stateData.letting_fee);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.investment_expenses + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    axios.get(config.get_property + property_id).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let client_id = (getClientId()) || '';
            let entity_id = (getEntityId()) || '';
            let type_of_property = (getTypeOfProperty()) || '';
            if (client_id === '' || entity_id === '' || type_of_property === '') {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.economic_assumption + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.investment_outcome + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />
            <ReactTooltip  textColor='#000000' backgroundColor='#ffffff' border borderColor='#cccccc' effect='solid'/>

            <Row>
                <Col xl="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*INVESTMENT EXPENSES*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className="plisting_form rect_bx flip_bx">
                                <div className="head_tron d-flex">
                                    <h2 className="head d-flex fx_w t_green">
                                        Investment Expenses
                                        <p data-tip="If you live at this property and do not collect any rent, please leave this section blank, except for any insurances, maintenance and body corporate." className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                     </h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>

                                    <RefreshProjection
                                        show={this.state.is_edit}
                                        loading={this.state.refreshLoading}
                                        onClick={this.refreshProjection}
                                    />
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        {/*<div className={this.state.show_alert ? "tp_row pbtm0 ptop0 alert-note" : "hide"}>
                                            <div className="height60 d-flex  w100">
                                                If you live at this property and do not collect any rent, please leave this section blank, except for any insurances, maintenance and body corporate.
                                                 <span><i onClick={this.closeAlert} className="f20 icon-close" /></span>
                                            </div>
                                        </div>*/}

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w">{getTypeOfProperty() === 1 ? 'Do you have a property manager?' : 'Will you have a property manager?'}</h4>
                                                <Label className="switch" onClick={this.onCheckboxChange.bind(this, 'has_property_manager')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="has_property_manager"
                                                        checked={this.state.has_property_manager}
                                                    />
                                                    <span className="slider round1"/>
                                                </Label>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_property_manager) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Manager Name
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <Input
                                                               type="text"
                                                               className="form-control"
                                                               placeholder="Property Manager"
                                                               name="property_manager"
                                                               value={this.state.property_manager}
                                                               onChange={this.onInputChange}
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_property_manager) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">Agent Commission</h4>
                                                    <div className="hd_row d-flex">
                                                        <div className="slct d-flex">
                                                            <div className="p_type_opt">
                                                                <PercentageField
                                                                    placeholder="Commission Rate"
                                                                    name="agent_commision_per"
                                                                    value={this.state.agent_commision_per}
                                                                    onChange={this.onInputChange}
                                                                />
                                                            </div>

                                                            <div className="p_type_cat">
                                                                <PriceField
                                                                    className="form-control text-right"
                                                                    placeholder="$0"
                                                                    name="agent_commission"
                                                                    value={this.state.agent_commission}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_property_manager) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Letting Fees
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <PriceField
                                                               placeholder="$0"
                                                               name="letting_fee"
                                                               value={this.state.letting_fee}
                                                               onChange={this.onInputChange}
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_property_manager) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Rates (P/Y)
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <PriceField
                                                               placeholder="$0"
                                                               name="rates"
                                                               value={this.state.rates}
                                                               onChange={this.onInputChange}
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w">{getTypeOfProperty() === 1 ? 'Do you have Insurance?' : 'Will you have Insurance?'}</h4>
                                                <Label className="switch" onClick={this.onCheckboxChange.bind(this, 'has_insurance')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="has_insurance"
                                                        checked={this.state.has_insurance}
                                                    />
                                                    <span className="slider round1"/>
                                                </Label>
                                            </div>
                                        </div>

                                        <div className={`tp_row pr-0 pt-0 pb-0 height60` + ((this.state.has_insurance) ? `` : ` hide`)} >
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w d-flex">Building
                                                <p data-tip="The average building and contents insurance cost in Australia is $1640.76 per year (Finder, 2021)" className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                                </h4>
                                                <Label className="switch" onClick={this.onCheckboxChange.bind(this, 'has_building_ins')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="has_building_ins"
                                                        checked={this.state.has_building_ins}
                                                    />
                                                    <span className="slider round1"/>
                                                </Label>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_building_ins) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Insurer Name
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <Input
                                                               type="text"
                                                               className="form-control text-right"
                                                               placeholder="Insurer Name"
                                                               name="building_ins_name"
                                                               value={this.state.building_ins_name}
                                                               maxLength={config.price_max_length}
                                                               onChange={this.onInputChange}
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_building_ins) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Amount
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="Amount"
                                                                name="building_ins_amount"
                                                                value={this.state.building_ins_amount}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`tp_row pr-0 pt-0 pb-0 height60` + ((this.state.has_insurance) ? `` : ` hide`)} >
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w d-flex">Landlord</h4>                                                

                                                <Label className="switch" onClick={this.onCheckboxChange.bind(this, 'has_landlord_ins')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="has_landlord_ins"
                                                        checked={this.state.has_landlord_ins}
                                                    />
                                                    <span className="slider round1"/>
                                                </Label>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_landlord_ins) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Insurer Name
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <Input
                                                               type="text"
                                                               className="form-control text-right"
                                                               placeholder="Insurer Name"
                                                               name="landlord_ins_name"
                                                               value={this.state.landlord_ins_name}
                                                               maxLength={config.price_max_length}
                                                               onChange={this.onInputChange}
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_landlord_ins) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Amount
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="Amount"
                                                                name="landlord_ins_amount"
                                                                value={this.state.landlord_ins_amount}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Body Corporate (P/Y)</h4>
                                                <PriceField
                                                    placeholder="$0"
                                                    name="body_corporate_amount"
                                                    value={this.state.body_corporate_amount}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                {/* Earlier known as "Other Cost" */}
                                                <h4 className="sm_tit fx_w t_black">Maintenance Costs</h4>  

                                                <Label className="switch" onClick={this.onCheckboxChange.bind(this, 'has_other_expenses')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="has_other_expenses"
                                                        checked={this.state.has_other_expenses}
                                                    />
                                                    <span className="slider round1"/>
                                                </Label>
                                            </div>


                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_other_expenses) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Maintenance
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="maintenance"
                                                                value={this.state.maintenance}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_other_expenses) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Cleaning
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="cleaning"
                                                                value={this.state.cleaning}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_other_expenses) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Pest Control
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="post_control"
                                                                value={this.state.post_control}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_other_expenses) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Gardening
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="mowing"
                                                                value={this.state.mowing}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.has_other_expenses) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Other Expenses
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="other_expenses"
                                                                value={this.state.other_expenses}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Total Expenses</h4>
                                                <PriceField
                                                    placeholder="$0"
                                                    name="total_expenses"
                                                    value={this.state.total_expenses}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious} loading={this.state.loading_previous} />

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col xl={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        interest_term={this.state.interest_term}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        interest_term_back={this.state.interest_term_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                        loans={this.state.loans}
                        is_existing_property={this.state.is_existing_property}
                    />
                </Col>
            </Row>
        </div>

    }
}