import React from 'react';
import {Button} from 'reactstrap';

export default class DuplicatePropertyList extends React.Component { 
    render() {
        let {loading, display, property_list, duplicateProperty, showHidePopup} = this.props;


        return <div className={(display) ? "add-entity open duplicate-props" : "add-entity"}>
            <div className="rect_bx property-row">
                <div className="head_tron d-flex justify-content-between">
                    <h2 className="head fsize22 w_nowrap fx_w t_green">Duplicate Property</h2>

                    <span className="close-sider mbtm0 fsize14 d-flex" onClick={showHidePopup.bind(this)}>
                        <i className="f20 icon-close" />
                    </span>
                </div>

                <div className={(loading) ? "w100 " : "hide"}>
                    <div className="page-loader show" />
                </div>

                <div  className={(loading) ? "hide" : "w100 inner_bx space20"}>
                    <PropertiesMap
                        properties={property_list.existing}
                        duplicateProperty={duplicateProperty}
                    />

                    <PropertiesMap
                        properties={property_list.future}
                        duplicateProperty={duplicateProperty}
                    />
                </div>
            </div>
        </div>
    }
}


const PropertiesMap = ({properties, duplicateProperty}) => {
    if (!properties || properties.length === 0) {
        return ''
    }

    return properties.map((property, key) => {
        return (
            <div className="inner-row" key={key}>
                <div className="d-flex align-items-center justify-content-between">
                    <div  className="left-aside">
                        <div className="d-flex  align-items-center justify-content-between">
                            <img src={property.image} className="prpt_img" width="100" height="100" alt="Property_Image" />

                            <div className="top_mdl">
                                <div className="d-flex">
                                    <p className="prop_add mbtm0">{property.address}</p>
                                </div>

                                <ul className="list-inline d-flex mbtm0 pro_faci">
                                    <li>
                                        Bed  <span className="no">{property.bedrooms}</span>
                                    </li>
                                    <li>
                                        Bath  <span className="no">{property.bathrooms}</span>
                                    </li>
                                    <li>
                                        Car  <span className="no">{property.car_space}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="right-aside  d-flex  align-items-center justify-content-between">

                        <Button
                            className="block-btn bg_green"
                            onClick={duplicateProperty.bind(this, property.property_id)}
                        >
                            Select
                        </Button>
                    </div>
                </div>
            </div>
        )
    });
}
