import React from 'react';
import PropertyMap from "../portfolio/property-map";

export default class PropertyList extends React.Component {
    render() {
        let {display, list_loading, properties, showNotification, deleteConfirm, setData} = this.props;

        return <div className={(display) ? 'property-row' : 'hide'} >
            {/* Loading */}
            <div className={(list_loading) ? "" : "hide"}>
                <div className="table-loader show" />
            </div>

            {/* Not found any properties */}
            <div className={(!list_loading && properties.existing.length === 0 && properties.future.length === 0) ? "exist-prop" : "hide"}>
                <div className="text-center">
                    Properties not found
                </div>
            </div>

            {/* Existing property */}
            <div className={(!list_loading && properties.existing.length > 0) ? "exist-prop" : "hide"}>
                <h5 className="property-type  title-devider">
                    <span>Existing Properties</span>
                </h5>

                <PropertyMap
                    properties={properties.existing}
                    showNotification={showNotification}
                    deleteConfirm={deleteConfirm}
                    setData={setData}
                />
            </div>

            {/* Future property */}
            <div className={(!list_loading  && properties.future.length > 0) ? "future-prop" : "hide"}>
                <h5 className="property-type  title-devider black-vr">
                    <span>Future Properties</span>
                </h5>

                <PropertyMap
                    properties={properties.future}
                    showNotification={showNotification}
                    deleteConfirm={deleteConfirm}
                    setData={setData}
                />
            </div>
        </div>
    }
}