import React from 'react';
import {
    Button,
    Row,
    Col,
    Input,
} from 'reactstrap';
import RecentProperty from "./recent-property";
import Select from "../../components/properlytics/select";
import {getConfig} from 'services/helper';
import clients from "assets/images/client-icon.png";
import properties from "assets/images/property-icon.png";
import config from "config/config";

export default class DashboardTabs extends React.Component {

    constructor(props) {
        super(props);

        let searchOptions = [];
        this.state = {
            searchOptions: searchOptions,
            groupedOptions: [
                {
                    options: searchOptions,
                },
            ],

            search_by: 1,
            search_text: '',
        }

        this.onSelectChange = this.onSelectChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.changeSearchFilterOptions = this.changeSearchFilterOptions.bind(this);
    }

    onSelectChange(selected) {
        this.props.changeFilter({
            search_by: selected.value,
            search_text: this.state.search_text
        })
        this.setState({
            search_by: selected.value
        });
    }

    onTextChange(e) {
        this.props.changeFilter({
            search_by: this.state.search_by,
            search_text: e.target.value
        })
        this.setState({
            search_text: e.target.value
        });
    }

    changeSearchFilterOptions(account_type) {
        let config = getConfig();
        if (config) {
            let searchOptions = [];
            if (account_type === 2) {
                if (this.props.page === 'dashboard') {
                    searchOptions = config.filters.business_clients;
                } else {
                    searchOptions = config.filters.business_properties;
                }
            } else {
                searchOptions = config.filters.personal_properties;
            }
            this.setState({
                searchOptions: searchOptions,
                groupedOptions: [
                    {
                        options: searchOptions,
                    },
                ]
            })
        }
    }

    componentDidMount() {
        this.changeSearchFilterOptions(this.props.account_type);
    }

    componentDidUpdate(props) {
        const { account_type } = this.props;
        if (props.account_type !== account_type) {
            this.changeSearchFilterOptions(account_type);
        }
    }

	render() {
        let {dashboard, account_type} = this.props;

        let property = (dashboard.recent_properties.length ? dashboard.recent_properties[0] : {});

        return (
            <Row>
                <Col md="6">
                    <Row>
                        <Col md={(account_type === 2) ? 6 : 12}>
                            <div className="ttl_prpty sm_bx grid_bx">
                                <a href={config.hash + config.site_url.properties}>
                                    <div className="bx_info">
                                        <h5 className="pmedium fnormal">
                                            <img src={properties} className="dashboard-user-icon" alt="Icon"/>
                                            Properties
                                        </h5>

                                        <div className="al_btm">
                                            <h6 className="num">{dashboard.properties}</h6>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>

                        <Col md="6" className={(account_type === 2) ? '' : 'hide'}>
                            <div className="ttl_client sm_bx grid_bx">
                                <a href={config.hash + config.site_url.dashboard}>
                                    <div className="bx_info">
                                        <h5 className="pmedium t_black fnormal">
                                            <img src={clients} className="dashboard-user-icon" alt="Icon"/>
                                            Clients
                                        </h5>

                                        <div className="al_btm">
                                            <h6 className="num">{dashboard.clients}</h6>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col md="6">
                    <div className="wt_bx prpty_card">
                        <RecentProperty property={property}/>

                        <div className="btm_part">
                            <div className="d-flex btm-dtl">
                                <div className="search_by">
                                    <span className="t_green mbtm0 fnormal pmedium">
                                        Search via
                                    </span>

                                    <Select
                                        defaultValue={this.state.searchOptions[0]}
                                        options={this.state.groupedOptions}
                                        onChange={this.onSelectChange}
                                    />
                                </div>

                                <div className="q_search">
                                    <Input
                                        type="text"
                                        id="search_text"
                                        name="search_text"
                                        onChange={this.onTextChange}
                                        value={this.state.search_text}
                                        placeholder="SEARCH" />
                                    <Button type="submit">
                                        <i aria-hidden="true" className="fa fa-search" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
		);
	}
}