import React from 'react';
import {
    Row,
    Col,
    InputGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import camera from '../../assets/images/camera.png';
import Avatar from 'react-avatar-edit'

const previewStyle = {
    width: '200px'
}

export default class UploadAvtar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            model: false,
            preview: '',
            picture_url: props.picture_url,
            img_display: props.img_display,
            change: false
        };

        this.toggle = this.toggle.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.onFileLoad = this.onFileLoad.bind(this);
    }

    toggle() {
        if (this.state.model && this.state.change) {
            this.props.updateState('picture', this.state.preview);
            this.props.updateState('picture_url', this.state.preview);
            this.props.updateState('img_display', this.state.img_display);
        } else {
            this.setState({
                change: false,
            })
        }

        this.setState({
            model: !this.state.model
        });
    }

    onClose() {
        this.setState({
            preview: '',
            picture_url: '',
            img_display: false,
        })

        if (this.props.from === 'account' || this.props.from === 'clients') {
            this.props.updateState('remove_picture', true);
        }

    }

    onCrop(preview) {
        if (preview !== this.state.preview) {
            this.setState({
                change: true,
            })
        }

        this.setState({
            preview: preview,
        })
    }

    onFileLoad(image) {
        this.setState({
            picture_url: this.props.picture_url,
            img_display: true,
            change: true,
        })

        if (this.props.from === 'account' || this.props.from === 'clients') {
            this.props.updateState('remove_picture', false);
        }
    }

    componentDidUpdate(props) {
        const { img_display, picture_url } = this.props;
        if (props.img_display !== img_display) {
            this.setState({
                img_display: this.props.img_display
            });

            if (this.props.img_display) {
                this.setState({
                    preview: picture_url
                })
            }
        }

        if (props.picture_url !== picture_url) {
            this.setState({
                picture_url: this.props.picture_url
            })
        }
    }

	render() {

        let { img_display, picture_url, preview } = this.state;

		return (
            <InputGroup>
                <Col md="12">
                    <div className={(img_display ? 'hide' : 'user-img')}>
                        <div className="avtar">
                            <img src={camera} width="40" alt="user_image"/>
                        </div>

                        <div className="change-img" onClick={this.toggle}>
                            <i className="fa fa-plus" />
                        </div>
                    </div>

                    <div className={(img_display ? 'user-img' : 'hide')}>
                        <div className="avtar-img">
                            <img src={(picture_url)} width="40" alt="user_image"/>
                        </div>

                        <div className="change-img" onClick={this.toggle}>
                            <i className="fa fa-pencil-alt" />
                        </div>
                    </div>
                </Col>


                <Modal
                    isOpen={this.state.model}
                    toggle={this.toggle}
                    size="lg"
                    backdrop={true}
                >
                    <ModalHeader toggle={this.toggle}>Upload Image</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm="6">
                                <Avatar
                                    width={390}
                                    imageWidth={390}
                                    height={295}
                                    onCrop={this.onCrop}
                                    onFileLoad={this.onFileLoad}
                                    onClose={this.onClose}
                                    src={picture_url}
                                />
                            </Col>
                            <Col sm="6" className="text-center">
                                <img src={preview} style={previewStyle} className={(preview === '') ? 'hide' : ''} alt="Preview" />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="btn" onClick={this.toggle}>
                            Crop
                        </Button>
                    </ModalFooter>
                </Modal>
            </InputGroup>
		);
	}
}