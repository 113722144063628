import React from 'react';
import {
    Row,
    Col,
    Form,
    Progress,
} from 'reactstrap';
import Select from "../../../components/properlytics/select";
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getRent,
    getRentOptions,
    getLoanType,
    getEntityId,
    getTypeOfProperty
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import config from "config/config";
import {
    getNotificationObject, setOutcomesTabState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import ExtraRepayment from "../sections/extra-repayment";
import PriceField from "../../../components/numbers/PriceField";
import PercentageField from "../../../components/numbers/PercentageField";
import axios from "../../../services/axios";
import ReactTooltip from 'react-tooltip';
import RefreshProjection from "../../../components/properlytics/refresh-projection";

export default class InvestmentOutcomes extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        this.state = {
            is_edit: false,
            property_id: '',
            rent_amount: '',
            rent_duration: getRent(52).value,
            potential_yearly_return: '',
            vacancy_rate: '',
            actual_yearly_rent: '',
            gross_percent: '',

            show_alert: true,


            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            interest_term: 5,
            to_year: 30,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            interest_term_back: 5,
            to_year_back: 30,
            loan_type_back: getLoanType().value,
            loans: [],

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog: false,
            redirect_to_dashboard: false,

            is_existing_property: true,
            is_owner_occupied: false,
            refreshLoading: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onRentDurationChange = this.onRentDurationChange.bind(this);
        this.calculateData = this.calculateData.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.refreshProjection = this.refreshProjection.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    closeAlert() {
        this.setState({
            show_alert: false
        })
    }

    onInputChange(event) {
        if (!isNaN(event.target.value) || event.target.value === '') {
            let name = event.target.name;
            this.setState({
                [name]: event.target.value
            });

            if (name === 'rent_amount') {
                this.calculateData(this.state.purchase_price, event.target.value, this.state.rent_duration, this.state.vacancy_rate);
            } else if (name === 'vacancy_rate') {
                this.calculateData(this.state.purchase_price, this.state.rent_amount, this.state.rent_duration, event.target.value);
            }
        }
    }

    onRentDurationChange(selected) {
        this.setState({
            rent_duration: selected.value,
        });
        this.calculateData(this.state.purchase_price, this.state.rent_amount, selected.value, this.state.vacancy_rate);
    }

    calculateData(property_price, rent_amount, rent_duration, vacancy_rate) {
        let potential_yearly_return = 0;
        rent_duration = (parseInt(rent_duration) === 2) ? 1 : rent_duration;
        if (rent_amount && rent_duration) {
            try {
                potential_yearly_return = (rent_amount * rent_duration);
            } catch (e) {

            }
        }

        if (vacancy_rate.toString() === '' || !vacancy_rate) {
            vacancy_rate = 0;
        }

        let actual_yearly_rent = 0;
        if (potential_yearly_return) {
            try {
                actual_yearly_rent = potential_yearly_return - ((potential_yearly_return * vacancy_rate) / 100);

                actual_yearly_rent = (actual_yearly_rent < 0) ? 0 : actual_yearly_rent;
            } catch (e) {

            }
        }
        let gross_percent = 0;
        if (actual_yearly_rent && property_price) {
            try {
                gross_percent = (actual_yearly_rent / property_price) * 100;
            } catch (e) {

            }
        }
        this.setState({
            potential_yearly_return: potential_yearly_return,
            actual_yearly_rent: actual_yearly_rent,
            gross_percent: gross_percent.toFixed(2),
        })
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    refreshProjection() {
        this.saveData(true, true);
    }

    saveData(isNext = true, fromRefresh = false) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        if (fromRefresh) {
            loadingState = 'refreshLoading';
        }

        this.setState({
            [loadingState]: true
        });

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);
        postData.append('rent_amount', (this.state.rent_amount) ? this.state.rent_amount : 0);
        postData.append('rent_duration', this.state.rent_duration);
        postData.append('potential_yearly_return', (this.state.potential_yearly_return) ? this.state.potential_yearly_return : 0);
        postData.append('vacancy_rate', (this.state.vacancy_rate) ? this.state.vacancy_rate : 0);
        postData.append('actual_yearly_rent', (this.state.actual_yearly_rent) ? this.state.actual_yearly_rent : 0);
        postData.append('gross_percent', (this.state.gross_percent) ? this.state.gross_percent : 0);

        axios.post(config.add_investment_outcomes, postData).then(res => {
            if (res.success) {
                if (fromRefresh) {
                    this.setState({
                        overview: res.data.property.overview,
                        property: res.data.property
                    });
                } else {
                    this.showNotification(res.message, 'success');
                    this.setState({
                        [goToState]: true,
                        property: res.data.property
                    })
                }
            } else {
                this.showNotification(res.message, 'danger');
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setOutcomesTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        });

        this.calculateData(stateData.purchase_price, stateData.rent_amount, stateData.rent_duration, stateData.vacancy_rate);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.investment_outcome + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    axios.get(config.get_property + property_id).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let client_id = (getClientId()) || '';
            let entity_id = (getEntityId()) || '';
            let type_of_property = (getTypeOfProperty()) || '';
            if (client_id === '' || entity_id === '' || type_of_property === '') {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.investment_expenses + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.funding_structure + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />
            <ReactTooltip  textColor='#000000' backgroundColor='#ffffff' border borderColor='#cccccc' effect='solid'/>

            <Row>
                <Col xl="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className="plisting_form rect_bx flip_bx">
                                <div className="head_tron d-flex">
                                    <h2 className="head  d-flex  fx_w t_green">
                                        Investment Outcomes
                                        <p data-tip="If you live at this property and collect rent/board/airbnb, please enter the amount in the section below. If not skip to the next step" className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                    </h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>

                                    <RefreshProjection
                                        show={this.state.is_edit}
                                        loading={this.state.refreshLoading}
                                        onClick={this.refreshProjection}
                                    />
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        <div className={(this.state.show_alert && this.state.is_owner_occupied) ? "tp_row pbtm0 ptop0 alert-note" : "hide"}>
                                            <div className="height60 d-flex  w100">
                                                If you live at this property and do not collect any rent, please leave this section blank.

                                                <span><i onClick={this.closeAlert} className="f20 icon-close" /></span>
                                            </div>
                                        </div>

                                        <div className="tp_row pbtm0 ptop0">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Rent</h4>
                                                <div className="slct d-flex">
                                                    <div className="p_type_opt">
                                                        <PriceField
                                                            placeholder="Enter Rent Amount"
                                                            name="rent_amount"
                                                            value={this.state.rent_amount}
                                                            onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div className="p_type_cat padl10">
                                                        <Select
                                                            defaultValue={getRent()}
                                                            options={getRentOptions()}
                                                            onChange={this.onRentDurationChange}
                                                            value={getRent(this.state.rent_duration)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Potential Yearly Return</h4>
                                                <PriceField
                                                    placeholder="0"
                                                    name="potential_yearly_return"
                                                    value={this.state.potential_yearly_return}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w  items-center d-flex">
                                                    Vacancy <p data-tip="A vacancy of 5% means the property is vacant on average 5% of the year." className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                                </h4>
                                                
                                                <PercentageField
                                                    placeholder="Enter Vacancy Rate"
                                                    name="vacancy_rate"
                                                    value={this.state.vacancy_rate}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Actual Yearly Return</h4>
                                                <PriceField
                                                    placeholder="0"
                                                    name="actual_yearly_rent"
                                                    value={this.state.actual_yearly_rent}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w items-center d-flex items-center">Gross Yield 
                                                <p data-tip="The gross yield is the annual return on an investment prior to taxes and expenses, divided by the current price of the investment." className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                                </h4>
                                                
                                                <PercentageField
                                                    placeholder="0"
                                                    name="gross_percent"
                                                    value={this.state.gross_percent}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>


                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious} loading={this.state.loading_previous} />

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col xl={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        interest_term={this.state.interest_term}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        interest_term_back={this.state.interest_term_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                        loans={this.state.loans}
                        is_existing_property={this.state.is_existing_property}
                    />
                </Col>
            </Row>
        </div>

    }
}