import React from 'react';
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
    Row,
    Col,
} from 'reactstrap';
import validator from "./signup-validators";
import {Redirect} from 'react-router';
import config from 'config/config';
import RightButton from "../../components/properlytics/right-button";
import ReactPixel from 'react-facebook-pixel';
import axios from "../../services/axios";
import DOBField from "../../components/numbers/DOBField";

class SignupForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            password: '',
            account_code: '',
            dob: '',
            redirect: false,
            loading: false,
        }

        this.validators = validator;
        this.onInputChange = this.onInputChange.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onSignup = this.onSignup.bind(this);
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (field === 'email' || field === 'password' || field === 'first_name' || field === 'last_name' ||
                field === 'phone') {
                if (!this.validators[field].valid) {
                    status = false;
                }
            }
        });
        return status;
    }

    showErrors() {
        const fields = ['email', 'password', 'first_name', 'last_name', 'phone'];

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    async onSignup() {
        if (this.validForm()) {
            this.setState({
                loading: true
            });
            axios.get(config.csrf)
                .then(response => {
                    axios.post(config.register, this.state).then(res => {
                        if (res.success) {
                            sessionStorage.setItem('verifyFor', res.data.user_id);
                            this.setState({
                                redirect: true,
                            });
                        } else {
                            this.setState({
                                loading: false
                            });
                            this.props.showNotification(res.message, 'danger')
                        }
                    })
                });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.props.showNotification('Please enter all required data', 'danger')
            } else {
                this.props.showNotification(msg, 'danger')
            }
        }
    }

    componentDidMount() {
        ReactPixel.init(config.fb_pixel_id);
        ReactPixel.pageView();
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={{
                pathname: config.site_url.verification,
            }} />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <Row>
                <Col xs="12">
                    <Form id="signupform">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Full Name*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter first name"
                                name="first_name"
                                id="first_name"
                                value={this.state.first_name}
                                onChange={this.onInputChange}
                            />
                            <Input
                                type="text"
                                placeholder="Enter last name"
                                name="last_name"
                                id="last_name"
                                value={this.state.last_name}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Email*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter your email"
                                name="email"
                                id="email"
                                value={this.state.email}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup className="password-note-group">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Password*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="password"
                                placeholder="Enter your password"
                                name="password"
                                id="password"
                                value={this.state.password}
                                onChange={this.onInputChange}
                            />
                            <label className="password-note">
                                Use 5 or more characters with a mix of letters, numbers & symbols
                            </label>
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Mobile Number*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter your mobile number"
                                name="phone"
                                id="phone"
                                value={this.state.phone}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    DOB (optional)
                                </InputGroupText>
                            </InputGroupAddon>
                            <DOBField
                                name="dob"
                                value={this.state.dob}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Code (optional)
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter Professional or Enterprise promotion code"
                                name="account_code"
                                id="account_code"
                                value={this.state.account_code}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <div className="d-flex no-block align-items-cente auth-footer">
                            <div className="right_bx d-flex">
                            </div>
                            <RightButton text="Sign up" onClick={this.onSignup} loading={this.state.loading} />
                        </div>

                    </Form>
                </Col>
            </Row>
        </div>;
    }
}

export default SignupForm;
