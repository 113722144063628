import React from 'react';
import {
    Row,
    Col,
    Form,
    Card,
    CardTitle,
    CardBody,
} from 'reactstrap';
import config from 'config/config';
import ReactPixel from 'react-facebook-pixel';
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import {getNotificationObject} from "../../services/helper";
import RightButton from "../../components/properlytics/right-button";
import LeftButton from "../../components/properlytics/left-button";
import axios from "../../services/axios";

export default class Verification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            verified: false,
            redirect: false,
            user_id: '',
            focus: 1,

            loading_resend: false,
            loading_verify: false,
        };

        this.resend = this.resend.bind(this);
        this.verify = this.verify.bind(this);
        this.keyPress = this.keyPress.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.verificationCode1 = React.createRef();
        this.verificationCode2 = React.createRef();
        this.verificationCode3 = React.createRef();
        this.verificationCode4 = React.createRef();
        this.verificationCode5 = React.createRef();
        this.verificationCode6 = React.createRef();
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    keyPress(e){
        let focus = 1;
        let code = e.which || e.keyCode;
        if(code === 8 || code === 37) {
            if (this.state.focus === 3) {
                focus = 2;
            } else if (this.state.focus === 4) {
                focus = 3;
            } else if (this.state.focus === 5) {
                focus = 4;
            } else if (this.state.focus === 6) {
                focus = 5;
            }
        }  else if((code >= 48 && code <= 57) || (code >= 65 && code <= 90) || (code >= 96 && code <= 105) || code === 39) {
            if (this.state.focus === 1) {
                focus = 2;
            } else if (this.state.focus === 2) {
                focus = 3;
            } else if (this.state.focus === 3) {
                focus = 4;
            } else if (this.state.focus === 4) {
                focus = 5;
            } else if (this.state.focus === 5) {
                focus = 6;
            } else if (this.state.focus === 6) {
                focus = 6;
            }
        }

        this.setState({
            focus: focus
        });

        if (focus === 1) {
            this.verificationCode1.current.select();
        } else if (focus === 2) {
            this.verificationCode2.current.select();
        } else if (focus === 3) {
            this.verificationCode3.current.select();
        } else if (focus === 4) {
            this.verificationCode4.current.select();
        } else if (focus === 5) {
            this.verificationCode5.current.select();
        } else if (focus === 6) {
            this.verificationCode6.current.select();
        }
    }

    resend() {
        this.setState({
            loading_resend: true
        });
        let data = {
            user_id: this.state.user_id
        };
        axios.post(config.resend_otp, data).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success');
            } else {
                this.showNotification(res.message, 'danger');
            }

            this.setState({
                loading_resend: false
            });
        })
    }

    verify() {
        this.setState({
            loading_verify: true
        });
        let verificationCode = this.verificationCode1.current.value
            + this.verificationCode2.current.value
            + this.verificationCode3.current.value
            + this.verificationCode4.current.value
            + this.verificationCode5.current.value
            + this.verificationCode6.current.value;

        let data = {
            verification_code: verificationCode,
            user_id: this.state.user_id
        };
        axios.post(config.verify_otp, data).then(res => {
            if (res.success) {
                sessionStorage.removeItem('verifyFor');
                this.setState({
                    verified: true,
                });
            } else {
                this.setState({
                    loading_verify: false
                });
                this.showNotification(res.message, 'danger');
            }
        })
    }

    componentDidMount() {
        this.verificationCode1.current.focus();
        let user_id = sessionStorage.getItem('verifyFor') || '';
        if (user_id === '') {
            this.showNotification("Invalid Url", 'danger');

            let self = this;
            setTimeout(function () {
                self.setState({
                    redirect: true
                })
            }, 1000)
        }

        this.setState({
            user_id: user_id
        });

        ReactPixel.init(config.fb_pixel_id);
        ReactPixel.pageView();
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={{
                pathname: config.site_url.login,
            }} />;
        }

        if (this.state.verified) {
            return <Redirect to={{
                pathname: config.site_url.thank_you,
                title: 'Thank You',
                message: 'Your account has been created and activated',
            }} />;
        }

        return <div className="">

            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Verify Account
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">A verification code has been sent via text</p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="verification">
                                <Row>
                                    <Col xs="12">
                                        <Form id="verifyform" autoComplete="off">

                                            <h3 className="text-center">
                                                Verification Code
                                            </h3>

                                            <div className="description text-center">
                                                Please enter the 6 digit verification code we sent via code to verify your account and continue the registration
                                            </div>

                                            <div className="text-center digit-group">
                                                <input
                                                    ref={this.verificationCode1}
                                                    type="text"
                                                    id="verificationCode1"
                                                    name="verificationCode1"
                                                    maxLength="1"
                                                    onKeyPress={this.keyPress}
                                                />
                                                <input
                                                    ref={this.verificationCode2}
                                                    type="text"
                                                    id="verificationCode2"
                                                    name="verificationCode2"
                                                    maxLength="1"
                                                    onKeyPress={this.keyPress}
                                                />
                                                <input
                                                    ref={this.verificationCode3}
                                                    type="text"
                                                    id="verificationCode3"
                                                    name="verificationCode3"
                                                    maxLength="1"
                                                    onKeyPress={this.keyPress}
                                                />
                                                <input
                                                    ref={this.verificationCode4}
                                                    type="text"
                                                    id="verificationCode4"
                                                    name="verificationCode4"
                                                    maxLength="1"
                                                    onKeyPress={this.keyPress}
                                                />
                                                <input
                                                    ref={this.verificationCode5}
                                                    type="text"
                                                    id="verificationCode5"
                                                    name="verificationCode5"
                                                    maxLength="1"
                                                    onKeyPress={this.keyPress}
                                                />
                                                <input
                                                    ref={this.verificationCode6}
                                                    type="text"
                                                    id="verificationCode6"
                                                    name="verificationCode6"
                                                    maxLength="1"
                                                    onKeyPress={this.keyPress}
                                                />
                                            </div>

                                            <div className="d-flex no-block align-items-center auth-footer reset-bx">
                                                <LeftButton text="Resend" onClick={this.resend} loading={this.state.loading_resend} />

                                                <RightButton text="Verify Now" onClick={this.verify} loading={this.state.loading_verify} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

