import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
} from 'reactstrap';
import {
    getMyProfile, storeInLocalStorage, getNotificationObject, dataURLtoFile,
} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import validator from "./account-validators";
import {Redirect} from 'react-router';
import config from 'config/config';
import moment from 'moment';
import RightButton from "../../components/properlytics/right-button";
import LeftLink from "../../components/properlytics/left-link";
import MiddleLink from "../../components/properlytics/middle-link";
import DOBField from "../../components/numbers/DOBField";
import UploadAvtar from "../../components/properlytics/upload-avtar";
import axios from "../../services/axios";

class Account extends React.Component {
    constructor(props) {
        super(props);

        this.validators = validator;
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.updateState = this.updateState.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.state = {
            user_id: '',
            picture: '',
            img_display: false,
            picture_url: '',
            remove_picture: false,
            first_name: '',
            last_name: '',
            dob: '',
            phone: '',
            email: '',
            display_change_password: false,
            redirect: false,
            loading: false,
            page_loading: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    updateState(name, value) {
        this.setState({
            [name]: value,
        })
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (field === 'first_name' || field === 'last_name' ||
                field === 'phone') {
                if (!this.validators[field].valid) {
                    status = false;
                }
            }
        });
        return status;
    }

    showErrors() {
        let fields = ['first_name', 'last_name', 'phone'];

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onUpdate() {
        if (this.validForm()) {
            this.setState({
                loading: true
            });
            let postData = new FormData();
            if (this.state.picture) {
                postData.append('picture', dataURLtoFile(this.state.picture));
            }
            postData.append('remove_picture', (this.state.remove_picture) ? 1 : 0);
            postData.append('first_name', this.state.first_name);
            postData.append('last_name', this.state.last_name);
            postData.append('dob', this.state.dob);
            postData.append('phone', this.state.phone);

            axios.post(config.update_account, postData).then(res => {
                if (res.success) {
                    storeInLocalStorage(res.data);
                    this.showNotification(res.message, 'success')
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            })
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.showNotification('Please enter all required data', 'danger')
            } else {
                this.showNotification(msg, 'danger')
            }
        }
    }


    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {

            let img_display = false;
            if (profile.picture) {
                img_display = true;

                let postData = {
                    id: profile.user_id,
                    type: 1
                };

                this.setState({
                    page_loading: true
                });

                axios.post(config.get_base64_image, postData).then(res => {
                    if (res.success) {
                        this.setState({
                            picture_url: res.data.image
                        })
                    }

                    this.setState({
                        page_loading: false
                    });
                })
            } else {
                this.setState({
                    picture_url: ''
                })
            }

            this.setState({
                user_id: profile.user_id,
                email: profile.email,
                img_display: img_display,
                first_name: profile.first_name,
                last_name: profile.last_name,
                dob: (profile.dob) ? moment(profile.dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
                phone: profile.phone,
                display_change_password: (profile.source === 'MANUAL'),
            });

            this.formValidators('first_name', profile.first_name);
            this.formValidators('last_name', profile.last_name);
            this.formValidators('dob', profile.dob);
            this.formValidators('phone', profile.phone);
        } else {
            this.setState({
                redirect: true,
            })
        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Account
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Update your personal details</p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="userdetail">

                                            <UploadAvtar
                                                from="account"
                                                img_display={this.state.img_display}
                                                picture_url={this.state.picture_url}
                                                updateState={this.updateState}
                                            />

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Email*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        First Name*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    name="first_name"
                                                    id="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Surname*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    name="last_name"
                                                    id="last_name"
                                                    value={this.state.last_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        D.O.B
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <DOBField
                                                    name="dob"
                                                    value={this.state.dob}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Contact no.*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Your Contact Number"
                                                    name="phone"
                                                    id="phone"
                                                    value={this.state.phone}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <div className="d-flex no-block align-items-cente auth-footer">
                                                <LeftLink text="Cancel" url={config.site_url.dashboard} />

                                                <MiddleLink
                                                    url={config.site_url.change_password}
                                                    text="Change your password"
                                                    className={(this.state.display_change_password) ? 'center_bx' : 'hide'}
                                                />

                                                <RightButton text="Update" onClick={this.onUpdate} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Account;
