import React from 'react';
import {
    Row,
    Col,
    Table,
    Input,
    Button,
} from 'reactstrap';
import config from "config/config";

export default class Entitylist extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_text: '',
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.clear = this.clear.bind(this);
    }

    onTextChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });

        if (value.length > 2) {
            this.onSearch();
        }

        if (value.length === 0) {
            this.clear();
        }
    }

    onSearch() {
        this.props.fetchEntities(this.props.client_id, this.state.search_text);
    }

    clear() {
        this.setState({
            search_text: ''
        });
        this.props.fetchEntities(this.props.client_id, '');
    }

    render() {
        let {entities} = this.props;

        let entities_data = <tr className="strip text-center"><td colSpan={7}>Entity not found</td></tr>;

        if (entities.length > 0) {
            entities_data = entities.map((entity, key) => {
                return (
                    <tr className="strip" key={key}>
                        <td>
                            { entity.name }
                        </td>

                        <td>
                            { config.entity_type[entity.entity_type] }
                           
                        </td>

                        <td>
                            { entity.updated_at }
                        </td>

                        <td> <span className="edit" onClick={this.props.openCloseEntity.bind(this, true, entity.entity_id)}>
                                <i className="fa fa-pencil-alt" />
                            </span>
                            <span className="delete" onClick={this.props.deleteConfirm.bind(this, entity.entity_id)}>
                                <i className="fa fa-trash" />
                            </span></td>
                    </tr>
                );
            });
        }

        return <div className="">
            <div className="inner_bx">
                <div className="d-flex entity-search">
                    <div className="search_by"><span className="t_green mbtm0 fnormal pmedium">Existing Owners</span></div>
                    <div className="q_search">
                        <Input
                            type="text"
                            id="search_text"
                            name="search_text"
                            placeholder="SEARCH"
                            value={this.state.search_text}
                            onChange={this.onTextChange}
                        />
                        <Button type="submit" className="search-btn">
                            <i aria-hidden="true" className="fa fa-search" />
                        </Button>
                        
                        <Button onClick={this.clear} className={this.state.search_text.length > 0 ? "remove-btn" : "hide"}>
                            <i aria-hidden="true" className="fa fa-times" />
                        </Button>
                    </div>
                </div>

                <div id="entity-list">
                    <Row>
                        <Col md="12">
                            <div className="table-wrap">
                                <Table responsive className="table custmize">
                                    <thead>
                                        <tr>
                                            <th> Name</th>
                                            <th>Portfolio Type</th>
                                            <th>Date Modified</th>
                                            <th class="w-80"></th>
                                        </tr>
                                    </thead>
                                    <tbody className={(this.props.search_loading || this.props.list_loading) ? `` : `hide`}>
                                    <tr>
                                        <td colSpan={7}>
                                            <div className="table-loader show" />
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody className={(this.props.search_loading || this.props.list_loading) ? `hide` : ``}>
                                    {entities_data}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
       </div>
              
    }
}

