import React from 'react';
import {
    Button,
    Row,
    Col,
} from 'reactstrap';
import Entitylist from "../../components/portfolio/entities-list";
import AddEntity from "../../components/portfolio/add-entity";
import {getMyProfile, getNotificationObject, } from "../../services/helper";
import ReactNotification from "react-notifications-component";
import config from "config/config";
import {Redirect} from 'react-router';
import {getClientId} from "../../services/config-helper";
import axios from "../../services/axios";
import PortfolioList from "../../components/portfolio/portfolio-list";
import DuplicatePropertyList from "../../components/portfolio/duplicate-property";
import {confirmAlert} from 'react-confirm-alert';
import {downloadReport} from "../../services/helper";
import Chart from 'react-c3-component';
import 'c3/c3.css';

export default class Portfolio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list_loading: false,
            page_loading: true,
            search_loading: false,
            download_report_loading: false,
            loading: false,
            redirect_to_dashboard: false,
            open_entity: false,
            entities: [],
            entities_data: [],
            is_edit: false,
            client_id: '',
            entity_id: '',
            change_entity: false,
            property_id: '',

            property_list: [],
            open_duplicate_propery_popup: false,
            loading_property_list: false,

            chart_columns: [],
            chart_type: 0,
            chart_type_text: 'Property Value',
            chart_name: '',
            display_chart: false,
        };

        this.openCloseEntity = this.openCloseEntity.bind(this);
        this.fetchEntities = this.fetchEntities.bind(this);
        this.addUpdateEntity = this.addUpdateEntity.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.deleteEntity = this.deleteEntity.bind(this);
        this.duplicateProperty = this.duplicateProperty.bind(this);
        this.showHidePopup = this.showHidePopup.bind(this);
        this.setData = this.setData.bind(this);
        this.getPortfolioChart = this.getPortfolioChart.bind(this);
        this.changeCartType = this.changeCartType.bind(this);
        this.generateReport = this.generateReport.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    generateReport() {
        this.setState({
            download_report_loading: true
        })
        let postData = {
            client_id: this.state.client_id,
            type: 'stream'
        };

        axios.post(config.export_wealth_report, postData).then(res => {
            if (res.success) {
                let fileName = 'Porfolio.pdf';
                downloadReport(res.data, fileName);
            } else {
                this.props.showNotification(res.message, 'danger');
            }
            this.setState({
                download_report_loading: false
            })
        });
    }

    openCloseEntity(is_edit = false, entity_id = null) {
        if (this.state.open_entity) {
            this.setState({
                change_entity: false,
                property_id: '',
            })
        }
        this.setState({
            open_entity: !this.state.open_entity,
            is_edit: is_edit,
            entity_id: (entity_id !== null) ? entity_id : ''
        });
    }

    deleteConfirm = (entity_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this entity?',
            buttons: [
                {
                    label: 'Cancel',
                },
                {
                    label: 'Delete',
                    onClick: () => this.deleteEntity(entity_id)
                }
            ]
        }, entity_id);
    }

    deleteEntity(entity_id) {
        let data = {
            client_id: this.state.client_id,
        };

        this.setState({
            list_loading: true,
        });

        axios.post(config.delete_entity + entity_id, data).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success');
                this.setData('list_entity', res.data.entity_data);
                this.setState({
                    entities: res.data.entity_list,
                });
            } else {
                this.showNotification(res.message, 'danger');
            }
            this.setState({
                list_loading: false,
            });
        });
    }

    addUpdateEntity(data) {
        this.setState({
            loading: true
        });

        let url = config.add_entity;

        if (this.state.is_edit) {
            url = config.edit_entity + this.state.entity_id;
        }

        axios.post(url, data).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success');
                this.setData('list_entity', res.data.entity_data);
                this.setState({
                    entities: res.data.entity_list,
                    loading: false,
                    open_entity: false,
                    is_edit: false,
                    entity_id: ''
                })
            } else {
                this.showNotification(res.message, 'danger');
                this.setState({
                    loading: false
                })
            }
        })
    }

    setData(type, res) {
        let entities_data = this.state.entities_data;
        if (type === 'change_entity_open') {
            this.setState({
                change_entity: res.change_entity,
                open_entity: res.change_entity,
                property_id: res.property_id
            });
            return;
        } else if (type === 'change_entity') {
            this.setState({
                loading: true
            });
            let data = {
                property_id: this.state.property_id,
                entity_id: res
            };

            let property_id = this.state.property_id;
            let new_entity_id = res;
            let old_entity_id = '';
            let property = {};

            axios.post(config.property_change_entity, data).then(res => {
                if (res.success) {
                    //remove property from the old entity
                    for (let i=0;i<entities_data.length;i++) {
                        for (let j = 0; j < entities_data[i].entities.length; j++) {
                            let properties = entities_data[i].entities[j].properties;
                            for (let k=0;k<properties.existing.length;k++) {
                                if (parseInt(properties.existing[k].property_id) === parseInt(property_id)) {
                                    old_entity_id = properties.existing[k].entity_id;
                                    property = properties.existing[k];
                                    properties.existing.splice(k,1);
                                    break;
                                }
                            }
                            for (let k=0;k<properties.future.length;k++) {
                                if (parseInt(properties.future[k].property_id) === parseInt(property_id)) {
                                    old_entity_id = properties.future[k].entity_id;
                                    property = properties.future[k];
                                    properties.future.splice(k,1);
                                    break;
                                }
                            }
                            entities_data[i].entities[j].properties = properties;

                            if (old_entity_id !== '') {
                                break;
                            }
                        }

                        if (old_entity_id !== '') {
                            break;
                        }
                    }

                    //add property in the new entity
                    if (old_entity_id !== '') {
                        for (let i=0;i<entities_data.length;i++) {
                            for (let j = 0; j < entities_data[i].entities.length; j++) {
                                if (parseInt(entities_data[i].entities[j].entity_id) === parseInt(new_entity_id)) {
                                    let properties = entities_data[i].entities[j].properties;
                                    if (parseInt(property.type) === 1) {
                                        properties.existing.push(property);
                                        old_entity_id = '';
                                    } else if (parseInt(property.type) === 2) {
                                        properties.future.push(property);
                                        old_entity_id = '';
                                    }
                                    entities_data[i].entities[j].properties = properties;

                                    if (old_entity_id === '') {
                                        break;
                                    }
                                }
                            }

                            if (old_entity_id === '') {
                                break;
                            }
                        }
                    }


                    this.setState({
                        loading: false,
                        change_entity: false,
                        open_entity: false
                    });
                } else {
                    this.showNotification(res.message, 'danger');
                    this.setState({
                        loading: false
                    });
                }
            });
            return;
        } else if (type === 'list_entity') {
            entities_data = res;

            for (let i=0;i<entities_data.length;i++) {
                for (let j=0;j<entities_data[i].entities.length;j++) {
                    entities_data[i].entities[j].properties = {
                        existing: [],
                        future: [],
                    };
                    entities_data[i].entities[j].open_property_list = false;
                    entities_data[i].entities[j].properties_loading = false;
                }
            }
        } else {
            for (let i=0;i<entities_data.length;i++) {
                for (let j=0;j<entities_data[i].entities.length;j++) {
                    if (parseInt(entities_data[i].entities[j].entity_id) === parseInt(res.entity_id)) {
                        if (type === 'properties_loading') {
                            entities_data[i].entities[j].properties_loading = res.loading;
                        } else if (type === 'open_property_list') {
                            entities_data[i].entities[j].open_property_list = !entities_data[i].entities[j].open_property_list;
                        } else if (type === 'get_properties') {
                            let properties = res.properties;
                            for (let k=0;k<properties.existing.length;k++) {
                                properties.existing[k].open_property_detail = false;
                                properties.existing[k].property_loading = false;
                                properties.existing[k].property_detail = {};
                            }
                            for (let k=0;k<properties.future.length;k++) {
                                properties.future[k].open_property_detail = false;
                                properties.future[k].property_loading = false;
                                properties.future[k].property_detail = {};
                            }
                            entities_data[i].entities[j].properties = properties;
                        } else if (type === 'duplicate_property') {
                            let properties = entities_data[i].entities[j].properties;
                            let property = res.property;
                            property.open_property_detail = false;
                            property.property_loading = false;
                            property.property_detail = {};
                            if (parseInt(property.type) === 1) {
                                properties.existing.push(property);
                            } else if (parseInt(property.type) === 2) {
                                properties.future.push(property);
                            }
                            entities_data[i].entities[j].properties = properties;
                        } else if (type === 'delete_property' || type === 'open_property_detail' ||
                            type === 'property_loading' || type === 'get_property_detail'
                        ) {
                            let properties = entities_data[i].entities[j].properties;
                            for (let k=0;k<properties.existing.length;k++) {
                                if (parseInt(properties.existing[k].property_id) === parseInt(res.property_id)) {
                                    if (type === 'delete_property') {
                                        properties.existing.splice(k,1);
                                    } else if (type === 'open_property_detail') {
                                        properties.existing[k].open_property_detail = !properties.existing[k].open_property_detail;
                                    } else if (type === 'property_loading') {
                                        properties.existing[k].property_loading = res.property_loading;
                                    } else if (type === 'get_property_detail') {
                                        properties.existing[k].property_detail = res.property_detail;
                                    }
                                    break;
                                }
                            }
                            for (let k=0;k<properties.future.length;k++) {
                                if (parseInt(properties.future[k].property_id) === parseInt(res.property_id)) {
                                    if (type === 'delete_property') {
                                        properties.future.splice(k,1);
                                    } else if (type === 'open_property_detail') {
                                        properties.future[k].open_property_detail = !properties.future[k].open_property_detail;
                                    } else if (type === 'property_loading') {
                                        properties.future[k].property_loading = res.property_loading;
                                    } else if (type === 'get_property_detail') {
                                        properties.future[k].property_detail = res.property_detail;
                                    }
                                    break;
                                }
                            }
                            entities_data[i].entities[j].properties = properties;
                        }
                        break;
                    }
                }
            }
        }

        this.setState({
            entities_data: entities_data,
        });
    }

    fetchEntities(client_id, search = '') {
        let data = {
            client_id: client_id,
        };
        if (search !== '') {
            data.search = search;
        }
        this.setState({
            search_loading: true
        });
        axios.post(config.entities, data).then(res => {
            if (res.success) {
                this.setData('list_entity', res.data.entity_data);
                this.setState({
                    entities: res.data.entity_list,
                    page_loading: false,
                    search_loading: false
                });
            } else {
                this.showNotification(res.message, 'danger');
                this.setState({
                    page_loading: false,
                    search_loading: false
                });
            }
        });
    }

    duplicateProperty(property_id) {
        this.setState({
            loading_property_list: true
        });
        axios.post(config.duplicate_properties + property_id).then(res => {
            if (res.success) {
                this.setData('duplicate_property', {
                    entity_id: res.data.property.entity_id,
                    property: res.data.property,
                });
                this.showNotification(res.message, 'success');
                this.setState({
                    open_duplicate_propery_popup: false,
                    entity_id: '',
                    property_list: [],
                });
            } else {
                this.showNotification(res.message, 'danger');
            }
            this.setState({
                loading_property_list: false,
            });
        });
    }

    getPortfolioChart(entity_id, event) {
        if (typeof event !== 'undefined' && !event.target.classList.contains('api-call')) {
            return;
        }
        this.setState({
            loading_portfolio_chart: true,
            chart_columns: [],
            chart_name: '',
        });
        axios.post(config.get_portfolio_data + entity_id).then(res => {
            if (res.success) {
                this.setState({
                    chart_columns: (typeof res.data.chart !== 'undefined') ? res.data.chart : '',
                    chart_name: (typeof res.data.name !== 'undefined') ? res.data.name : '',
                    loading_portfolio_chart: false,
                    display_chart: true,
                });
            } else {
                this.showNotification(res.message, 'danger');
            }
            this.setState({
                loading_portfolio_chart: false,
            });
        });
    }

    changeCartType(type) {
        let chart_type_text = 'Property Value';
        if (type === 1) {
            chart_type_text = 'Current Equity';
        } else if (type === 2) {
            chart_type_text = 'Loan Amount';
        } else if (type === 3) {
            chart_type_text = 'LVR';
        } else if (type === 4) {
            chart_type_text = 'Pre-Tax Cashflow';
        } else if (type === 5) {
            chart_type_text = 'Post-Tax Cashflow';
        }

        this.setState({
            chart_type: type,
            chart_type_text: chart_type_text
        })
    }

    showHidePopup(entity_id = null) {
        if (document.querySelector('.duplicate-property') !== null) {
            document.querySelectorAll('.duplicate-property').forEach((item) => {
                item.classList.remove('show')
            })
        }
        this.setState({
            open_duplicate_propery_popup: !this.state.open_duplicate_propery_popup,
            entity_id: (entity_id === null) ? '' : entity_id,
            property_list: [],
        });

        if (!isNaN(entity_id)) {
            this.setState({
                loading_property_list: true
            });

            axios.get(config.get_properties + entity_id).then(res => {
                if (res.success) {
                    this.setState({
                        property_list: res.data.property_list,
                    });
                } else {
                    this.showNotification(res.message, 'danger');
                }
                this.setState({
                    loading_property_list: false,
                });
            });
        }
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            let client_id = getClientId() || '';
            if (client_id !== '') {
                this.setState({
                    client_id: client_id
                });
                this.fetchEntities(client_id);
                return
            }
        }

        this.setState({
            redirect_to_dashboard: true,
        });
    }

    render() {
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        const {chart_type, chart_type_text} = this.state;

        return <div className="pad-30">

            <ReactNotification ref={this.notificationDOMRef} />

            <div className={this.state.page_loading ? `auth-wrapper no-block justify-content-center align-items-center` : `hide`}>
                <div className="page-loader show" />
            </div>

            <Row className={(this.state.page_loading) ? 'hide' : ''}>
                <Col xl="5" className="mb-30">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*PROPERTY DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="blocks ui-sortable h100">
                        <div className="block h100">
                            <div className="entities rect_bx h100">
                                <div className="head_tron d-flex justify-content-between">
                                    <h2 className="head fsize22 fx_w t_green">Owners</h2>

                                    <h4 className="sm_tit create-btn mbtm0 fsize14 d-flex" onClick={this.openCloseEntity.bind(this, false)}>
                                        {/* Owner -> Previously know as Entity */}
                                        Create New Owner
                                    </h4>    
                                </div>

                                <Entitylist
                                    entities={this.state.entities}
                                    search_loading={this.state.search_loading}
                                    list_loading={this.state.list_loading}
                                    client_id={this.state.client_id}
                                    openCloseEntity={this.openCloseEntity}
                                    fetchEntities={this.fetchEntities}
                                    deleteConfirm={this.deleteConfirm}
                                />
                            </div>
                            <AddEntity
                                loading={this.state.loading}
                                is_edit={this.state.is_edit}
                                client_id={this.state.client_id}
                                entity_id={this.state.entity_id}
                                open_entity={this.state.open_entity}
                                entities={this.state.entities}
                                change_entity={this.state.change_entity}
                                openCloseEntity={this.openCloseEntity}
                                showNotification={this.showNotification}
                                addUpdateEntity={this.addUpdateEntity}
                                setData={this.setData}
                            />
                        </div>
                    </div>

                </Col>

                <Col xl="7" className={(this.state.display_chart) ? '' : 'hide'}>
                    <div className="entities rect_bx">
                        <div className="head_tron d-flex justify-content-between">
                            <h2 className="head fsize22  t_green">{this.state.chart_name}</h2>
                        </div>

                        <div className="chart-lables d-flex">
                            <span onClick={this.changeCartType.bind(this, 0)} className={chart_type === 0 ? 'active' : ''}>Property Value</span>
                            <span onClick={this.changeCartType.bind(this, 1)} className={chart_type === 1 ? 'active' : ''}>Current Equity</span>
                            <span onClick={this.changeCartType.bind(this, 2)} className={chart_type === 2 ? 'active' : ''}>Loan Amount</span>
                            <span onClick={this.changeCartType.bind(this, 3)} className={chart_type === 3 ? 'active' : ''}>LVR</span>
                            <span onClick={this.changeCartType.bind(this, 4)} className={chart_type === 4 ? 'active' : ''}>Pre-Tax Cashflow</span>
                            <span onClick={this.changeCartType.bind(this, 5)} className={chart_type === 5 ? 'active' : ''}>Post-Tax Cashflow</span>
                        </div>

                        <Chart
                            config={{
                                // Add your C3 config excluding bindto here
                                // http://c3js.org/reference.html
                                data: {
                                    columns: (typeof this.state.chart_columns[this.state.chart_type] !== 'undefined') ?
                                        this.state.chart_columns[this.state.chart_type] :
                                        []
                                },
                                axis: {
                                   x:{
                                    label:{
                                        text: 'Years',
                                        position: 'outer-center',
                                    },
                                   },

                                    y: {
                                      label: {
                                        text: chart_type_text,
                                        position: 'outer-middle'
                                      },
                                      tick: {
                                        format: function (d) { return (chart_type === 3) ? (d.toFixed(2) + "%") : ("$" + d); }
                                      }
                                    },
                                },
                                color: { pattern: ["#00c880", "#00c880"] },
                                legend: {
                                    show: false
                                }
                            }}
                            />
                   </div>
                </Col>

                <Col md="12">
                    <div className="entities portfolios rect_bx">
                        <div className="head_tron d-flex justify-content-between">
                            <h2 className="head fsize22 fx_w t_green">Portfolio</h2>

                            <Button
                                style={{ marginRight: '10px'}}
                                className="block-btn bg_green"
                                onClick={this.generateReport.bind(this)}
                            >
                                Wealth Report
                                <div className={(this.state.download_report_loading) ? 'btn-loading right' : 'hide'}>
                                    <div className="btn-loader" />
                                </div>
                            </Button>
                        </div>

                        <PortfolioMap
                            portfolio={this.state.entities_data}
                            list_loading={this.state.list_loading}
                            showNotification={this.showNotification}
                            showHidePopup={this.showHidePopup}
                            setData={this.setData}
                            getPortfolioChart={this.getPortfolioChart}
                        />
                    </div>
                </Col>
            </Row>

            <DuplicatePropertyList
                property_list={this.state.property_list}
                display={this.state.open_duplicate_propery_popup}
                loading={this.state.loading_property_list}
                duplicateProperty={this.duplicateProperty}
                showHidePopup={this.showHidePopup}
            />
        </div>

    }
}


const PortfolioMap = ({portfolio, list_loading, showNotification, showHidePopup, setData, getPortfolioChart}) => {
    if (!portfolio || portfolio.length === 0) {
        return ''
    }

    if (list_loading) {
        return (
            <div className='port-row'>
                <div className="table-loader show" />
            </div>
        )
    }

    return portfolio.map((pf, key) => {
        return (
            <PortfolioList
                key={key}
                id={'portfolio_' + pf.key}
                portfolio={pf}
                showNotification={showNotification}
                showHidePopup={showHidePopup}
                setData={setData}
                getPortfolioChart={getPortfolioChart}
            />
        )
    });
}
