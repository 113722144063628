import React from 'react';
import {
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import config from 'config/config';
import {
    getClientId,
    getEntityId,
    getEntityName,
    getTypeOfProperty,
    getClientName
} from "services/config-helper";
import {
    isInPropertyTab,
    isInDashboard
} from "services/helper";

export default class TopBarLinks extends React.Component {
	render() {
        return (
            <Nav className="float-left bread-crumb" navbar>
                <NavItem>
                    <NavLink href={config.hash + config.site_url.dashboard} className="">
                        Dashboard
                    </NavLink>
                </NavItem>
                <NavItem className={(getClientId() === '' || isInDashboard()) ? 'hide' : ''}>
                    <NavLink href={config.hash + config.site_url.portfolio} className="">
                        { getClientName() }
                    </NavLink>
                </NavItem>
                <NavItem className={(getEntityId() !== '' && isInPropertyTab()) ? '' : 'hide'}>
                    <NavLink href={config.hash + config.site_url.portfolio} className="">
                        { getEntityName() }
                    </NavLink>
                </NavItem>
                <NavItem className={(getTypeOfProperty() !== '' && isInPropertyTab()) ? '' : 'hide'}>
                    <a href="javascript:void(0)" className="nav-link">{getTypeOfProperty() === 1 ? 'Existing Property' : 'Future Property'}</a>
                </NavItem>
            </Nav>
        );
	}
}