import Login from '../views/authentication/login.jsx';
import config from 'config/config';
import SetupProfile from "../views/authentication/setup-profile";
import ResetPassword from "../views/authentication/reset-password";
import ThankYou from "../views/authentication/thank-you";
import Verification from "../views/authentication/verification";
import ForgotPassword from "../views/authentication/forgot-password";

var authRoutes = [
    { path: config.site_url.login, name: 'Login', icon: 'mdi mdi-account-key', component: Login },
    { path: config.site_url.register, name: 'Register', icon: 'mdi mdi-account-key', component: Login },
    { path: config.site_url.businessTrial, name: 'BusinessTrial', icon: 'mdi mdi-account-key', component: Login },
    { path: config.site_url.personalTrial, name: 'PersonalTrial', icon: 'mdi mdi-account-key', component: Login },
    { path: config.site_url.setup_profile, name: 'SetupProfile', icon: 'mdi mdi-account-key', component: SetupProfile },
    { path: config.site_url.forgot_password, name: 'ForgotPassword', icon: 'mdi mdi-account-key', component: ForgotPassword },
    { path: config.site_url.reset_password + '/:token', name: 'ResetPassword', icon: 'mdi mdi-account-key', component: ResetPassword },
    { path: config.site_url.thank_you, name: 'ThankYou', icon: 'mdi mdi-account-key', component: ThankYou },
    { path: config.site_url.verification, name: 'Verification', icon: 'mdi mdi-account-key', component: Verification },
];
export default authRoutes;
