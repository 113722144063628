import React from 'react';
import EntityMap from "./entity-map";

export default class PortfolioList extends React.Component { 
    render() {
        let {portfolio, showNotification, showHidePopup, setData, getPortfolioChart} = this.props;

        return <div className='port-row'>
            <h4 className="portfolio-type t_darkgreen">{portfolio.name}</h4>
            <EntityMap
                entities={portfolio.entities}
                showNotification={showNotification}
                showHidePopup={showHidePopup}
                setData={setData}
                getPortfolioChart={getPortfolioChart}
            />
        </div>
    }
}