import React from 'react';
import {
    Button,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import Propertydetail from "../portfolio/property-details";
import axios from "../../services/axios";
import config from "config/config";
import {downloadReport} from "../../services/helper";
import {Redirect} from 'react-router';
import {setEntityId, setTypeOfProperty, setEntityType, setEntityName} from "../../services/config-helper";

export default class PropertyMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            property_id: '',
        };

        this.openClosePropertyDetail = this.openClosePropertyDetail.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.openChangeEntity = this.openChangeEntity.bind(this);
        this.editProperty = this.editProperty.bind(this);
    }

    generateReport(property_id) {
        let postData = {
            property_id: property_id,
            type: 'stream'
        };

        axios.post(config.export_report, postData).then(res => {
            if (res.success) {
                let fileName = 'Properlytics-Report-' + res.data.address + '.pdf';
                downloadReport(res.data, fileName);
            } else {
                this.props.showNotification(res.message, 'danger');
            }
            this.setState({
                loading: false
            })
        });
    }

    openChangeEntity(property_id) {
        if (document.querySelector('.property-options') !== null) {
            document.querySelectorAll('.property-options').forEach((item) => {
                item.classList.remove('show')
            })
        }
        this.props.setData('change_entity_open', {property_id: property_id, change_entity: true});
    }

    openClosePropertyDetail(property) {
        if (!property.open_property_detail && !isNaN(property.property_id)) {
            this.props.setData('property_loading', {
                entity_id: property.entity_id,
                property_id: property.property_id,
                property_loading: true
            });
            axios.get(config.get_property + property.property_id).then(res => {
                if (res.success) {
                    this.props.setData('get_property_detail', {
                        entity_id: property.entity_id,
                        property_id: property.property_id,
                        property_detail: res.data.property
                    });
                } else {
                    this.props.showNotification(res.message, 'danger');
                }
                this.props.setData('property_loading', {
                    entity_id: property.entity_id,
                    property_id: property.property_id,
                    property_loading: false
                });
            });
        }

        this.props.setData('open_property_detail', {
            entity_id: property.entity_id,
            property_id: property.property_id,
        });
    }

    editProperty(property) {
        setEntityId(property.entity_id);
        setEntityType(property.entity_type);
        setEntityName(property.entity_name);
        setTypeOfProperty(property.type);
        this.setState({
            redirect: true,
            property_id: property.property_id
        })
    }

    render() {
        let {properties, deleteConfirm} = this.props;

        if (this.state.redirect) {
            return <Redirect
                to={{pathname: config.site_url.property_detail + '/' + this.state.property_id}}
            />;
        }

        return properties.map((property, key) => {
            return (
                <div className="inner-row" key={key}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div  className="left-aside">
                            <div className="d-flex  align-items-center justify-content-between">
                                <img src={property.image} className="prpt_img" width="100" height="100" alt="Property_Image" />

                                <div className="top_mdl">
                                    <div className="d-flex">
                                        <p className="prop_add mbtm0">{property.address}</p>

                                        <UncontrolledDropdown  inNavbar className="read-more">
                                            <DropdownToggle nav className="rpad0">
                                                <i className="fa fa-pencil-alt" />
                                            </DropdownToggle>

                                            <DropdownMenu right className="pad0 user-dd property-options">
                                                <Nav>
                                                    <NavItem>
                                                        <a onClick={this.editProperty.bind(this,property)} href="javascript:void(0)" className="nav-link">Edit</a>
                                                    </NavItem>

                                                    <NavItem>
                                                        <a onClick={deleteConfirm.bind(this,property)} href="javascript:void(0)" className="nav-link">Delete</a>
                                                    </NavItem>

                                                    <NavItem>
                                                        <a onClick={this.openChangeEntity.bind(this, property.property_id)} href="javascript:void(0)" className="nav-link">
                                                            Change Owner
                                                        </a>
                                                    </NavItem>
                                                </Nav>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                    </div>

                                    <ul className="list-inline d-flex mbtm0 pro_faci">
                                        <li>
                                            Bed  <span className="no">{property.bedrooms}</span>
                                        </li>
                                        <li>
                                            Bath  <span className="no">{property.bathrooms}</span>
                                        </li>
                                        <li>
                                            Car  <span className="no">{property.car_space}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right-aside  d-flex  align-items-center justify-content-between">
                            <Button
                                className="block-btn bg_green"
                                onClick={this.generateReport.bind(this, property.property_id)}
                            >
                                Generate Report
                            </Button>


                            <span className="m-info d-flex  align-items-center" onClick={this.openClosePropertyDetail.bind(this, property)}>
                                More Info <i className={(property.open_property_detail) ? 'icon-arrow-up icon' : 'icon-arrow-down icon'} />
                            </span>
                        </div>
                    </div>

                    <Propertydetail
                        loading={property.property_loading}
                        display={property.open_property_detail}
                        property={property.property_detail}
                    />
                </div>
            )
        });
    }
}
