import React from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    Form,
    Input,
    Button,
    UncontrolledTooltip,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import PropertyMap from "../../../components/properlytics/map";
import config from "config/config";
import {dataURLtoFile, formatMoney} from "../../../services/helper";
import {confirmAlert} from "react-confirm-alert";
import FileDrop from 'react-file-drop';
import AvatarEditor from 'react-avatar-editor';
import axios from "../../../services/axios";

import default_img from "assets/images/default-property.png";
import default_green_img from "assets/images/default-property_new.png";
import greenIcon from 'assets/images/upload-green.png';
import greyIcon from 'assets/images/upload-grey.png';

const backgroundImage = {backgroundImage: "url(" + default_green_img  + ")"}
const previewStyle = {
    width: '200px'
}

export default class PropertySnapshot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            show_map: true,
            show_upload_img: false,
            lat: props.lat,
            lng: props.lng,
            loading: false,

            images: props.images,

            image: '',
            preview: '',
            model: false,
            change: false
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.hideShowMap = this.hideShowMap.bind(this);
        this.hideShowUploadSection = this.hideShowUploadSection.bind(this);
        this.openFileUpload = this.openFileUpload.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onDeleteImage = this.onDeleteImage.bind(this);

        this.toggle = this.toggle.bind(this);
        this.preview = this.preview.bind(this);
    }

    toggle() {
        if (this.state.model) {
            // upload image call
            if (this.editor) {
                // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
                // drawn on another canvas, or added to the DOM.
                const canvas = this.editor.getImage().toDataURL()

                this.imageUpload(canvas);
                this.setState({
                    image: '',
                    preview: '',
                })
            }
        }

        this.setState({
            model: !this.state.model
        });
    }

    preview() {
        if (this.editor) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            const canvas = this.editor.getImage().toDataURL()

            this.setState({
                preview: canvas,
            })
        }
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) {
            return;
        }
        const nextIndex = this.state.activeIndex === this.state.images.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        if (this.animating) {
            return;
        }
        const nextIndex = this.state.activeIndex === 0 ? this.state.images.length - 1 : this.state.activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(newIndex) {
        if (this.animating) {
            return;
        }
        this.setState({activeIndex: newIndex});
    }

    hideShowMap() {
        this.setState({show_map: (!this.state.show_map)});
    }

    hideShowUploadSection() {
        if (this.props.property_id) {
            this.setState({show_upload_img: (!this.state.show_upload_img)});
        } else {
            this.props.showNotification('Please add property detail first, then you can upload images.', 'danger')
        }
    }

    openFileUpload() {
        document.getElementById('upload-image-control').click();
    }

    handleDrop = (files, event) => {
        if (files.length > 1) {
            this.props.showNotification('You can only upload 1 image at a time.', 'danger')
            this.setState({
                image: '',
                preview: '',
            })
        } else {
           this.setState({
               model: true,
               image: files[0]
           })
        }
    }

    onFileChange(event) {
        this.setState({
            [event.target.name]: event.target.files[0],
        });

        this.setState({
            model: true,
            image: event.target.files[0]
        })
    }

    imageUpload(file) {
        this.setState({
            loading: true
        })
        let postData = new FormData();
        postData.append('property_id', this.props.property_id);
        postData.append('image', dataURLtoFile(file));

        axios.post(config.upload_image, postData).then(res => {
            if (res.success) {
                this.setState({
                    images: res.data.images
                })
                this.props.showNotification(res.message, 'success')
            } else {
                this.props.showNotification(res.message, 'danger')
            }
            this.setState({
                loading: false
            })
        });
    }

    deleteConfirm = (image_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this image?',
            buttons: [
                {
                    label: 'Cancel',
                },
                {
                    label: 'Delete',
                    onClick: () => this.onDeleteImage(image_id)
                }
            ]
        }, image_id);
    }

    onDeleteImage(image_id) {
        axios.delete(config.delete_image + image_id).then(res => {
            if (res.success) {
                this.setState({
                    images: res.data.images
                })
                this.props.showNotification(res.message, 'success')
            } else {
                this.props.showNotification(res.message, 'danger')
            }
        });
    }

    componentDidUpdate(props) {
        const { lat, lng, images } = this.props;
        if (props.lat !== lat) {
            this.setState({
                lat: this.props.lat
            });
        }

        if (props.lng !== lng) {
            this.setState({
                lng: this.props.lng
            });
        }

        if (props.images !== images) {
            this.setState({
                images: this.props.images
            });
        }
    }

    setEditorRef = (editor) => this.editor = editor

    render() {

        const activeIndex = this.state.activeIndex;

        let slides = '';

        if (this.state.images && this.state.images.length) {
            slides = this.state.images.map(item => (
                <CarouselItem
                    className="custom-tag"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.image}
                >
                    <img className="w-100" src={item.image} alt={item.image_id}/>
                </CarouselItem>
            ));
        }


        return <div className="prpty_snapshot prpty_flipper blocks  ui-sortable">
            {/*--------------------------------------------------------------------------------*/}
            {/*PROPERTY SNAPSHOT*/}
            {/*--------------------------------------------------------------------------------*/}

            <div className="block">
                <div className="plisting_form rect_bx flip_bx">
                    <div className="head_tron d-flex">
                        <h2 className="head t_green">Property Snapshot</h2>

                        <div className="collapse_grid">
                            <span>
                                <i
                                    className={(this.state.show_map ? 'icon-camera' : 'icon-camera active')}
                                    onClick={this.hideShowMap}
                                    aria-hidden="true"
                                    id="add-image-icon"
                                />
                            </span>
                            <span>
                                <i
                                    className={(this.state.show_map ? 'icon-map active' : 'icon-map')}
                                    onClick={this.hideShowMap}
                                    aria-hidden="true"
                                />
                            </span>

                            <UncontrolledTooltip
                                placement="bottom"
                                target="add-image-icon"
                            >
                                Add an image
                            </UncontrolledTooltip>
                        </div>
                    </div>

                    <div className={(this.state.show_map ? 'hide' : 'snapshot')}>

                        {(this.state.images && this.state.images.length)
                            ?
                            <div className={(this.state.show_upload_img) ? 'hide' : ''}>
                                <Carousel
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    autoPlay={false}
                                    interval={false}
                                    previous={this.previous}
                                >
                                    {slides}

                                    <CarouselControl
                                        direction="prev"
                                        className={(this.state.images.length === 1) ? 'hide' : ''}
                                        directionText="Previous"
                                        onClickHandler={this.previous}/>
                                    <CarouselControl
                                        direction="next"
                                        className={(this.state.images.length === 1) ? 'hide' : ''}
                                        directionText="Next"
                                        onClickHandler={this.next}/>
                                </Carousel>
                                <div className="upload-imgs" onClick={this.hideShowUploadSection}>
                                    <i className="icon-picture" aria-hidden="true"/>
                                </div>
                            </div>
                            :
                            <div className={(this.state.show_upload_img) ? 'hide' : 'carousel slide default-img-bg'}>
                                <div role="listbox" className="carousel-inner">
                                    <div className="custom-tag carousel-item active default" style={backgroundImage}
                                         onClick={this.hideShowUploadSection}>
                                        <img className="default-img" src={default_img} alt="Property"/>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={(this.state.show_upload_img) ? 'upload-section' : 'hide'}>

                            {(this.state.images && this.state.images.length)
                                ?
                                <div className="uploaded-image">
                                    <img src={this.state.images[0].image} alt={this.state.images[0].image_id}/>
                                    <div className="remove-image"
                                         onClick={this.deleteConfirm.bind(this, this.state.images[0].image_id)}>
                                        <i className="mdi mdi-delete"/>
                                    </div>
                                </div>
                                :
                                <div className="upload" onClick={this.openFileUpload}>
                                    <FileDrop onDrop={this.handleDrop}>
                                        <img src={greenIcon} alt='Upload'
                                             className={(this.state.loading) ? 'hide' : ''}/>
                                        <div className={(this.state.loading) ? 'uploading-image-main-loader' : 'hide'}/>
                                        <span className="primary-text">Click here</span> or drag and drop the <br/>
                                        image(s) you would like uploaded.
                                    </FileDrop>
                                </div>
                            }

                            <Button className="done-btn" onClick={this.hideShowUploadSection}>Done</Button>

                            <div className="preview">
                                <PreviewMap
                                    images={this.state.images}
                                    deleteConfirm={this.deleteConfirm}
                                />

                                {(this.state.images && this.state.images.length)
                                    ?
                                    <div className="preview-block" onClick={this.openFileUpload}>
                                        <FileDrop onDrop={this.handleDrop}>
                                            <img src={greyIcon} alt='Upload'
                                                 className={(this.state.loading) ? 'hide' : ''}/>
                                            <div className={(this.state.loading) ? 'uploading-image-loader' : 'hide'}/>
                                        </FileDrop>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                            <input className="hide" type="file" id="upload-image-control" name="file"
                                   onChange={this.onFileChange}/>
                        </div>


                        <Modal
                            isOpen={this.state.model}
                            toggle={this.toggle}
                            size="lg"
                            backdrop={true}
                        >
                            <ModalHeader toggle={this.toggle}>Upload Image</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col sm="6">
                                        <AvatarEditor
                                            width={400}
                                            height={200}
                                            image={this.state.image}
                                            ref={this.setEditorRef}
                                        />
                                    </Col>
                                    <Col sm="6" className="text-center">
                                        <img src={this.state.preview} style={previewStyle} className={(this.state.preview === '') ? 'hide' : ''} alt="Preview" />
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="btn" onClick={this.toggle}>
                                    Crop
                                </Button>
                                <Button color="btn" onClick={this.preview}>
                                    Preview
                                </Button>
                            </ModalFooter>
                        </Modal>

                    </div>

                    <div className={(this.state.show_map ? 'map' : 'hide')}>
                        <PropertyMap
                            googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + config.map_key}
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `100%`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                            lat={this.state.lat}
                            lng={this.state.lng}
                        />
                    </div>

                    <div className="inner_bx">
                        <Form id="prp_detail">
                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">Property Address</h4>
                                    <Input type="text" className="form-control"
                                           value={this.props.address}
                                           placeholder="Placeholder text"/>
                                </div>
                            </div>


                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">Price</h4>
                                    <Input type="text" className="form-control" placeholder=""
                                           value={'$' + formatMoney(this.props.price, 0)}/>
                                </div>
                            </div>

                            <div className="tp_row pad0 amenities  d-flex">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit ">Bedrooms</h4>

                                    <Input type="text" className="form-control" value={this.props.bed}/>
                                </div>

                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit ">Bathrooms</h4>
                                    <Input type="text" className="form-control" value={this.props.bath}/>
                                </div>

                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit ">Cars</h4>
                                    <Input type="text" className="form-control" value={this.props.car}/>
                                </div>
                            </div>

                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">Advertised Link</h4>
                                    <Input type="text" className="form-control"
                                           value={this.props.adv_link}
                                    />
                                </div>
                            </div>

                        </Form>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const PreviewMap = ({images, deleteConfirm}) => {

    if (!images || images.length < 2) {
        return null;
    }

    let new_images = [];
    for(let i=1;i<images.length;i++) {
        new_images.push(images[i]);
    }

    return new_images.map(item => {
        return (
            <div className="preview-block">
                <img className="preview-uploaded" src={item.image} alt={item.image_id} />
                <div className="remove-image" onClick={deleteConfirm.bind(this, item.image_id)}>
                    <i className="mdi mdi-delete"/>
                </div>
            </div>
        );
    })
}