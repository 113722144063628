import React from 'react';
import {
    Row,
    Col,
    CardTitle,
    Form,
    Input,
    Button,
} from 'reactstrap';
import {
    getMyProfile, getNotificationObject, setPurchaseSubscriptionState,
    storeInLocalStorage
} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import {Redirect} from 'react-router';
import config from 'config/config';
import moment from 'moment';
import Select from "../../components/properlytics/select";
import LeftLink from "../../components/properlytics/left-link";
import {
    getLicence,
    getLicenceOptions,
    setClientId,
    setClientName
} from "../../services/config-helper";
import axios from "../../services/axios";
import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';

class SubscriptionPurchase extends React.Component {

    constructor(props) {
        super(props);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChangeQty = this.onChangeQty.bind(this);
        this.setSubscriptionState = this.setSubscriptionState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        let trial_days = config.trial_days;

        this.state = {
            user_id: '',
            email: '',
            name: '',
            subscription_type: 'PERSONAL',
            subscription_interval: 'MONTHLY_INTERVAL',
            has_trial: true,
            coupon_code: '',
            message: '',

            //plan prices
            start_date: moment().add(trial_days,'days').format('DD/MM/YYYY'),
            end_date: moment().add(trial_days,'days').add(1, 'month').format('DD/MM/YYYY'),
            qty: 1,
            sub_price: 0,
            tax: 0,
            discount: 0,
            discount_per: 0,
            total: 0,
            min_qty: 1,
            max_qty: 1,
            has_extra_user: false,
            redirect_choose_subscription: false,
            redirect_to_dashboard: false,
            redirect_to_portfolio: false,
            loading: false,
            coupon_loading: false,
        }
    }

    handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        const {stripe, elements} = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
        } else {
            console.log('[PaymentMethod]', paymentMethod);

            this.setState({
                loading: true
            })
            let postData = {
                stripe_token: paymentMethod.id,
                subscription_type: this.state.subscription_type,
                subscription_interval: this.state.subscription_interval,
                qty: this.state.qty,
                coupon: this.state.coupon_code,
                has_trial: (this.state.has_trial) ? 1 : 0
            };

            axios.post(config.add_subscription, postData).then(res => {
                if (res.success) {
                    storeInLocalStorage(res.data);
                    this.setState({
                        message: res.message,
                    });

                    if (this.state.subscription_type === config.subscription.personal_type) {
                        setClientId(this.state.user_id);
                        setClientName(this.state.name);
                        this.setState({
                            redirect_to_portfolio: true
                        })
                    } else {
                        this.setState({
                            redirect_to_dashboard: true
                        })
                    }
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            })
        }
    };

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    onBlur(event) {
        let value = event.target.value;
        if (value !== '') {
            this.setState({
                coupon_loading: true
            })
            let postData = {
                coupon_code: value,
            }

            axios.post(config.apply_coupon, postData).then(res => {
                let discount = 0;
                let discount_per = 0;
                if (res.success) {
                    this.showNotification(res.message, 'success')
                    discount = res.data.discount;
                    discount_per = res.data.discount_per;
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    coupon_loading: false,
                    discount: discount,
                    discount_per:discount_per
                })
                this.setSubscriptionState(this.state.subscription_type, this.state.subscription_interval, this.state.qty, this.state.has_trial, discount, discount_per);
            })
        } else {
            this.setState({
                discount: 0,
                discount_per:0
            })
            this.setSubscriptionState(this.state.subscription_type, this.state.subscription_interval, this.state.qty, this.state.has_trial, 0, 0);
        }
    }

    onChangeQty(selected) {
        this.setState({
            qty: selected.value,
        });
        this.setSubscriptionState(this.state.subscription_type, this.state.subscription_interval, selected.value, this.state.has_trial, this.state.discount, this.state.discount_per);
    }

    setSubscriptionState(subscription_type, subscription_interval, qty, has_trial, discount, discount_per) {
        let state = setPurchaseSubscriptionState(subscription_type, subscription_interval, qty, has_trial, discount, discount_per);
        this.setState(state);
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                user_id: profile.user_id,
                email: profile.email,
                name: profile.name
            });

            let subscription_type = this.props.subscription_type;
            let subscription_interval = this.props.subscription_interval;
            let has_trial = (typeof this.props.has_trial !== 'undefined') ? this.props.has_trial : true;

            if (subscription_type && subscription_interval) {
                this.setSubscriptionState(subscription_type, subscription_interval, this.state.qty, has_trial, this.state.discount, this.state.discount_per);
            } else {
                this.setState({
                    redirect_choose_subscription: true,
                })
            }
        } else {
            this.setState({
                redirect_choose_subscription: true,
            })
        }
    }

    render() {

        const {stripe} = this.props;

        if (this.state.redirect_choose_subscription) {
            return <Redirect to={config.site_url.subscription} />;
        }

        if (this.state.redirect_to_dashboard) {
            return <Redirect
                to={{
                    pathname: config.site_url.dashboard,
                    message: this.state.message,
                }}
            />;
        }

        if (this.state.redirect_to_portfolio) {
            return <Redirect
                to={{
                    pathname: config.site_url.portfolio,
                    message: this.state.message,
                }}
            />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Subscription
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Purchase plan.</p>
                        </Col>
                    </Row>

                    <div id="subscription">
                        <div className="rect_bx mt-5">
                            <div className="head_tron d-flex justify-content-between">
                                <h2 className="head t_green">Your subscription</h2>
                                <div>
                                    <h2 className="head ">
                                        {this.state.subscription_type}
                                        { '(' + this.state.subscription_interval + ')'}
                                    </h2>
                                    <h2 className="head t_gray">{this.state.start_date} to {this.state.end_date}</h2>
                               </div>    
                            </div>

                            <div className="inner_bx">
                                <Form id="prp_detail">
                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Licences</h4>
                                            <div className={(this.state.subscription_type !== 'PERSONAL') ? 'slct' : 'hide'}>
                                                <Select
                                                    defaultValue={getLicence(this.state.min_qty, this.state.max_qty)}
                                                    options={getLicenceOptions(this.state.min_qty, this.state.max_qty)}
                                                    value={getLicence(this.state.min_qty, this.state.max_qty, this.state.qty)}
                                                    onChange={this.onChangeQty}
                                                />
                                            </div>

                                            <div className={(this.state.subscription_type === 'PERSONAL') ? 'slct' : 'hide'}>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.qty}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Coupon Code</h4>
                                            <div className="slct">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter coupon code to get discount"
                                                    name="coupon_code"
                                                    value={this.state.coupon_code}
                                                    onChange={this.onInputChange}
                                                    onBlur={this.onBlur}
                                                />
                                                <div className={this.state.coupon_loading ? "input-loader" : "hide"} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tp_row pr-0 pt-0 pb-0 height60">
                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                                Subscription Cost
                                            </h4>

                                            <div className="d-flex w100 height38  justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Sub total
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.sub_price}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    GST {config.gst_percentage}%
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.tax}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Discount
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.discount}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="f14 t_black pbold fx_w">
                                                    Total
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value t_black pbold">${this.state.total}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <form className="card-payment" onSubmit={this.handleSubmit}>
                                            
                                            <h4 className="head t_green">Payment Method</h4>

                                            <div className="card-name">
                                                <label>Name on Card</label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Card holder's name"
                                                />
                                            </div>
                                            <div className="card-strip">    
                                                <CardElement
                                                    options={{hidePostalCode: true}}
                                                />
                                            </div>    
                                        </form>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex  height60 w100  justify-content-end">

                                                <Button
                                                    size="lg"
                                                    type="button"
                                                    className="t_green value wauto"
                                                    block
                                                    disabled={this.state.loading || !stripe}
                                                    onClick={this.handleSubmit}
                                                >
                                                    <div>
                                                        Subscribe <i aria-hidden="true" className="icon-arrow-right f10" />
                                                    </div>

                                                    <div className={(this.state.loading) ? 'btn-loading btn-s-loading right' : 'hide'}>
                                                        <div className="btn-loader" />
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tp_row_btm d-flex justify-content-between">
                                        <LeftLink url={config.site_url.subscription} text="Exit" />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;

    }
}

const InjectedCheckoutForm = (props) => {
    let {subscription_type, subscription_interval, has_trial} = props.location;

    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <SubscriptionPurchase
                    elements={elements}
                    stripe={stripe}
                    subscription_type={subscription_type}
                    subscription_interval={subscription_interval}
                    has_trial={has_trial}
                />
            )}
        </ElementsConsumer>
    );
};

export default InjectedCheckoutForm;
