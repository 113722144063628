import React from 'react';
import {
    Input,
    Form,
    Button
} from 'reactstrap';
import {dataURLtoFile} from "../../services/helper";
import validator from "./client-validators";
import config from 'config/config';
import Autocomplete from 'react-google-autocomplete';
import DOBField from "../../components/numbers/DOBField";
import moment from 'moment';
import UploadAvtar from "../../components/properlytics/upload-avtar";
import axios from "../../services/axios";

export default class AddEntity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            block_loading: false,
            is_edit: false,
            client_id: '',
            picture: '',
            img_display: false,
            picture_url: '',
            remove_picture: false,
            email: '',
            first_name: '',
            last_name: '',
            dob: '',
            gender: 'M',
            phone: '',
            address: '',
            has_joint_client: 0,
            j_first_name: '',
            j_last_name: '',
            j_dob: '',
            j_gender: 'M',
            j_phone: '',
            j_address: '',
        };

        this.validators = validator;
        this.validForm = this.validForm.bind(this);
        this.updateState = this.updateState.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleSwitchGender = this.handleSwitchGender.bind(this);
        this.handleSwitchJGender = this.handleSwitchJGender.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.addEdit = this.addEdit.bind(this);
    }

    onAddressChange(place) {
        this.setState({
            address: place.formatted_address,
            j_address: place.formatted_address
        });
        this.formValidators('address', place.formatted_address);
        this.formValidators('j_address', place.formatted_address);
    }

    updateState(name, value) {
        this.setState({
            [name]: value,
        })
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (this.state.has_joint_client) {
                if (field === 'first_name' || field === 'last_name' || field === 'email' || field === 'dob' ||
                    field === 'phone' || field === 'address' || field === 'j_first_name' ||
                    field === 'j_last_name' ||  field === 'j_dob' || field === 'j_phone' || field === 'j_address'
                ) {
                    if (!this.validators[field].valid) {
                        status = false;
                    }
                }
            } else {
                if (field === 'first_name' || field === 'last_name' || field === 'email' || field === 'dob' ||
                    field === 'phone' || field === 'address') {
                    if (!this.validators[field].valid) {
                        status = false;
                    }
                }
            }
        });
        return status;
    }

    showErrors() {
        let fields = [];
        if (this.state.has_joint_client) {
            fields = ['first_name', 'last_name', 'email', 'dob', 'phone', 'address', 'j_first_name', 'j_last_name', 'j_dob', 'j_phone', 'j_address'];
        } else {
            fields = ['first_name', 'last_name', 'email', 'dob', 'phone', 'address'];
        }

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    addEdit() {
        if (this.validForm()) {
            let postData = new FormData();
            if (this.state.is_edit) {
                postData.append('client_id', this.state.client_id);
                postData.append('remove_picture', (this.state.remove_picture) ? 1 : 0);
            }
            if (this.state.picture) {
                postData.append('picture', dataURLtoFile(this.state.picture));
            }
            postData.append('email', this.state.email);
            postData.append('first_name', this.state.first_name);
            postData.append('last_name', this.state.last_name);
            postData.append('dob', this.state.dob);
            //postData.append('gender', this.state.gender);
            postData.append('phone', this.state.phone);
            postData.append('address', this.state.address);
            postData.append('has_joint_client', this.state.has_joint_client);

            if (this.state.has_joint_client) {
                postData.append('j_first_name', this.state.j_first_name);
                postData.append('j_last_name', this.state.j_last_name);
                postData.append('j_dob', this.state.j_dob);
                //postData.append('j_gender', this.state.j_gender);
                postData.append('j_phone', this.state.j_phone);
                postData.append('j_address', this.state.j_address);
            }

            this.props.addUpdateClient(postData);
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.props.showNotification('Please enter all required data', 'danger')
            } else {
                this.props.showNotification(msg, 'danger')
            }
        }
    }

    handleSwitch() {
        if (this.state.has_joint_client) {
            this.setState({
                has_joint_client: 0,
                j_address: ''
            })
        } else {
            this.setState({
                has_joint_client: 1,
                j_address: this.state.address
            })
        }
    }

    handleSwitchGender() {
        this.setState({
            gender: this.state.gender === 'M' ? 'F' : 'M',
        })
    }

    handleSwitchJGender() {
        this.setState({
            j_gender: this.state.j_gender === 'M' ? 'F' : 'M',
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.open_client && this.props.open_client) {
            this.setState({
                block_loading: false,
                is_edit: false,
                client_id: '',
                picture: '',
                img_display: false,
                picture_url: '',
                remove_picture: false,
                email: '',
                first_name: '',
                last_name: '',
                dob: '',
                gender: 'M',
                phone: '',
                address: '',
                has_joint_client: 0,
                j_first_name: '',
                j_last_name: '',
                j_dob: '',
                j_gender: 'M',
                j_phone: '',
                j_address: '',
            });
        }

        if (nextProps.is_edit && this.props.client_id !== nextProps.client_id && !isNaN(nextProps.client_id)) {
            this.setState({
                block_loading: true
            });

            axios.get(config.get_client + nextProps.client_id).then(res => {
                if (res.success) {
                    let client = res.data.client;
                    this.setState({
                        is_edit: true,
                        client_id: client.client_id,
                        img_display: (client.picture),
                        email: client.email,
                        first_name: client.first_name,
                        last_name: client.last_name,
                        dob: (client.dob) ? moment(client.dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : "",
                        gender: client.gender,
                        phone: client.phone,
                        address: client.address,
                        has_joint_client: (client.has_joint_client) ? 1 : 0,
                        j_first_name: client.j_first_name,
                        j_last_name: client.j_last_name,
                        j_dob: (client.j_dob) ? moment(client.j_dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : "",
                        j_gender: client.j_gender,
                        j_phone: client.j_phone,
                        j_address: client.j_address,
                    });


                    if (client.picture) {
                        let postDataImage = {
                            id: client.client_id,
                            type: 2
                        };

                        axios.post(config.get_base64_image, postDataImage).then(res => {
                            if (res.success) {
                                this.setState({
                                    picture_url: res.data.image
                                })
                            }
                            this.setState({
                                block_loading: false
                            })
                        });
                    } else {
                        this.setState({
                            block_loading: false
                        })
                    }

                    this.formValidators('email', client.email);
                    this.formValidators('first_name', client.first_name);
                    this.formValidators('last_name', client.last_name);
                    this.formValidators('dob', client.dob);
                    this.formValidators('phone', client.phone);
                    this.formValidators('address', client.address);
                    this.formValidators('j_first_name', client.j_first_name);
                    this.formValidators('j_last_name', client.j_last_name);
                    this.formValidators('j_dob', client.j_dob);
                    this.formValidators('j_phone', client.j_phone);
                    this.formValidators('j_address', client.j_address);
                } else {
                    this.props.showNotification(res.message, 'danger')
                }
            });
        }
    }

    render() {
        return <div className={this.props.open_client ? 'add-entity open' : 'add-entity'}>
            <div className="rect_bx">
                <div className="head_tron d-flex justify-content-between">
                    <h2 className="head fsize22 fx_w t_green">{this.props.is_edit ? 'Edit' : 'Add'} Client</h2>

                    <span className=" close-sider mbtm0 fsize14 d-flex" onClick={this.props.openCloseClient.bind(this, false, null)}>
                            <i className="f20 icon-close"></i>
                    </span>
                </div>

                <div className={this.state.block_loading ? `inner_bx` : `hide`}>
                    <div className="page-loader show" />
                </div>

                <div className={this.state.block_loading ? `hide` : `inner_bx`}>
                    <Form id="entity-form">
                        <UploadAvtar
                            from="clients"
                            img_display={this.state.img_display}
                            picture_url={this.state.picture_url}
                            updateState={this.updateState}
                        />

                        <div className="tp_row pbtm0 ptop0">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Email*</h4>
                                <div className="slct d-flex">
                                    <Input
                                        type="text"
                                        placeholder="Enter your email"
                                        name="email"
                                        id="email"
                                        readOnly={this.props.is_edit}
                                        value={this.state.email}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">First Name*</h4>
                                <Input
                                    type="text"
                                    placeholder="Enter First Name"
                                    name="first_name"
                                    id="first_name"
                                    value={this.state.first_name}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Surname*</h4>
                                <Input
                                    type="text"
                                    placeholder="Enter Last Name"
                                    name="last_name"
                                    id="last_name"
                                    value={this.state.last_name}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">D.O.B*</h4>
                                <DOBField
                                    name="dob"
                                    value={this.state.dob}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        {/*<div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Gender*</h4>
                                <div className="field check-round slide-inverse">
                                    <span className="sts">M </span>
                                    <input
                                        type="checkbox"
                                        name="gender"
                                        id="check-slide-gender"
                                        checked={(this.state.gender === 'F')}
                                    />
                                    <label for="check-slide-gender">
                                        <span onClick={this.handleSwitchGender}/>
                                    </label>
                                    <span className="sts">F </span>
                                </div>
                            </div>
                        </div>*/}

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Contact no.*</h4>
                                <Input
                                    type="text"
                                    placeholder="Enter Your Contact Number"
                                    name="phone"
                                    id="phone"
                                    value={this.state.phone}
                                    maxLength={15}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Address*</h4>
                                <Autocomplete
                                    className="form-control"
                                    onPlaceSelected={this.onAddressChange}
                                    types={[]}
                                    name="address"
                                    onChange={this.onInputChange}
                                    value={this.state.address}
                                    componentRestrictions={{country: "au"}}
                                />
                            </div>
                        </div>

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Joint Client*</h4>
                                <div className="field check-round slide-inverse">
                                    <span className="sts">No </span>
                                    <input
                                        type="checkbox"
                                        name="has_joint_client"
                                        id="check-slide-i"
                                        defaultChecked={(this.state.has_joint_client)}
                                    />
                                    <label htmlFor="check-slide-i">
                                        <span onClick={this.handleSwitch}/>
                                    </label>
                                    <span className="sts">Yes </span>
                                </div>
                            </div>
                        </div>

                        <div className={(this.state.has_joint_client) ? '' : 'hide'}>
                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">First Name*</h4>
                                    <Input
                                        type="text"
                                        placeholder="Enter First Name"
                                        name="j_first_name"
                                        id="j_first_name"
                                        value={this.state.j_first_name}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </div>

                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">Surname*</h4>
                                    <Input
                                        type="text"
                                        placeholder="Enter Last Name"
                                        name="j_last_name"
                                        id="j_last_name"
                                        value={this.state.j_last_name}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </div>

                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">D.O.B*</h4>
                                    <DOBField
                                        name="j_dob"
                                        value={this.state.j_dob}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </div>

                            {/*<div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">Gender*</h4>
                                    <div className="field check-round slide-inverse">
                                        <span className="sts">M </span>
                                        <input
                                            type="checkbox"
                                            name="gender"
                                            id="check-slide-jgender"
                                            checked={(this.state.j_gender === 'F')}
                                        />
                                        <label for="check-slide-jgender">
                                            <span onClick={this.handleSwitchJGender}/>
                                        </label>
                                        <span className="sts">F </span>
                                    </div>
                                </div>
                            </div>*/}

                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">Contact no.</h4>
                                    <Input
                                        type="text"
                                        placeholder="Enter Your Contact Number"
                                        name="j_phone"
                                        id="j_phone"
                                        value={this.state.j_phone}
                                        maxLength={15}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </div>

                            <div className="tp_row">
                                <div className="hd_row d-flex">
                                    <h4 className="sm_tit fx_w">Address*</h4>
                                    <Autocomplete
                                        className="form-control"
                                        onPlaceSelected={this.onAddressChange}
                                        types={[]}
                                        name="j_address"
                                        onChange={this.onInputChange}
                                        value={this.state.j_address}
                                        componentRestrictions={{country: "au"}}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="tp-btm-row text-center">
                            <Button className={"grey-btn active"} size="lg" onClick={this.props.openCloseClient.bind(this, false, null)} type="button">
                                    <div>
                                       Close
                                    </div>
                            </Button>
                            <Button className={"middle_btn green-btn active"} size="lg" type="button" onClick={this.addEdit}>
                                    <div>
                                       Save
                                    </div>
                                    <div className={(this.props.loading) ? 'btn-loading left right' : ' hide'}>
                                        <div className="btn-loader" />
                                    </div>
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    }
}

