import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
} from 'reactstrap';
import ReactNotification from "react-notifications-component";
import validator from "./change-password-validators";
import {Redirect} from 'react-router';
import config from 'config/config';
import {getMyProfile, getNotificationObject} from "../../services/helper";
import RightButton from "../../components/properlytics/right-button";
import LeftLink from "../../components/properlytics/left-link";
import axios from "../../services/axios";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.validators = validator;
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.state = {
            old_password: '',
            new_password: '',
            confirm_password: '',
            can_view: true,
            loading: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }


    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (field === 'old_password' || field === 'new_password' || field === 'confirm_password') {
                if (!this.validators[field].valid) {
                    status = false;
                }
            }
        });
        return status;
    }

    showErrors() {
        let fields = ['old_password', 'new_password', 'confirm_password'];

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onChangePassword() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            postData.append('old_password', this.state.old_password);
            postData.append('new_password', this.state.new_password);
            postData.append('confirm_password', this.state.confirm_password);

            axios.post(config.change_password, postData).then(res => {
                if (res.success) {
                    this.showNotification(res.message, 'success')
                    this.setState({
                        old_password: '',
                        new_password: '',
                        confirm_password: '',
                    })
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.showNotification('Please enter all required data', 'danger')
            } else {
                this.showNotification(msg, 'danger')
            }
        }
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            let can_view = false;

            if (profile.source === 'MANUAL') {
                can_view = true;
            }

            this.setState({
                can_view: can_view,
            });
        } else {
            this.setState({
                can_view: false,
            })
        }
    }

    render() {

        if (!this.state.can_view) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Change Password
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Change your password</p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="userdetail">

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Old Password*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter your old password"
                                                    name="old_password"
                                                    id="old_password"
                                                    value={this.state.old_password}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        New Password*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter your new password"
                                                    name="new_password"
                                                    id="new_password"
                                                    value={this.state.new_password}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Confirm Password*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter confirm password"
                                                    name="confirm_password"
                                                    id="confirm_password"
                                                    value={this.state.confirm_password}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <div className="d-flex no-block align-items-cente auth-footer">
                                                <LeftLink text="Cancel" url={config.site_url.account} />

                                                <RightButton text="Change" onClick={this.onChangePassword} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default ChangePassword;
