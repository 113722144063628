import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Progress,
} from 'reactstrap';
import {
    getHouseTypesOptions,
    getSelectedHouseType,
    displayPropertySubType,
    getPropertySubTypesOptions,
    getPropertySubHouseType,
    getBedroomsOptions,
    getBedroomsType,
    getBathroomsOptions,
    getBathroomsType,
    getCarsOptions,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getLoanType,
    getEntityId,
    getTypeOfProperty,
    getNumber,
    getNumberOptions
} from "../../../services/config-helper"
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import config from "config/config";
import {getNotificationObject, setPropertyTabState } from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import Autocomplete from 'react-google-autocomplete';
import ExtraRepayment from "../sections/extra-repayment";
import PriceField from "../../../components/numbers/PriceField";
import moment from 'moment';
import axios from "../../../services/axios";
import ReactTooltip from 'react-tooltip';
import Select from "../../../components/properlytics/select";
import RefreshProjection from "../../../components/properlytics/refresh-projection";

export default class PropertyDetail extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        let year = parseInt(moment().format('YYYY'));
        this.state = {
            is_edit: false,
            property_id: '',
            client_id: '',
            entity_id: '',
            address: '',
            latitude: lat,
            longitude: long,
            property_type: getSelectedHouseType().value,
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            year_purchase: year,
            year_build: year,
            builder_name: '',
            purchase_price: '',
            current_price: '',
            property_sub_type: getPropertySubHouseType().value,
            land_price: '',
            build_price: '',
            adv_link: '',
            type_of_property: 1,

            min_build_year: year - 10,
            max_build_year: year,
            min_purchase_year: year - 10,
            max_purchase_year: year,

            progress: 0,

            images: [],
            overview: {},

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            interest_term: 5,
            to_year: 30,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            interest_term_back: 5,
            to_year_back: 30,
            loan_type_back: getLoanType().value,
            loans: [],

            property: {},
            loading: false,
            refreshLoading: false,
            display_sub_type: false,
            redirect_to_dashboard: false,
            next: false,

            is_existing_property: true,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onAddressChange = this.onAddressChange.bind(this);
        this.onPropertyTypeChange = this.onPropertyTypeChange.bind(this);
        this.onPropertySubTypeChange = this.onPropertySubTypeChange.bind(this);
        this.onBedroomsChange = this.onBedroomsChange.bind(this);
        this.onBathroomsChange = this.onBathroomsChange.bind(this);
        this.onCarsChange = this.onCarsChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setMinAndMaxYears = this.setMinAndMaxYears.bind(this);
        this.onBuildYearChange = this.onBuildYearChange.bind(this);
        this.onPurchaseYearChange = this.onPurchaseYearChange.bind(this);
        this.refreshProjection = this.refreshProjection.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        let name = event.target.name;

        if (name === 'purchase_price' || name === 'current_price' || name === 'land_price' ||
            name === 'build_price' || name === 'year_build'
        ) {
            if (!isNaN(event.target.value) || event.target.value === '') {
                this.setState({
                    [name]: event.target.value
                });
            }
        } else {
            this.setState({
                [name]: event.target.value
            });
        }
    }

    onAddressChange(place) {
        this.setState({
            address: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
        });
    }

    onPropertyTypeChange(selected) {
        if (displayPropertySubType(selected.value)) {
            this.setState({
                property_type: selected.value,
                display_sub_type: true,
                property_sub_type: getPropertySubHouseType().value,
            });
        } else {
            this.setState({
                property_type: selected.value,
                display_sub_type: false,
                property_sub_type: getPropertySubHouseType().value,
                land_price: '',
                build_price: '',
            });
        }
    }

    onPropertySubTypeChange(selected) {
        this.setState({
            property_sub_type: selected.value,
        });
    }

    onBedroomsChange (selected) {
        this.setState({
            bedrooms: selected.value,
        });
    }

    onBathroomsChange (selected) {
        this.setState({
            bathrooms: selected.value,
        });
    }

    onCarsChange (selected) {
        this.setState({
            car_space: selected.value,
        });
    }

    onBuildYearChange(selected) {
        this.setState({
            year_build: selected.value,
        });
    }

    onPurchaseYearChange(selected) {
        this.setState({
            year_purchase: selected.value,
        });
    }

    refreshProjection() {
        this.onSubmit(true);
    }

    onSubmit(fromRefresh = false) {
        let loadingName = (fromRefresh) ? 'refreshLoading' : 'loading';

        this.setState({
            [loadingName]: true
        });
        let postData = new FormData();
        if (this.state.is_edit) {
            postData.append('property_id', this.state.property_id);
        }

        postData.append('client_id', this.state.client_id);
        postData.append('entity_id', this.state.entity_id);
        postData.append('address', this.state.address);
        postData.append('latitude', this.state.latitude);
        postData.append('longitude', this.state.longitude);
        postData.append('property_type', this.state.property_type);
        postData.append('bedrooms', this.state.bedrooms);
        postData.append('bathrooms', this.state.bathrooms);
        postData.append('car_space', this.state.car_space);
        postData.append('year_build', this.state.year_build);
        postData.append('builder_name', this.state.builder_name);
        postData.append('purchase_price', this.state.purchase_price);
        postData.append('current_price', this.state.current_price);
        postData.append('property_sub_type', this.state.property_sub_type);
        postData.append('land_price', this.state.land_price);
        postData.append('build_price', this.state.build_price);
        postData.append('year_purchase', this.state.year_purchase);
        postData.append('adv_link', this.state.adv_link);
        postData.append('type_of_property', this.state.type_of_property);

        axios.post(config.add_property_detail, postData).then(res => {
            if (res.success) {
                if (fromRefresh) {
                    this.setState({
                        overview: res.data.property.overview
                    });
                } else {
                    this.showNotification(res.message, 'success');
                }
                let property_id = (typeof res.data.property.property_detail !== 'undefined') ? res.data.property.property_detail.property_id : '';
                this.setState({
                    property_id: property_id,
                    next: !(fromRefresh),
                    property: res.data.property
                });
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingName]: false
            })
        });
    }

    setStateData(property) {
        this.setState(setPropertyTabState(property));

        this.setMinAndMaxYears(property.property_detail.type_of_property, true);

        this.setState({
            is_edit: true,
        })
    }

    setMinAndMaxYears(type, isEdit) {
        let min_build_year = 1900;
        let max_build_year = parseInt(moment().format('YYYY'));
        let min_purchase_year = 1900;
        let max_purchase_year = parseInt(moment().format('YYYY'));
        if (isEdit && parseInt(this.state.year_purchase) > max_purchase_year) {
            max_purchase_year = parseInt(this.state.year_purchase)
        }

        if (parseInt(type) === 2) {
            //future
            min_build_year = parseInt(moment().format('YYYY')) - 20;
            max_build_year = parseInt(moment().format('YYYY')) + 20;
            min_purchase_year = parseInt(moment().format('YYYY'));
            max_purchase_year = parseInt(moment().format('YYYY')) + 20;
            if (isEdit && parseInt(this.state.year_purchase) < min_purchase_year) {
                min_purchase_year = parseInt(this.state.year_purchase)
            }
        }

        this.setState({
            min_build_year: min_build_year,
            max_build_year: max_build_year,
            min_purchase_year: min_purchase_year,
            max_purchase_year: max_purchase_year,
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.property_detail + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    axios.get(config.get_property + property_id).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let client_id = (getClientId()) || '';
            let entity_id = (getEntityId()) || '';
            let type_of_property = (getTypeOfProperty()) || '';
            if (client_id === '' || entity_id === '' || type_of_property === '') {
                this.setState({
                    redirect_to_dashboard: true,
                })
            } else {
                this.setMinAndMaxYears(type_of_property, false);

                this.setState({
                    client_id: client_id,
                    entity_id: entity_id,
                    type_of_property: type_of_property,
                });
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.acquisition_cost + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

                <ReactNotification ref={this.notificationDOMRef} />

                <ReactTooltip  textColor='#000000' backgroundColor='#ffffff' border borderColor='#cccccc' effect='solid'/>

            <Row>
                <Col xl="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*PROPERTY DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks ui-sortable">
                        <div className="block">
                            <div className="plisting_form rect_bx flip_bx">
                                <div className="head_tron d-flex">
                                    <h2 className="head d-flex fx_w t_green">Property Details</h2>

                                    <Progress value={this.state.progress}/>

                                    <div className="progress-no t_green">{this.state.progress}%</div>

                                    <RefreshProjection
                                        show={this.state.is_edit}
                                        loading={this.state.refreshLoading}
                                        onClick={this.refreshProjection}
                                    />

                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">
                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Property Address*</h4>
                                                <Autocomplete
                                                    className="form-control"
                                                    onPlaceSelected={this.onAddressChange}
                                                    types={[]}
                                                    name="address"
                                                    value={this.state.address}
                                                    onChange={this.onInputChange}
                                                    componentRestrictions={{country: "au"}}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pbtm0 ptop0">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Property Type*</h4>
                                                <div className="slct d-flex">
                                                    <div className="p_type_opt">
                                                        <Select
                                                            defaultValue={getSelectedHouseType()}
                                                            options={getHouseTypesOptions()}
                                                            onChange={this.onPropertyTypeChange}
                                                            value={getSelectedHouseType(this.state.property_type)}
                                                        />
                                                    </div>

                                                    <div className={this.state.display_sub_type ? "p_type_cat" : "hide"}>
                                                        <Select
                                                            defaultValue={getPropertySubHouseType()}
                                                            options={getPropertySubTypesOptions()}
                                                            onChange={this.onPropertySubTypeChange}
                                                            value={getPropertySubHouseType(this.state.property_sub_type)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">{(this.state.type_of_property === 2) ? "Purchase Price*" : "Original Purchase Price*"}</h4>
                                                <PriceField
                                                    placeholder={(this.state.type_of_property === 2) ? "Enter Property Price" : "Enter Original Purchase Price*"}
                                                    name="purchase_price"
                                                    value={this.state.purchase_price}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className={(this.state.type_of_property === 1) ? "tp_row" : "hide"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Property Value*</h4>
                                                <div className="row align-items-center">
                                                    <div className="col-lg-12">
                                                        <PriceField
                                                            placeholder="Enter Property Value"
                                                            name="current_price"
                                                            value={this.state.current_price}
                                                            onChange={this.onInputChange}
                                                        />
                                                       
                                                    </div>

                                                    {/*<div className="col-lg-6 ">
                                                       <div className="corelogic"> or <button type="button" className="tab_btn btn">CoreLogic</button></div>
                                                    </div>*/}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={this.state.display_sub_type ? "tp_row" : "hide"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Land Price*</h4>
                                                <PriceField
                                                    placeholder="Enter Land Price"
                                                    name="land_price"
                                                    value={this.state.land_price}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className={this.state.display_sub_type ? "tp_row" : "hide"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Build Price*</h4>
                                                <PriceField
                                                    placeholder="Enter Build Price"
                                                    name="build_price"
                                                    value={this.state.build_price}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Bedrooms*</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getBedroomsType()}
                                                        options={getBedroomsOptions()}
                                                        onChange={this.onBedroomsChange}
                                                        value={getBedroomsType(this.state.bedrooms)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Bathrooms</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getBathroomsType()}
                                                        options={getBathroomsOptions()}
                                                        onChange={this.onBathroomsChange}
                                                        value={getBathroomsType(this.state.bathrooms)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Cars*</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getCarsType()}
                                                        options={getCarsOptions()}
                                                        onChange={this.onCarsChange}
                                                        value={getCarsType(this.state.car_space)}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Builder</h4>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Builder Name"
                                                    name="builder_name"
                                                    id="builder_name"
                                                    value={this.state.builder_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Advertised Link</h4>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Advertised Link"
                                                    name="adv_link"
                                                    id="adv_link"
                                                    value={this.state.adv_link}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Year Built*</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getNumber(this.state.min_build_year, this.state.max_build_year)}
                                                        options={getNumberOptions(this.state.min_build_year, this.state.max_build_year)}
                                                        onChange={this.onBuildYearChange}
                                                        value={getNumber(this.state.min_build_year, this.state.max_build_year, this.state.year_build)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Purchase Year*</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getNumber(this.state.min_purchase_year, this.state.max_purchase_year)}
                                                        options={getNumberOptions(this.state.min_purchase_year, this.state.max_purchase_year)}
                                                        onChange={this.onPurchaseYearChange}
                                                        value={getNumber(this.state.min_purchase_year, this.state.max_purchase_year, this.state.year_purchase)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row_btm">
                                            <RightButton text="Next" onClick={this.onSubmit.bind(this, false)} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col xl={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        interest_term={this.state.interest_term}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        interest_term_back={this.state.interest_term_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                        loans={this.state.loans}
                        is_existing_property={this.state.is_existing_property}
                    />
                </Col>
            </Row>
        </div>

    }
}
