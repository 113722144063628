import config from 'config/config';
import Account from "../views/user/account";
import ChangePassword from "../views/user/change-password";
import Dashboard from "../views/dashboard/dashboard";
import PropertyList from "../views/dashboard/property-list";
import AdminDashboard from "../views/admin/dashboard";
import AdminUserForm from "../views/admin/user";
import ClientForm from "../views/client/client-form";
import Subscription from "../views/subscription/subscription";
import SubUserForm from "../views/subscription/user";
import Portfolio from '../views/portfolio/portfolio';
import InjectedCheckoutForm from "../views/subscription/subscription-purchase";
import SubscriptionManage from "../views/subscription/subscription-manage";
import CustomSubscription from "../views/subscription/custom-subscription";
import AdminUserList from "../views/admin/user-list";

var innerRoutes = [
    { path: config.site_url.subscription_custom, name: 'SubscriptionCustom', icon: 'mdi mdi-account-key', component: CustomSubscription },
    { path: config.site_url.subscription_purchase, name: 'SubscriptionPurchase', icon: 'mdi mdi-account-key', component: InjectedCheckoutForm },
    { path: config.site_url.subscription_manage, name: 'SubscriptionManage', icon: 'mdi mdi-account-key', component: SubscriptionManage },
    { path: config.site_url.subscription, name: 'Subscription', icon: 'mdi mdi-account-key', component: Subscription },
    { path: config.site_url.sub_user_add, name: 'SubUserAdd', icon: 'mdi mdi-account-key', component: SubUserForm },
    { path: config.site_url.sub_user_edit + '/:id', name: 'SubUserEdit', icon: 'mdi mdi-account-key', component: SubUserForm },

    { path: config.site_url.account, name: 'Account', icon: 'mdi mdi-account-key', component: Account },
    { path: config.site_url.change_password, name: 'ChangePassword', icon: 'mdi mdi-account-key', component: ChangePassword },
    { path: config.site_url.dashboard, name: 'BDashboard', icon: 'mdi mdi-account-key', component: Dashboard },
    { path: config.site_url.add_client, name: 'AddClient', icon: 'mdi mdi-account-key', component: ClientForm },
    { path: config.site_url.edit_client + '/:id', name: 'EditClient', icon: 'mdi mdi-account-key', component: ClientForm },
    { path: config.site_url.portfolio, name: 'Portfolio', icon: 'mdi mdi-account-key', component: Portfolio },

    { path: config.site_url.properties, name: 'PropertyList', icon: 'mdi mdi-account-key', component: PropertyList },

    { path: config.site_url.admin_dashboard, name: 'AdminDashboard', icon: 'mdi mdi-account-key', component: AdminDashboard },
    { path: config.site_url.admin_add_user, name: 'AdminAddUser', icon: 'mdi mdi-account-key', component: AdminUserForm },
    { path: config.site_url.admin_edit_user + '/:id', name: 'AdminEditUser', icon: 'mdi mdi-account-key', component: AdminUserForm },
    { path: config.site_url.admin_users, name: 'AdminUsers', icon: 'mdi mdi-account-key', component: AdminUserList },

    { path: '/', pathTo: config.site_url.dashboard, name: 'Dashboard', redirect: true },
];
export default innerRoutes;