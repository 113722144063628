const env = 'local'; //local = local environment, live = live environment

//local
const local_data = {
    //base_url: 'http://localhost:8000/',    
    base_url: 'https://properlytics-v2.webappstudio.com.au/staging/public/',
    linkedin_redirect_uri: 'http://localhost:3000/linkedin',
    stripe_key: 'pk_test_51J3aTlCIxWMrz8iRlL1wKPnAegs2GyoDdfF7pGJPK7kAmM9mKgXYUM3h0m1BVV19DlWEP6FUwTY9HWZCtHWuyQLy00qNlzYlxl',
}

//live
const live_data = {
    base_url: 'https://app.properlytics.com.au/production/api/site/',
    linkedin_redirect_uri: 'https://app.properlytics.com.au/linkedin',
    stripe_key: 'pk_live_Cna6BNr4XBEGjoIFaNl8fDYV',
}

const config = {
    base_url : (env === 'local') ? local_data.base_url : live_data.base_url,

    //Urls
    csrf: 'sanctum/csrf-cookie',

    register: 'api/register',
    verify_otp: 'api/verify',
    resend_otp: 'api/resend-verification-code',

    forgot_password: 'api/forgot-password',
    check_reset_password: 'api/check/reset-password',
    reset_password: 'api/reset-password',

    login: 'api/login',
    logout: 'api/logout',
    linkedin_login: 'api/linkedin/login',
    facebook_login: 'api/facebook/login',

    get_profile: 'api/profile',

    update_account: 'api/user/update',
    change_password: 'api/user/change-password',
    dashboard: 'api/dashboard',


    apply_coupon: 'api/subscription/coupon-apply',
    add_subscription: 'api/subscription/add',
    get_subscription: 'api/subscription',
    request_custom_account: 'api/request/custom-account',
    update_subscription: 'api/subscription/update',
    resume_subscription: 'api/subscription/resume',
    cancel_subscription: 'api/subscription/cancel',

    add_sub_user: 'api/sub-user',
    get_sub_user: 'api/sub-user/',
    edit_sub_user: 'api/sub-user/',
    delete_sub_user: 'api/sub-user/delete/',


    clients: 'api/clients',
    add_clients: 'api/clients',
    get_client: 'api/clients/',
    edit_clients: 'api/clients/',
    delete_clients: 'api/clients/delete/',

    entities: 'api/entities',
    add_entity: 'api/entity',
    get_entity: 'api/entity/',
    edit_entity: 'api/entity/update/',
    delete_entity: 'api/entity/delete/',
    get_portfolio_data: 'api/entity/portfolio/',

    get_properties: 'api/properties/',
    delete_property: 'api/property/delete/',
    get_property: 'api/property/',
    duplicate_properties: 'api/property/duplicate/',
    property_change_entity: 'api/property/entity/change',

    add_property_detail: 'api/property/add',
    get_stamp_duty: 'api/stamp-duty',
    add_stamp_duty: 'api/stamp-duty/add',
    cal_stamp_duty: 'api/calculate/stamp-duty',
    add_acquisition_costs: 'api/acquisition-cost/add',
    get_funding_structure: 'api/funding-structure/',
    cal_loan_repayment: 'api/calculate/loan-repayment/',
    cal_extra_repayment: 'api/calculate/extra-repayment',
    add_funding_structure: 'api/funding-structure/add',
    add_investment_outcomes: 'api/investment-outcome/add',
    cal_agent_commission: 'api/calculate/agent-commission',
    add_investment_expenses: 'api/investment-expense/add',
    add_economic_assumption: 'api/economic-assumption/add',
    cal_tax_benefits: 'api/calculate/tax-benefits',
    get_income_scales: 'api/income-tax',
    add_income_scales: 'api/income-tax/add',
    get_tax_offset: 'api/tax-offset',
    add_tax_offset: 'api/tax-offset/add',
    add_income: 'api/income/add',
    get_deduction: 'api/calculate/deduction/',
    cal_depreciation_of_fittings: 'api/calculate/depreciation-of-fitting',
    add_deduction: 'api/deduction/add',
    get_who_pay: 'api/calculate/who-pay',
    add_cashflow: 'api/cashflow/add',

    upload_image: 'api/image/upload',
    delete_image: 'api/image/',
    get_base64_image: 'api/image/get',

    export_report: 'api/export/report',
    export_portfolio_report: 'api/export/portfolio/report',
    export_wealth_report: 'api/export/wealth/report',

    admin_dashboard: 'api/admin/dashboard',
    admin_add_user: 'api/admin/user/add',
    admin_get_user: 'api/admin/user/view',
    admin_edit_user: 'api/admin/user/edit',
    admin_delete_user: 'api/admin/user/delete',
    admin_user_list: 'api/admin/users-list',



    back_to_website: 'https://properlytics.com.au',
    support_url: 'https://support.properlytics.com.au',

    google_analytics_url: '',

    //api key
    session_time_out: 60, //in minutes

    map_key: 'AIzaSyDzJdgZ8tzEoxcZiBFgPJW8cMGAlTNrvIo',

    /* FB Data */
    fb_client_id: '243333569714785',
    fb_pixel_id: '460403397826774',

    /* Linkedin Data */
    linkedin_client_id: '86dchjfzc2rfvi',
    linkedin_redirect_uri: (env === 'local') ? local_data.linkedin_redirect_uri : live_data.linkedin_redirect_uri,

    /* Stripe Key */
    stripe_key: (env === 'local') ? local_data.stripe_key : live_data.stripe_key,

    //price control max length
    price_max_length: 15,
    percentage_max_length: 5,
    number_max_length: 3,

    who_pay_color: {
        you: '#00C880',
        tax_credit: '#C5DBCF',
        tenant: '#000000',
    },

    trial_days: 7,
    gst_percentage: 10,

    contact_no: '1300 30 80 23',

    subscription: {
        no: '',
        web: 'WEB',
        ios: 'IOS',
        android: 'ANDROID',

        no_type: '',
        personal_type: 'PERSONAL',
        professional_type: 'PROFESSIONAL',
        enterprise_type: 'ENTERPRISE',

        month: 'MONTHLY_INTERVAL',
        year: 'YEARLY_INTERVAL',
    },

    entity_type: {
        PERSONAL_INVESTMENT: 'Personal Investment',
        SMSF: 'SMSF',
        COMPANY: 'Company',
        TRUST: 'Trust',
        PRINCIPAL_PLACE_OF_RESIDENCE: 'Owner Occupied',
    },

    entity_type_values: {
        PERSONAL_INVESTMENT: 'PERSONAL_INVESTMENT',
        SMSF: 'SMSF',
        COMPANY: 'COMPANY',
        TRUST: 'TRUST',
        PRINCIPAL_PLACE_OF_RESIDENCE: 'PRINCIPAL_PLACE_OF_RESIDENCE',
    },

    //site urls
    hash: '#',

    site_url: {
        login: '/login',
        register: '/register',
        businessTrial: '/businesstrial',
        personalTrial: '/personaltrial',
        setup_profile: '/setup-profile',
        forgot_password: '/forgot-password',
        reset_password: '/reset-password',
        thank_you: '/thank-you',
        verification: '/verify',

        dashboard: '/dashboard', //welcome screen after login
        portfolio: '/portfolio',
        properties: '/properties',
        account: '/account',
        change_password: '/change-password',

        subscription: '/subscription',
        subscription_purchase: '/subscription/purchase',
        subscription_manage: '/subscription/manage',
        subscription_custom: '/subscription/custom',
        sub_user_add: '/sub-user/add',
        sub_user_edit: '/sub-user/edit',

        add_client: '/client/add',
        edit_client: '/client/edit',

        property_detail: '/property-detail',
        acquisition_cost: '/acquistion-detail',
        funding_structure: '/funding-strucuture',
        investment_outcome: '/investment-outcome',
        investment_expenses: '/investment-expenses',
        economic_assumption: '/economic-assumptions',
        income: '/income',
        deduction: '/deduction',
        cashflow: '/cashflow',


        admin_dashboard: '/admin/dashboard',
        admin_add_user: '/admin/user/add',
        admin_edit_user: '/admin/user/edit',
        admin_users: '/admin/users',
    },

    //numbers

    price_prefix: '$',
    percentage_suffix: '%',

    //app link

    ios_app: '#',
    android_app: '#',
};

export default config;