import React from 'react';
import {getMyProfile, getNotificationObject} from "../../services/helper";
import {Redirect} from 'react-router';
import config from 'config/config';
import DashboardTabs from "../../components/dashboard/dashboard-tabs";
import ClientTable from "../../components/dashboard/client-table";
import ReactNotification from "react-notifications-component";
import {setClientId, setClientName} from "../../services/config-helper";
import axios from "../../services/axios";
import ClientForm from "../../components/client/client-form";

class Dashboard extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            is_personal_account: false,
            dashboard: {
                clients: 0,
                properties: 0,
                recent_properties: [],
                account_type: 1,
            },

            loading: false,
            clients: [],
            paginator: {},

            is_edit: false,
            client_id: '',
            open_client: false,
            action_loading: false,

            filter: {
                search_by: '',
                search_text: '',
            },

            redirect_to_property: false,
            redirect_to_portfolio: false,
        };

        this.getClientProperties = this.getClientProperties.bind(this);
        this.getClientPortfolios = this.getClientPortfolios.bind(this);
        this.fetchClients = this.fetchClients.bind(this);
        this.setClients = this.setClients.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.openCloseClient = this.openCloseClient.bind(this);
        this.addUpdateClient = this.addUpdateClient.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();
	}

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    addUpdateClient(postData) {
        this.setState({
            action_loading: true
        });

        let url = config.add_clients;
        if (this.state.is_edit) {
            url = config.edit_clients + this.state.client_id;
        }

        axios.post(url, postData).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success');
                this.setState({
                    open_client: false,
                    is_edit: false,
                    client_id: ''
                })
                this.setClients(res);
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                action_loading: false
            })
        });
    }

    openCloseClient(is_edit = false, client_id = null) {
	    if (document.querySelector('.edit-delete-client') !== null) {
            document.querySelectorAll('.edit-delete-client').forEach((item) => {
                item.classList.remove('show')
            })
        }
        this.setState({
            open_client: !this.state.open_client,
            is_edit: is_edit,
            client_id: (client_id !== null) ? client_id : ''
        });
    }

    fetchClients(page, filter) {
        if (page !== '') {
            this.setState({
                loading: true
            });

            let search_by = '';
            let search_text = '';
            if (typeof filter !== 'undefined' && typeof filter.search_by !== 'undefined') {
                if (filter.search_by !== '' && filter.search_text !== '') {
                    search_by = (filter.search_by !== '') ? filter.search_by : '';
                    search_text = (filter.search_text !== '') ? filter.search_text : '';
                }
            } else {
                if (this.state.filter.search_by !== '' && this.state.filter.search_text !== '') {
                    search_by = (this.state.filter.search_by !== '') ? this.state.filter.search_by : '';
                    search_text = (this.state.filter.search_text !== '') ? this.state.filter.search_text : '';
                }
            }

            let url = config.clients + '?page=' + page + '&search_by=' + search_by + '&search_text=' + search_text
            axios.get(url).then(res => {
                if (res.success) {
                    this.setClients(res);
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                });
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    setClients(res) {
        this.setState({
            clients: res.data.client_list,
            paginator: res.data.pagination,
        })
    }

    changeFilter(filter) {
        this.fetchClients(1, filter);

        this.setState({
            filter: filter
        })
    }

    getClientProperties(client_id) {
	    this.setState({
            redirect_to_property: true
        });
        setClientId(client_id);
    }

    getClientPortfolios(client_id, name) {
        this.setState({
            redirect_to_portfolio: true
        });
        setClientId(client_id);
        setClientName(name);
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                is_personal_account: (profile.account_type === 1),
                account_type: profile.account_type
            });
        }

        axios.get(config.dashboard).then(res => {
            if (res.success) {
                this.setState({
                    dashboard: res.data
                })
            } else {
                this.showNotification(res.message, 'danger')
            }
        });

        this.fetchClients(1);
    }
    
    render() {

        //if personal account redirect to property screen
        if (this.state.is_personal_account) {
            return <Redirect to={config.site_url.properties} />;
        }

        if (this.state.redirect_to_property) {
            return <Redirect to={config.site_url.properties} />;
        }

        if (this.state.redirect_to_portfolio) {
            return <Redirect to={config.site_url.portfolio} />;
        }

        return (
            <div className="page-content no-gutters  container-fluid">

                <ReactNotification ref={this.notificationDOMRef} />

                <div className="client_wrap">

                    <DashboardTabs
                        dashboard={this.state.dashboard}
                        account_type={this.state.account_type}
                        page="dashboard"
                        changeFilter={this.changeFilter}
                    />

                    <ClientTable
                        clients={this.state.clients}
                        paginator={this.state.paginator}
                        loading={this.state.loading}
                        fetchClients={this.fetchClients}
                        showNotification={this.showNotification}
                        setClients={this.setClients}
                        getClientProperties={this.getClientProperties}
                        getClientPortfolios={this.getClientPortfolios}
                        openCloseClient={this.openCloseClient}
                    />

                    <ClientForm
                        loading={this.state.action_loading}
                        is_edit={this.state.is_edit}
                        client_id={this.state.client_id}
                        open_client={this.state.open_client}
                        openCloseClient={this.openCloseClient}
                        showNotification={this.showNotification}
                        addUpdateClient={this.addUpdateClient}
                    />

            </div>
        </div>
        );
    }
}
            
export default Dashboard;