import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Card,
    CardTitle,
    CardBody,
    InputGroupText
} from 'reactstrap';
import config from 'config/config';
import {getNotificationObject} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import RightButton from "../../components/properlytics/right-button";
import axios from "../../services/axios";
import LeftLink from "../../components/properlytics/left-link";

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            loading: false,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmit() {
        if (this.state.email) {
            let success = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i.test(this.state.email);

            if (success) {
                this.setState({
                    loading: true
                });
                axios.get(config.csrf)
                    .then(response => {
                        axios.post(config.forgot_password, {email: this.state.email}).then(res => {
                            if (res.success) {
                                this.showNotification(res.message, 'success');
                                this.setState({
                                    email: ''
                                });
                            } else {
                                this.showNotification(res.message, 'danger');
                            }
                            this.setState({
                                loading: false
                            });
                        })
                    });
            } else {
                this.showNotification('Please enter valid email', 'danger');
            }
        } else {
            this.showNotification('Please enter email', 'danger');
        }
    }

    render() {

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Forgot Password
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Forgot password for Properlytics</p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="signupform">

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Email*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <div className="d-flex no-block align-items-center auth-footer reset-bx">
                                                <LeftLink text="Go to Login" url={config.site_url.login}/>

                                                <RightButton text="Send Me Reset Password Link" onClick={this.onSubmit} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

