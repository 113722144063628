import {getConfig, getSettings} from "./helper";

export const getHouseTypesOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.property_type) {
        for (let [key, item] of Object.entries(config.property_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getSelectedHouseType = (value = '') => {
    let options = getHouseTypesOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const displayPropertySubType = (value) => {
    let config = getConfig();
    if (config.property_extra_display_type) {
        return (config.property_extra_display_type.toString() === value.toString());
    }
    return false
};

export const getPropertySubTypesOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.property_type) {
        for (let [key, item] of Object.entries(config.property_sub_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getPropertySubHouseType = (value = '') => {
    let options = getPropertySubTypesOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getBedroomsOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.beds) {
        for (let [key, item] of Object.entries(config.beds)) {
            data.push({
                value: item,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getBedroomsType = (value = '') => {
    let options = getBedroomsOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getBathroomsOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.bath) {
        for (let [key, item] of Object.entries(config.bath)) {
            data.push({
                value: item,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getBathroomsType = (value = '') => {
    let options = getBathroomsOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getCarsOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.cars) {
        for (let [key, item] of Object.entries(config.cars)) {
            data.push({
                value: item,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getCarsType = (value = '') => {
    let options = getCarsOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const projectionYearOptions = () => {
    let data = [];
    data.push({value: 'YR', label: 'YR', color: '#ccf4e6', isFixed: true});
    for (let i=3;i<=50;i++) {
        data.push({value: i, label: 'YR' + i, color: '#ccf4e6'});
    }

    return data
};

export const getSelectedYearOption = (value = '') => {
    let options = projectionYearOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getYear = () => {
    let settings = getSettings();
    if (settings.year) {
        return settings.year;
    }
    return '';
};

export const setYear = (year) => {
    let settings = getSettings();
    if (settings.year) {
        settings.year = year;
    } else {
        settings.year = year;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};

export const getDefaultMapLat = () => {
    let config = getConfig();
    if (config.default_map_address) {
        if (config.default_map_address.lat) {
            return parseFloat(config.default_map_address.lat);
        }
    }
    return 0.000000;
};

export const getDefaultMapLng = () => {
    let config = getConfig();
    if (config.default_map_address) {
        if (config.default_map_address.long) {
            return parseFloat(config.default_map_address.long);
        }
    }
    return 0.000000;
};

export const getClientId = () => {
    let settings = getSettings();
    if (settings.client_id) {
        return settings.client_id;
    }
    return '';
};

export const setClientId = (client_id) => {
    let settings = getSettings();
    if (settings.client_id) {
        settings.client_id = client_id;
    } else {
        settings.client_id = client_id;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};

export const getClientName = () => {
    let settings = getSettings();
    if (settings.client_name) {
        return settings.client_name;
    }
    return '';
};

export const setClientName = (client_name) => {
    let settings = getSettings();
    if (settings.client_name) {
        settings.client_name = client_name;
    } else {
        settings.client_name = client_name;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};

export const getEntityName = () => {
    let settings = getSettings();
    if (settings.entity_name) {
        return settings.entity_name;
    }
    return '';
};

export const setEntityName = (entity_name) => {
    let settings = getSettings();
    if (settings.entity_name) {
        settings.entity_name = entity_name;
    } else {
        settings.entity_name = entity_name;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};

export const getEntityId = () => {
    let settings = getSettings();
    if (settings.entity_id) {
        return settings.entity_id;
    }
    return '';
};

export const setEntityId = (entity_id) => {
    let settings = getSettings();
    if (settings.entity_id) {
        settings.entity_id = entity_id;
    } else {
        settings.entity_id = entity_id;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};


export const getEntityTypes = () => {
    let settings = getSettings();
    if (settings.entity_type) {
        return settings.entity_type;
    }
    return '';
};

export const setEntityType = (entity_type) => {
    let settings = getSettings();
    if (settings.entity_type) {
        settings.entity_type = entity_type;
    } else {
        settings.entity_type = entity_type;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};

export const getTypeOfProperty = () => {
    let settings = getSettings();
    if (settings.type_of_property) {
        return settings.type_of_property;
    }
    return '';
};

export const setTypeOfProperty = (type_of_property) => {
    let settings = getSettings();
    if (settings.type_of_property) {
        settings.type_of_property = type_of_property;
    } else {
        settings.type_of_property = type_of_property;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};


/*Tab 2*/
export const getHouseTypeOptions = () => {
    let config = getConfig();
    let property_type = getTypeOfProperty();
    let data = [];
    if (config.house_type) {
        for (let [key, item] of Object.entries(config.house_type)) {
            if (parseInt(property_type) === 1 && parseInt(key) === 4) {
                data.push({
                    value: key,
                    label: item + " (New Construction)",
                    color: '#ccf4e6'
                })
            } else {
                data.push({
                    value: key,
                    label: item,
                    color: '#ccf4e6'
                })
            }
        }
    }
    return data;
};

export const getHouseType = (value = '') => {
    let options = getHouseTypeOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getPropertyUsedOptions = () => {
    let entity_type = getEntityTypes();
    let property_type = getTypeOfProperty();
    let config = getConfig();
    let data = [];
    if (config.property_used) {
        for (let [key, item] of Object.entries(config.property_used)) {
            if ((entity_type === 'COMPANY' || entity_type === 'SMSF' || entity_type === 'TRUST') && (parseInt(key) === 1)) {
                continue;
            }

            if (parseInt(property_type) === 1 && parseInt(key) === 1) {
                data.push({
                    value: key,
                    label: "I currently live there",
                    color: '#ccf4e6'
                })
            } else {
                data.push({
                    value: key,
                    label: item,
                    color: '#ccf4e6'
                })
            }
        }
    }
    return data;
};

export const getPropertyUsed = (value = '') => {
    let options = getPropertyUsedOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getPropertyUsedText = (value = '') => {
    let options = getPropertyUsedOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option.label;
            }
        }

        return options[0].label;
    }
    return '';
};

export const getBuyerTypeOptions = () => {
    let entity_type = getEntityTypes();
    let config = getConfig();
    let data = [];
    if (config.buyer_type) {
        for (let [key, item] of Object.entries(config.buyer_type)) {
            if ((entity_type === 'COMPANY' || entity_type === 'SMSF' || entity_type === 'TRUST') && (parseInt(key) === 1 || parseInt(key) === 4)) {
                continue;
            }
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getBuyerType = (value = '') => {
    let options = getBuyerTypeOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getStateOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.states) {
        for (let [key, item] of Object.entries(config.states)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getState = (value = '') => {
    let options = getStateOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getRepaymentLoanType = (isExistingProperty = true, loans = 0) => {
    let data = [];
    data.push({value: '', label: 'Select Loan', color: '#ccf4e6', isFixed: true});

    if (isExistingProperty) {
        data.push({value: 'C', label: 'Current Loan', color: '#ccf4e6'});
    } else {
        data.push({value: 'D', label: 'Deposit & Costs Loan', color: '#ccf4e6'});
        data.push({value: 'C', label: 'Purchase Loan', color: '#ccf4e6'});
    }

    let name = '';
    for (let i=1;i<=loans;i++) {
        name = (isExistingProperty) ? 'Current Loan ' : 'Purchase Loan ';
        name += (i + 1);
        data.push({value: i, label: name, color: '#ccf4e6'});
    }

    return data;
};

export const getRepaymentLoan = (isExistingProperty = true, loans = 0, value = '') => {
    let options = getRepaymentLoanType(isExistingProperty, loans);
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getRepaymentCalculateByOption = [
    {value: '1', label: 'PM', color: '#ccf4e6',},
    {value: '2', label: 'PW', color: '#ccf4e6'},
];

export const getRepaymentCalculateBy = (value = '') => {
    let options = getRepaymentCalculateByOption;
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 3*/
export const getLoanTypeOptions = () => {
    let config = getConfig();
    let data = [];
    data.push({
        value: '',
        label: 'Repayment Type',
        color: '#ccf4e6'
    })
    if (config.loan_type) {
        for (let [key, item] of Object.entries(config.loan_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getLoanType = (value = '') => {
    let options = getLoanTypeOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getDefaultLoanCosts = () => {
    let config = getConfig();
    if (config.default_loan_costs) {
        return config.default_loan_costs;
    }
    return 0;
};

export const getLoanTermOptions = () => {
    let data = [];
    data.push({
        value: '',
        label: 'Term',
        color: '#ccf4e6'
    });
    for (let i = 1; i <= 50; i++) {
        data.push({
            value: i,
            label: i,
            color: '#ccf4e6'
        })
    }
    return data;
};

export const getLoanTerm = (value = '') => {
    let options = getLoanTermOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 4*/
export const getRentOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.rent_duration) {
        for (let [key, item] of Object.entries(config.rent_duration)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }

        let new_data = [];
        let order = config.rent_duration_order;

        for (let i = 0;i<order.length;i++) {
            for (let j = 0;j<data.length;j++) {
                if (parseInt(order[i]) === parseInt(data[j].value)) {
                    new_data.push(data[j]);
                    break;
                }
            }
        }

        data = new_data;
    }
    return data;
};

export const getRent = (value = '') => {
    let options = getRentOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 5*/
export const getDefaultAgentComissionRate = () => {
    let config = getConfig();
    if (config.default_agent_commission) {
        return config.default_agent_commission;
    }
    return 0;
};

/*Tab 6*/
export const getDefaultCapitalGrowth = () => {
    let config = getConfig();
    if (config.default_capital_growth_rate) {
        return config.default_capital_growth_rate;
    }
    return 0;
};

export const getDefaultInflation = () => {
    let config = getConfig();
    if (config.default_inflation) {
        return config.default_inflation;
    }
    return 0;
};

export const getSelectedYearsOption = () => {
    let data = [];
    for (let i = 11; i <= 50; i++) {
        data.push({
            value: i,
            label: 'YR ' + i,
            color: '#ccf4e6'
        })
    }
    return data;
};

export const getSelectedYear = (value = '') => {
    let options = getSelectedYearsOption();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 7*/
export const getDefaultDPLife = () => {
    let config = getConfig();
    if (config.default_dp_lifes) {
        return config.default_dp_lifes;
    }
    return {};
};

export const getDefaultDepreciationOfBuilding = () => {
    let config = getConfig();
    if (config.default_depreciation_of_building) {
        return config.default_depreciation_of_building;
    }
    return 0;
};

export const getDefaultDiminishingValue = () => {
    let config = getConfig();
    if (config.diminishing_value) {
        return config.diminishing_value;
    }
    return 0;
};


export const getDefaultDepreciationMethod = () => {
    let config = getConfig();
    if (config.default_depreciation_methods) {
        return config.default_depreciation_methods;
    }
    return 0;
};

export const getDepreciationMethodOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.depreciation_methods) {
        for (let [key, item] of Object.entries(config.depreciation_methods)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getDepreciationMethod = (value = '') => {
    let options = getDepreciationMethodOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 8*/
export const getITOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.it_options) {
        for (let [key, item] of Object.entries(config.it_options)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getIT = (value = '') => {
    let options = getITOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};


/*Tab 9*/
export const getWhoPayYearOptions = () => {
    let data = [];
    for (let i=1;i<=50;i++) {
        if (data.length === 0) {
            data.push({value: i, label: 'YR' + i, color: '#ccf4e6', isFixed: true});
        } else {
            data.push({value: i, label: 'YR' + i, color: '#ccf4e6'});
        }
    }

    return data;
};

export const getWhoPayYear = (value = '') => {
    let options = getWhoPayYearOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Subscription Page*/
export const getLicenceOptions = (min = 1, max = 1) => {
    let licences = [];
    for (let i=min;i<=max;i++) {
        if (licences.length === 0) {
            licences.push({value: i, label: i, color: '#ccf4e6', isFixed: true});
        } else {
            licences.push({value: i, label: i, color: '#ccf4e6'});
        }
    }

    return licences
}

export const getLicence = (min, max, value = '') => {
    let options = getLicenceOptions(min, max);
    if (options) {
        for (let option of options) {
            if (parseInt(option.value) === parseInt(value)) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Entity Page*/
export const getEntityTypeOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.entity_types) {
        for (let [key, item] of Object.entries(config.entity_types)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getEntityType = (value = '') => {
    let options = getEntityTypeOptions();
    if (options) {
        for (let option of options) {
            if (option.value === value) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getSupAnuPhaseOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.superannuation_phase) {
        for (let [key, item] of Object.entries(config.superannuation_phase)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getSupAnuPhase = (value = '') => {
    let options = getSupAnuPhaseOptions();
    if (options) {
        for (let option of options) {
            if (option.value === value) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getNumberOptions = (min = 1, max = 1) => {
    let licences = [];
    for (let i=min;i<=max;i++) {
        let value = {};
        if (licences.length === 0) {
            value = {value: i, label: i, color: '#ccf4e6', isFixed: true};
        } else {
            value = {value: i, label: i, color: '#ccf4e6'};
        }

        if (parseInt(i) === 1900) {
            value.label = 'Older then 1901';
        }

        licences.push(value);
    }

    return licences
}

export const getNumber = (min, max, value = '') => {
    let options = getNumberOptions(min, max);
    if (options) {
        for (let option of options) {
            if (parseInt(option.value) === parseInt(value)) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};