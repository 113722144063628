import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Progress,
} from 'reactstrap';
import Select from "../../../components/properlytics/select";
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getHouseTypeOptions,
    getHouseType,
    getPropertyUsed,
    getBuyerType,
    getState,
    getPropertyUsedOptions,
    getBuyerTypeOptions,
    getStateOptions,
    getLoanType,
    getPropertyUsedText,
    getEntityId,
    getTypeOfProperty,
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import config from "config/config";
import {
    getNotificationObject, setAcquisitionTabState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import StampDuty from "./dialogs/stamp-duty";
import ExtraRepayment from "../sections/extra-repayment";
import PriceField from "../../../components/numbers/PriceField";
import axios from "../../../services/axios";
import ReactTooltip from 'react-tooltip';
import RefreshProjection from "../../../components/properlytics/refresh-projection";

export default class AcquisitionCosts extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        this.state = {
            is_edit: false,
            property_id: '',
            conveyancing_cost: '',
            house_type: getHouseType().value,
            property_used: getPropertyUsed().value,
            buyer_type: getBuyerType().value,
            stamp_duty: 0,
            state: getState().value,
            transfer_of_title: '',
            other_costs: [],
            furniture_value: '',

            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            interest_term: 5,
            to_year: 30,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            interest_term_back: 5,
            to_year_back: 30,
            loan_type_back: getLoanType().value,
            loans: [],

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog: false,
            redirect_to_dashboard: false,

            buyer_types: getBuyerTypeOptions(),
            property_used_as: getPropertyUsedOptions(),
            house_types: getHouseTypeOptions(),
            calculateSD: false,

            is_existing_property: true,
            refreshLoading: false,
        };

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onHouseTypeChange = this.onHouseTypeChange.bind(this);
        this.onPropertyUsedChange = this.onPropertyUsedChange.bind(this);
        this.onBuyerTypeChange = this.onBuyerTypeChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.calculateSD = this.calculateSD.bind(this);
        this.onOtherCostsChange = this.onOtherCostsChange.bind(this);
        this.addOtherCost = this.addOtherCost.bind(this);
        this.removeOtherCost = this.removeOtherCost.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.hideShowDialog = this.hideShowDialog.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.getOtherCosts = this.getOtherCosts.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.refreshProjection = this.refreshProjection.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    hideShowDialog() {
        if (this.state.property_id === '') {
            this.showNotification('Please first enter property detail', 'danger')
        } else {
            this.setState({
                show_dialog: (!this.state.show_dialog)
            })
            window.scrollTo(0, 0);
        }
    }

    onInputChange(event) {
        if (!isNaN(event.target.value) || event.target.value === '') {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    handleOtherCostOperation = (key, name, value) => {
        let other_costs = this.state.other_costs;

        if (typeof other_costs[key] !== 'undefined') {
            if (name === 'cost_amount') {
                value = (value === '') ? '' : parseInt(value);
                if (typeof other_costs[key] !== 'undefined') {
                    other_costs[key].cost_amount = value;
                }
            }
            if (name === 'cost_name') {
                other_costs[key].cost_name = value;
            }
        }

        this.setState({
            other_costs: other_costs
        })
    }

    onOtherCostsChange = (key, name, event) => {
        if (name === 'cost_amount') {
            if (!isNaN(event.target.value) || event.target.value === '') {
                this.handleOtherCostOperation(key, name, event.target.value)
            }
        } else {
            this.handleOtherCostOperation(key, name, event.target.value)
        }
    }

    addOtherCost = () => {
        let other_costs = this.state.other_costs;

        let length = other_costs.length;
        other_costs[length] = {
            cost_name: '',
            cost_amount: '',
        }

        this.setState({
            other_costs: other_costs
        })
    }

    removeOtherCost = (key) => {
        let other_costs = this.state.other_costs;

        if (typeof other_costs[key] !== 'undefined') {
            other_costs.splice(key, 1);
        }

        this.setState({
            other_costs: other_costs
        })
    }

    onBuyerTypeChange (selected) {
        let property_used = this.state.property_used;
        if (selected.value.toString() === '2') {
            property_used = 2;
        }
        this.setState({
            buyer_type: selected.value,
            property_used: property_used,
        });
        this.calculateSD(this.state.property_id, this.state.state, this.state.house_type, property_used, selected.value, this.state.is_edit);
    }

    onHouseTypeChange(selected) {
        this.setState({
            house_type: selected.value,
        });
        this.calculateSD(this.state.property_id, this.state.state, selected.value, this.state.property_used, this.state.buyer_type, this.state.is_edit);
    }

    onPropertyUsedChange(selected) {
        this.setState({
            property_used: selected.value,
        });
        this.calculateSD(this.state.property_id, this.state.state, this.state.house_type, selected.value, this.state.buyer_type, this.state.is_edit);
    }

    onStateChange (selected) {
        this.setState({
            state: selected.value,
        });
        this.calculateSD(this.state.property_id, selected.value, this.state.house_type, this.state.property_used, this.state.buyer_type, this.state.is_edit);
    }

    calculateSD(property_id, state, house_type, property_used, buyer_type, call_api) {
        if (call_api) {
            if (this.state.calculateSD) {
                let postData = new FormData();
                postData.append('property_id', property_id);
                postData.append('state', state);
                postData.append('house_type', house_type);
                postData.append('property_used', property_used);
                postData.append('buyer_type', buyer_type);
                axios.post(config.cal_stamp_duty, postData).then(res => {
                    if (res.success) {
                        this.setState({
                            stamp_duty: res.data.stamp_duty,
                        })
                    }
                });
            } else {
                this.setState({
                    stamp_duty: 0,
                })
            }
        }
    }

    getOtherCosts(name) {
        let other_costs = this.state.other_costs;

        let data = [];
        for (let i=0;i<other_costs.length;i++) {
            if (name === 'cost_name') {
                data.push(other_costs[i].cost_name);
            }
            if (name === 'cost_amount') {
                data.push(other_costs[i].cost_amount);
            }
        }

        return data;
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    refreshProjection() {
        this.saveData(true, true);
    }

    saveData(isNext = true, fromRefresh = false) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        if (fromRefresh) {
            loadingState = 'refreshLoading';
        }

        this.setState({
            [loadingState]: true
        });

        let conveyancing_cost = 0;
        if (parseInt(getTypeOfProperty()) === 2) {
            conveyancing_cost = (this.state.conveyancing_cost) ? this.state.conveyancing_cost : 0;
        }

        let postData = {
            property_id: this.state.property_id,
            conveyancing_cost: conveyancing_cost,
            house_type: this.state.house_type,
            property_used: this.state.property_used,
            buyer_type: this.state.buyer_type,
            stamp_duty: this.state.stamp_duty,
            state: this.state.state,
            transfer_of_title: this.state.transfer_of_title,
            other_costs: this.state.other_costs,
            has_furniture: 1,
            furniture_value: (this.state.furniture_value) ? this.state.furniture_value : 0,
        };

        axios.post(config.add_acquisition_costs, postData).then(res => {
            if (res.success) {
                if (fromRefresh) {
                    this.setState({
                        overview: res.data.property.overview,
                        property: res.data.property
                    });
                } else {
                    this.showNotification(res.message, 'success');
                    this.setState({
                        [goToState]: true,
                        property: res.data.property
                    })
                }
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setAcquisitionTabState(property);
        this.setState({
            is_edit: true,
        });
        this.setState(stateData);
        this.calculateSD(stateData.property_id, stateData.state, stateData.house_type, stateData.property_used, stateData.buyer_type, true);

        this.setState({
            buyer_types: getBuyerTypeOptions(),
            property_used_as: getPropertyUsedOptions(),
            house_types: getHouseTypeOptions(),
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.acquisition_cost + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    axios.get(config.get_property + property_id).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let client_id = (getClientId()) || '';
            let entity_id = (getEntityId()) || '';
            let type_of_property = (getTypeOfProperty()) || '';
            if (client_id === '' || entity_id === '' || type_of_property === '') {
                this.setState({
                    redirect_to_dashboard: true,
                })
            } else {
                if (parseInt(type_of_property) === 2) {
                    this.setState({
                        calculateSD: true
                    })
                }
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.funding_structure + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.property_detail + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <ReactTooltip textColor='#000000' backgroundColor='#ffffff' border borderColor='#cccccc' effect='solid'/>

            <Row>
                <Col xl="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className={(this.state.show_dialog) ? "plisting_form rect_bx flip_bx go" : "plisting_form rect_bx flip_bx"}>
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green items-center d-flex">Acquisition Details</h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>

                                    <RefreshProjection
                                        show={this.state.is_edit}
                                        loading={this.state.refreshLoading}
                                        onClick={this.refreshProjection}
                                    />
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">
                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Conveyancing Costs</h4>
                                                <PriceField
                                                    placeholder={parseInt(getTypeOfProperty()) === 1 ? '$0' : "Enter Conveyancing Costs"}
                                                    name="conveyancing_cost"
                                                    value={this.state.conveyancing_cost}
                                                    onChange={this.onInputChange}
                                                    readOnly={parseInt(getTypeOfProperty()) === 1}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pbtm0 ptop0">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w d-flex">
                                                    Buyer Type
                                                    
                                                    <p data-tip="If you are renting out the property, choose the Investor." className="tooltip-pop">
                                                        <i className="fa fa-info-circle" />
                                                    </p>
                                                </h4>

                                                <div className="slct d-flex">
                                                    <div className="p_type_opt">
                                                        <Select
                                                            defaultValue={getBuyerType()}
                                                            options={this.state.buyer_types}
                                                            onChange={this.onBuyerTypeChange}
                                                            value={getBuyerType(this.state.buyer_type)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={(parseInt(this.state.buyer_type) === 2) ? "hide" : "tp_row pbtm0 ptop0"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Property Used As</h4>

                                                <div className="slct d-flex">
                                                    <div className="p_type_opt">
                                                        <Select
                                                            defaultValue={getPropertyUsed()}
                                                            options={this.state.property_used_as}
                                                            onChange={this.onPropertyUsedChange}
                                                            value={getPropertyUsed(this.state.property_used)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={(parseInt(this.state.buyer_type) === 2) ? "tp_row" : "hide"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Property Used As</h4>
                                                <Input
                                                    type="text"
                                                    className="form-control prop-used-as"
                                                    placeholder="Property Used As"
                                                    value={getPropertyUsedText(this.state.property_used)}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pbtm0 ptop0">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">House Type</h4>

                                                <div className="slct d-flex">
                                                    <div className="p_type_opt">
                                                        <Select
                                                            defaultValue={getHouseType()}
                                                            options={this.state.house_types}
                                                            onChange={this.onHouseTypeChange}
                                                            value={getHouseType(this.state.house_type)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={this.state.calculateSD ? "tp_row" : "hide"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">
                                                    Stamp Duty
                                                    <span className="flipbtn t_black rotate" onClick={this.hideShowDialog}>
                                                        <i className="icon-arrow-up-circle"/>
                                                    </span>
                                                </h4>

                                                <div className="d-flex justify-content-between w100">
                                                    <PriceField
                                                        placeholder="0"
                                                        name="stamp_duty"
                                                        value={this.state.stamp_duty}
                                                        readOnly={true}
                                                    />

                                                    <div className="loc_type_opt select-value">
                                                        <Select
                                                            defaultValue={getState()}
                                                            options={getStateOptions()}
                                                            onChange={this.onStateChange}
                                                            value={getState(this.state.state)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Transfer Of Title</h4>
                                                <PriceField
                                                    placeholder="Enter Transfer Of Title"
                                                    name="transfer_of_title"
                                                    value={this.state.transfer_of_title}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>*/}

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit d-flex items-center fx_w">
                                                    Other Costs

                                                    <p data-tip="This may include building and pest inspection, buyers agent fees, etc..." className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                                </h4>
                                                

                                                <div className="d-flex w100 height38 justify-content-end">
                                                    <span className="txt-gray act_icon">
                                                        <i className="f20 icon-plus" onClick={this.addOtherCost}/>
                                                    </span>
                                                </div>
                                            </div>

                                            <MapOtherCosts
                                                other_costs={this.state.other_costs}
                                                onInputChange={this.onOtherCostsChange}
                                                removeOtherCost={this.removeOtherCost}
                                            />
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Furniture</h4>
                                                <PriceField
                                                    placeholder="Enter Furniture Value"
                                                    name="furniture_value"
                                                    value={this.state.furniture_value}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious} loading={this.state.loading_previous}/>

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>


                            <StampDuty
                                show_dialog={this.state.show_dialog}
                                property_id={this.state.property_id}
                                state={this.state.state}
                                hideShowDialog={this.hideShowDialog}
                                showNotification={this.showNotification}
                                onStateChange={this.onStateChange}
                            />

                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col xl={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        interest_term={this.state.interest_term}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        interest_term_back={this.state.interest_term_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                        loans={this.state.loans}
                        is_existing_property={this.state.is_existing_property}
                    />
                </Col>
            </Row>
        </div>

    }
}

const MapOtherCosts = ({other_costs, onInputChange, removeOtherCost}) => {
    if(other_costs.length > 0){
        return other_costs.map( (item, key) => {
            return (
                <div className="hd_row d-flex inner_row" key={key}>
                    <h4 className="sm_tit fx_w">
                    </h4>

                    <div className="d-flex w100 height38 brd-top justify-content-between">
                        <h4 className="sm_tit fx_w">
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Name of cost"
                                name="cost_name[]"
                                value={item.cost_name}
                                onChange={onInputChange.bind(this, key, 'cost_name')}
                            />
                        </h4>

                        <span className="d-flex act_icon height60">
                            <span className="value t_black pmedium">
                                <PriceField
                                    placeholder="Cost Amount"
                                    name="cost_amount[]"
                                    value={item.cost_amount}
                                    onChange={onInputChange.bind(this, key, 'cost_amount')}
                                />
                            </span>
                            <i
                                className="f20 txt-gray icon-close"
                                onClick={removeOtherCost.bind(this, key)}
                            />
                        </span>
                    </div>
                </div>
            )
        })
    }
    return null;
}