import React from 'react';
import config from 'config/config';
import {Redirect} from 'react-router';
import {
    setClientId,
    setClientName,
    setEntityId,
    setTypeOfProperty,
    setEntityType,
    setEntityName
} from "../../services/config-helper";

export default class RecentProperty extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        };

        this.openProperty = this.openProperty.bind(this);
    }

    openProperty() {
        let {property} = this.props;
        setClientId(property.client_id);
        setClientName(property.client_name);
        setEntityId(property.entity_id);
        setEntityType(property.entity_type);
        setEntityName(property.entity_name);
        setTypeOfProperty(property.type);
        this.setState({
            redirect: true
        })
    }

	render() {
        let {property} = this.props;

        if (this.state.redirect) {
            return <Redirect
                to={{pathname: config.site_url.property_detail + '/' + property.property_id}}
            />;
        }

        if (typeof property.property_id === 'undefined') {
            return (
                <div className="top_part">
                    <h4 className="t_green fnormal pmedium f16">Recent Property</h4>

                    <div className="d-flex top_dtl">
                        <h6 className="pmedium t_black pmedium mbtm0" style={{height: '110px'}}>Not Available</h6>
                    </div>
                </div>
            );
        }

		return (
            <div className="top_part">
                <h4 className="t_green fnormal pmedium f16">Recent Property</h4>

                <div className="d-flex top_dtl">
                    <img src={property.image} className="prpt_img" alt="Property_Image" />

                    <div className="top_mdl">
                        <div className="d-flex">
                            <h6 className="pmedium t_black pmedium mbtm0">{property.entity_name}</h6>
                            <p className="prop_add mbtm0">{property.address}</p>
                        </div>

                        <ul className="list-inline mbtm0 pro_faci">
                            <li>
                                Bed  <span className="no">{property.bedrooms}</span>
                            </li>
                            <li>
                                Bath  <span className="no">{property.bathrooms}</span>
                            </li>
                            <li>
                                Car  <span className="no">{property.car_space}</span>
                            </li>
                        </ul>
                    </div>

                    <a href="javascript:void(0)" onClick={this.openProperty.bind(this)}>
                        <i className="icon-arrow-right" />
                    </a>
                </div>
            </div>
		);
	}
}