import React from 'react';
import {
    Button,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import PropertyList from "../portfolio/property-list";
import axios from "../../services/axios";
import config from "config/config";
import {Redirect} from 'react-router';
import {downloadReport} from "../../services/helper";
import {confirmAlert} from 'react-confirm-alert';
import {setEntityId, setTypeOfProperty, setEntityType, setEntityName} from "../../services/config-helper";

export default class EntityMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            download_report_loading: []
        };

        this.openClosePropertyList = this.openClosePropertyList.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
        this.addProperty = this.addProperty.bind(this);
        this.preventDefault = this.preventDefault.bind(this);
        this.generateReport = this.generateReport.bind(this);
    }

    generateReport(entity_id) {
        let download_report_loading = this.state.download_report_loading
        download_report_loading[entity_id] = true
        this.setState({
            download_report_loading: download_report_loading
        })
        let postData = {
            entity_id: entity_id,
            type: 'stream'
        };

        axios.post(config.export_portfolio_report, postData).then(res => {
            if (res.success) {
                let fileName = 'Porfolio.pdf';
                downloadReport(res.data, fileName);
            } else {
                this.props.showNotification(res.message, 'danger');
            }
            download_report_loading[entity_id] = false
            this.setState({
                download_report_loading: download_report_loading
            })
        });
    }

    preventDefault(event) {
        event.preventDefault();
    }

    addProperty(entity_id, entity_type, name, type) {
        setEntityId(entity_id);
        setEntityType(entity_type);
        setEntityName(name);
        setTypeOfProperty(type);
        this.setState({
            redirect: true,
        })
    }

    openClosePropertyList(entity) {
        if (!entity.open_property_list && !isNaN(entity.entity_id)) {
            this.props.setData('properties_loading', {entity_id: entity.entity_id, loading: true});
            axios.get(config.get_properties + entity.entity_id).then(res => {
                if (res.success) {
                    this.props.setData('get_properties', {
                        entity_id: entity.entity_id,
                        properties: res.data.property_list
                    });
                } else {
                    this.props.showNotification(res.message, 'danger');
                }
                this.props.setData('properties_loading', {entity_id: entity.entity_id, loading: false});
            });

            this.props.getPortfolioChart(entity.entity_id);
        }

        this.props.setData('open_property_list', {entity_id: entity.entity_id});
    }

    deleteConfirm = (property) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this property?',
            buttons: [
                {
                    label: 'Cancel',
                },
                {
                    label: 'Delete',
                    onClick: () => this.deleteProperty(property)
                }
            ]
        }, property);
    }

    deleteProperty(property) {
        let postData = {
            entity_id: property.entity_id
        };

        this.props.setData('delete_property', {entity_id: property.entity_id, property_id: property.property_id});

        axios.post(config.delete_property + property.property_id, postData).then(res => {
            if (res.success) {
                this.props.setData('get_properties', {
                    entity_id: property.entity_id,
                    properties: res.data.property_list
                });
                this.props.showNotification(res.message, 'success');
            } else {
                this.props.showNotification(res.message, 'danger');
            }
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect
                to={{pathname: config.site_url.property_detail}}
            />;
        }

        let {entities, showNotification, showHidePopup, setData, getPortfolioChart} = this.props;

        if (!entities || entities.length === 0) {
            return '';
        }

        const { download_report_loading } = this.state

        return entities.map((entity, key) => {
            return (
                <div className="entity-row pointer-cursor api-call" key={key} onClick={getPortfolioChart.bind(this, entity.entity_id)}>
                    <div className="d-flex align-items-center justify-content-between api-call">
                        <h5 className="entity-name mbtm0 api-call">{entity.name}</h5>

                        <div className="right-aside  d-flex  align-items-center justify-content-between">
                            <Button
                                style={{ marginRight: '10px'}}
                                className="block-btn bg_green"
                                onClick={this.generateReport.bind(this, entity.entity_id)}
                            >
                                Portfolio Report
                                <div className={(typeof download_report_loading[entity.entity_id] !== 'undefined' && download_report_loading[entity.entity_id]) ? 'btn-loading portfolio-download right' : 'hide'}>
                                    <div className="btn-loader" />
                                </div>
                            </Button>

                            <UncontrolledDropdown  inNavbar className="add-props">
                                <DropdownToggle nav className="outline-btn bg_lgreen">
                                    Add Property
                                    <i className="icon-arrow-down icon f10" />
                                </DropdownToggle>

                                <DropdownMenu right className="pad0 user-dd duplicate-property">
                                    <Nav>
                                        <NavItem>
                                            <a className="nav-link" href="javascript:void(0)" onClick={this.addProperty.bind(this, entity.entity_id, entity.entity_type, entity.name, 1)} >
                                                Add Existing Property
                                            </a>
                                        </NavItem>

                                        <NavItem>
                                            <a className="nav-link" href="javascript:void(0)" onClick={this.addProperty.bind(this, entity.entity_id, entity.entity_type, entity.name, 2)} >
                                                Add Future Property
                                            </a>
                                        </NavItem>

                                        <NavItem className={(entity.property_count > 0) ? '' : 'hide'}>
                                            <a className="nav-link" href="javascript:void(0)" onClick={showHidePopup.bind(this, entity.entity_id)} >
                                                Duplicate Property
                                            </a>
                                        </NavItem>
                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <span className="row-arrow" onClick={this.openClosePropertyList.bind(this, entity)}>
                                <i className={(entity.open_properties) ? "icon-arrow-up" : "icon-arrow-down"} />
                            </span>
                        </div>
                    </div>

                    <PropertyList
                        display={entity.open_property_list}
                        list_loading={entity.properties_loading}
                        properties={entity.properties}
                        deleteConfirm={this.deleteConfirm}
                        showNotification={showNotification}
                        setData={setData}
                    />
                </div>
            )
        });
    }
}