import config from '../config/config';
const axios = require('axios');

let axiosInstance = axios.create({
    baseURL: config.base_url,
});

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
    function(res) {
        if (res.status === 200 || res.status === 204) {
            return res.data;
        } else {
            return {
                success: false,
                message: res.data.message || res.statusText,
                error: null
            };
        }
    },
    function(error) {
        let msg = '';
        if (error.response) {
            if (error.response.status === 401) {
                window.localStorage.clear();
            }

            msg = error.response.data;
        } else {
            msg = error.message;
        }
        return {
            success: false,
            message: msg,
            error: null
        };
    }
);

export default axiosInstance;