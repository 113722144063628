import React from 'react';
import {
    Form,
    Input,
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import LeftButton from "components/properlytics/left-button";
import {getITOptions} from "../../../../services/config-helper";
import MiddleButton from "../../../../components/properlytics/middle-button";
import PriceField from "../../../../components/numbers/PriceField";
import PercentageField from "../../../../components/numbers/PercentageField";
import axios from "../../../../services/axios";
import config from "config/config";

export default class TaxScales extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tax_scales: {},
            ml_threshold: 0,
            ml_rate: 0,
        }
        this.changeTaxScales = this.changeTaxScales.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onITScaleChange = this.onITScaleChange.bind(this);
        this.onInputOtherChange = this.onInputOtherChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    changeTaxScales(key,name,value) {
        let tax_scales = this.state.tax_scales;

        if (typeof tax_scales.slab !== 'undefined') {
            if (typeof tax_scales.slab[key] !== 'undefined') {
                if (name === 'to') {
                    value = (value === '') ? '' : parseInt(value);
                    if (typeof tax_scales.slab[key] !== 'undefined') {
                        tax_scales.slab[key].to = value;
                        if (typeof tax_scales.slab[key + 1] !== 'undefined') {
                            if (value) {
                                tax_scales.slab[key + 1].from = value + 1;
                            } else {
                                tax_scales.slab[key + 1].from = '';
                            }

                            if ((value + 1) > tax_scales.slab[key + 1].to) {
                                for (let i = (key + 1);i<tax_scales.slab.length;i++) {

                                    if (i === (key+ 1)) {
                                        tax_scales.slab[i].to = '';
                                    } else {
                                        tax_scales.slab[i].from = '';
                                        tax_scales.slab[i].to = '';
                                    }
                                }
                            }
                        } else {
                            if (tax_scales.slab[key].to.toString() !== '') {
                                tax_scales.slab[key + 1] = {
                                    from: value + 1,
                                    to: '',
                                    rate: 0,
                                }
                            }
                        }
                    }
                }
                if (name === 'rate') {
                    value = (value === '') ? 0.00 : value;
                    if (typeof tax_scales.slab[key] !== 'undefined') {
                        tax_scales.slab[key].rate = value;
                    }
                }
            }
        }

        this.setState({
            tax_scales: tax_scales
        })
    }

    getTaxScalesFormData(name) {
        let tax_scales = this.state.tax_scales;

        let data = [];
        if (typeof tax_scales.slab !== 'undefined') {
            for (let i=0;i<tax_scales.slab.length;i++) {
                if (name === 'from') {
                    data.push(tax_scales.slab[i].from);
                }
                if (name === 'to') {
                    data.push(tax_scales.slab[i].to);
                }
                if (name === 'rate') {
                    data.push(tax_scales.slab[i].rate);
                }
            }
        }

        if (name === 'ml_threshold') {
            data = (typeof tax_scales.ml_threshold !== 'undefined') ? tax_scales.ml_threshold : 0;
        }

        if (name === 'ml_rate') {
            data = (typeof tax_scales.ml_rate !== 'undefined') ? tax_scales.ml_rate : 0;
        }

        return data;
    }

    onInputChange = (key, name, event) => {
        if (!isNaN(event.target.value) || event.target.value === '') {
            this.changeTaxScales(key, name, event.target.value)
        }
    }

    onInputOtherChange = (event) => {
        if (!isNaN(event.target.value) || event.target.value === '') {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onITScaleChange (value) {
        this.props.changeITType(value);
        this.changeTaxScales(value);
    }

    onSave() {
        if (this.props.property_id !== '') {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            postData.append('property_id', this.props.property_id);
            postData.append('option', this.props.it_type);
            let from = this.getTaxScalesFormData('from');
            for (let i = 0; i < from.length; i++) {
                postData.append('from[]', from[i]);
            }
            let to = this.getTaxScalesFormData('to');
            for (let i = 0; i < to.length; i++) {
                postData.append('to[]', to[i]);
            }
            let rate = this.getTaxScalesFormData('rate');
            for (let i = 0; i < rate.length; i++) {
                postData.append('rate[]', rate[i]);
            }
            postData.append('ml_threshold', (this.state.ml_threshold) ? this.state.ml_threshold : 0);
            postData.append('ml_rate', (this.state.ml_rate) ? this.state.ml_rate : 0);

            axios.post(config.add_income_scales, postData).then(res => {
                if (res.success) {
                    this.props.showNotification(res.message, 'success')
                    this.props.hideShowDialog3();
                    this.props.changeTaxCredit();
                } else {
                    this.props.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            this.props.showNotification('Please first enter property detail', 'danger')
        }
    }

    getTaxScales(it_type) {
        if (this.props.property_id !== '') {
            let postData = {
                property_id: this.props.property_id,
                option: it_type
            };

            axios.post(config.get_income_scales, postData).then(res => {
                if (res.success) {
                    this.setState({
                        tax_scales: res.data.income_tax_slab,
                        ml_threshold: res.data.income_tax_slab.ml_threshold,
                        ml_rate: res.data.income_tax_slab.ml_rate,
                    })
                } else {
                    this.props.showNotification(res.message, 'danger')
                    this.props.hideShowDialog3();
                }
            });
        }
    }

    componentDidMount() {
        this.getTaxScales(this.props.it_type);
    }

    componentDidUpdate(props) {
        const { it_type, show_dialog3, show_dialog4 } = this.props;
        if (props.it_type !== it_type) {
            if (this.props.it_type) {
                this.getTaxScales(this.props.it_type);
            }
        }

        if (props.show_dialog3 !== show_dialog3) {
            if (this.props.show_dialog3) {
                this.getTaxScales(this.props.it_type);
            }
        }

        if (props.show_dialog4 !== show_dialog4) {
            if (this.props.show_dialog4) {
                this.getTaxScales(this.props.it_type);
            }
        }
    }

    render() {
        const {it_type} = this.props;

        let i = 0;
        let it_list = getITOptions().map((item) => {
            i++;
            if (i === 1) {
                return (
                    <span
                        onClick={this.onITScaleChange.bind(this,item.value)}
                        className={(it_type.toString() === item.value.toString()) ? 'active ml10' : 'ml10'}
                    >
                        <div className="lpad0">
                            {item.label}
                        </div>
                </span>
                );
            } else if (i === 2) {
                return (
                    <span
                        onClick={this.onITScaleChange.bind(this,item.value)}
                        className={(it_type.toString() === item.value.toString()) ? 'super-annua active' : 'super-annua'}
                    >
                        <div className="lpad0">
                            {item.label}
                        </div>
                </span>
                );
            } else {
                return (
                    <span
                        onClick={this.onITScaleChange.bind(this,item.value)}
                        className={(it_type.toString() === item.value.toString()) ? 'text-right active' : 'text-right '}
                    >
                        <div className="text-right rpad0">
                            {item.label}
                        </div>
                </span>
                );
            }
        }, it_type);

        let classnames = 'grd_bx popup-wrap flip_bx1 tax_scales';
        if (this.props.show_dialog3) {
            if (!this.props.show_dialog4) {
                classnames += ' go';
            }
        }


        return <div className={classnames}>
            <div className="rect_bx flip_bx1">
                <div className="head_tron d-flex">
                    <span className="flipbtn t_black rotate">
                        <i className="icon-arrow-up-circle"/>
                    </span>
                    <h2 className="head pl-2 t_black">Tax Scales</h2>
                </div>

                <div className="inner_bx">
                    <Form>

                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                <div className="sm_tit fx_w">Region</div>

                                <div className="d-flex w100 loc_tab justify-content-between">
                                    {it_list}
                                </div>
                            </div>

                            <div className="hd_row d-flex justify-content-between inner_row">
                                <div className="sm_tit fx_w"/>
                                <div className="d-flex w100 height60 pr-40 justify-content-between brd-top">
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control lpad0"
                                            value="From"
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control lpad0"
                                            value="To"
                                        />
                                    </span>
                                    <span className="text-right">
                                        <Input
                                            type="text"
                                            className="form-control text-right rpad0"
                                            value="Rate"
                                        />
                                    </span>
                                </div>
                            </div>

                            <MapForm
                                onInputChange={this.onInputChange}
                                tax_scales={this.state.tax_scales}
                            />
                        </div>

                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                <div className="sm_tit fx_w">Medicare Levy</div>

                                <div className="d-flex w100 loc_tab justify-content-between">
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control padl10"
                                            value="Threshold"
                                        />
                                    </span>
                                    <span className="text-right">
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="ml_threshold"
                                            value={ this.state.ml_threshold }
                                            onChange={this.onInputOtherChange}
                                        />
                                    </span>
                                </div>
                            </div>

                            <div className="hd_row d-flex justify-content-between inner_row">
                                <div className="sm_tit fx_w"/>
                                <div className="d-flex w100 height60 pr-40 justify-content-between brd-top">
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control lpad0"
                                            value="Rate"
                                        />
                                    </span>
                                    <span className="text-right">
                                        <PercentageField
                                            className="form-control text-right rpad0"
                                            placeholder="0%"
                                            name="ml_rate"
                                            value={ this.state.ml_rate }
                                            onChange={this.onInputOtherChange}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row_btm d-flex justify-content-between">
                            <LeftButton text="Close" onClick={this.props.hideShowDialog3} />

                            <MiddleButton text="Low Tax Offset" onClick={this.props.hideShowDialog4} />

                            <RightButton text="Save" onClick={this.onSave} loading={this.state.loading} />
                        </div>
                    </Form>
                </div>
            </div>
        </div>

    }
}

const MapForm = ({tax_scales, onInputChange}) => {
    if((typeof tax_scales.slab !== 'undefined') && tax_scales.slab.length > 0){
        return tax_scales.slab.map( (item, key) => {
            return (
                <div className="hd_row d-flex justify-content-between inner_row">
                    <div className="sm_tit fx_w"/>
                    <div className="d-flex w100 height60 pr-40 justify-content-between brd-top">
                        <span>
                            <PriceField
                                className="form-control lpad0"
                                placeholder="Enter From"
                                name="from[]"
                                value={item.from}
                                readOnly={true}
                            />
                        </span>
                        <span>
                            <PriceField
                                className="form-control lpad0"
                                placeholder="Enter To"
                                name="to[]"
                                value={item.to}
                                onChange={onInputChange.bind(this, key, 'to')}
                            />
                        </span>
                        <span className="text-right">
                            <PercentageField
                                className="form-control text-right rpad0"
                                placeholder="Enter Rate"
                                name="rate[]"
                                value={item.rate}
                                onChange={onInputChange.bind(this, key, 'rate')}
                            />
                        </span>
                    </div>
                </div>
            )
        })
    }
    return null;
}

