import React from 'react';
import {
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import {Redirect} from 'react-router';
import config from 'config/config';
import {getMyProfile} from "../../services/helper";
import axios from "../../services/axios";

export default class ProfileMenu extends React.Component {

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.state = {
            logout: false,
            initials: '',
            name: '',
            profile_pic: '',
            display_profile_pic: false,
            can_see_subscription: false,
            is_super_admin: false,
            can_display_list: false
        };
    }

    logout() {
        axios.post(config.logout).then(res => {
            if (res.success) {
                window.localStorage.clear();
                this.setState({logout: true});
            }
        });
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                name: profile.name,
                initials: profile.initials,
                picture: profile.picture,
                //display_picture: (profile.picture !== ''),
                display_picture: false,
                can_see_subscription: profile.is_main_user,
                can_display_list: (profile.email.toString() === 'mahetabalkrushna@gmail.com')
            });
        }
    }

	render() {

        if (this.state.logout) {
            return <Redirect to={config.site_url.login} />;
        }

		return (
            <Nav className="ml-auto float-right" navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="profile">
                        <span>{this.state.name}</span>
                        <span className={(this.state.display_picture ? 'hide' : 'letter-badge')}>
                            {this.state.initials}
                        </span>
                        <span className={(this.state.display_picture ? 'avtar' : 'hide')}>
                            <img src={this.state.picture} alt={this.state.initials} />
                        </span>

                        <i className="fa fa-caret-down txt-green" />
                    </DropdownToggle>
                    <DropdownMenu right className="user-dd">
                        <span className="with-arrow">
                            <span className=""/>
                        </span>


                        <Nav>
                            <NavItem>
                                <NavLink href={config.hash + config.site_url.account}>
                                    Account
                                </NavLink>
                            </NavItem>

                            {this.state.can_see_subscription &&
                            <NavItem>
                                <NavLink href={config.hash + config.site_url.subscription}>
                                    Subscription
                                </NavLink>
                            </NavItem>
                            }

                            <NavItem>
                                <NavLink target="_blank" href={config.support_url}>
                                    Support
                                </NavLink>
                            </NavItem>

                            {this.state.is_super_admin &&
                            <NavItem>
                                <NavLink href={config.hash + config.site_url.admin_dashboard}>
                                    Super Admin
                                </NavLink>
                            </NavItem>
                            }

                            {(this.state.is_super_admin && this.state.can_display_list) &&
                            <NavItem>
                                <NavLink href={config.hash + config.site_url.admin_users}>
                                    User List
                                </NavLink>
                            </NavItem>
                            }


                            <NavItem>
                                <NavLink onClick={this.logout}>
                                    Logout
                                </NavLink>
                            </NavItem>
                        </Nav>

                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
		);
	}
}