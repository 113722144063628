import React from 'react';
import {
    Row,
    Col,
    Table,
    Label,
    Input,
} from 'reactstrap';
import {
    formatMoney
} from '../../services/helper';

export default class Propertydetail extends React.Component {

    render() {
        let {property, loading, display} = this.props;

        if (!display) {
            return '';
        }

        if (display && loading) {
            return <div>
                <div className="table-loader show" />
            </div>
        }

        let property_value = 0;
        let equity = 0;
        let loan_amount = 0;
        let lvr = 0;
        let pre_cash_flow = 0;
        let post_cash_flow = 0;
        let ownership_type = 'Single';
        let ownership_percentage = '100%';
        let interest_rate = 0;
        let is_existing_property = true;
        let year_build = '';
        let year_purchase = '';

        if (typeof property.property_detail.type_of_property !== 'undefined' && parseInt(property.property_detail.type_of_property) == 2) {
            is_existing_property = false;
            year_build = property.property_detail.year_build;
            year_purchase = property.property_detail.year_purchase;
        }

        if (typeof property.overview.property_value.yr0 !== 'undefined') {
            property_value = property.overview.property_value.yr0;
        }
        if (typeof property.overview.equity.yr0 !== 'undefined') {
            equity = property.overview.equity.yr0;
        }
        if (typeof property.overview.loan_amount.yr0 !== 'undefined') {
            loan_amount = property.overview.loan_amount.yr0;
        }
        if (typeof property.overview.lvr.yr0 !== 'undefined') {
            lvr = property.overview.lvr.yr0;
        }
        if (typeof property.overview.pre_tax_cash_flow.yr0 !== 'undefined') {
            pre_cash_flow = property.overview.pre_tax_cash_flow.yr0;
        }
        if (typeof property.overview.after_tax_cash_flow.yr0 !== 'undefined') {
            post_cash_flow = property.overview.after_tax_cash_flow.yr0;
        }
        if (typeof property.income.investor !== 'undefined') {
            ownership_percentage = property.income.investor + '%';
        }
        if (typeof property.income.is_joint !== 'undefined' && property.income.is_joint) {
            ownership_percentage += '/' + property.income.partner + '%';
            ownership_type = 'Joint';
        }
        ownership_percentage = '(' + ownership_percentage + ')';

        if (typeof property.overview.interest_rate !== 'undefined') {
            interest_rate = property.overview.interest_rate;
        }
      
        return <div className="props-detail">
                <div>
                    <Row>
                        <Col md="12">
                            <div className="table-wrap">
                                <Table responsive className="table mbtm0 custmize">
                                    <thead>
                                        <tr>
                                            <th>Property Value</th>
                                            <th>Current Equity</th>
                                            <th>Loan Amount</th>
                                            <th>LVR</th>
                                            <th>Interest (average)</th>
                                            <th>{is_existing_property ? 'Pre-Tax Cashflow' : 'Year of Build'}</th>
                                            <th>{is_existing_property ? 'Post-Tax Cashflow' : 'Year of Purchase'}</th>
                                            <th>Ownership % </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Label>
                                                  <Input type="radio" name="radio1" /> ${formatMoney(property_value)}
                                                </Label>

                                                <span><i className="fa fa-pencil-alt icon" /></span>
                                            </td>

                                            <td>
                                                ${formatMoney(equity)}
                                            </td>

                                            <td>
                                                ${formatMoney(loan_amount)}
                                            </td>

                                            <td>{lvr}%</td>

                                            <td>{interest_rate}%</td>

                                            <td>{ is_existing_property ? '$' + formatMoney(pre_cash_flow) : year_build }</td>

                                            <td>{ is_existing_property ? '$' + formatMoney(post_cash_flow) : year_purchase }</td>
                                            
                                            <td>
                                                {ownership_type + ownership_percentage} <span><i className="fa fa-pencil-alt icon" /></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
    }
}

