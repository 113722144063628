import React from 'react';
import {
    Input,
    Button,
    Form,
    CustomInput,
} from 'reactstrap';
import config from "config/config";
import Select from "../../components/properlytics/select";
import {
    getEntityType,
    getEntityTypeOptions,
    getLicenceOptions,
    getLicence,
    getSupAnuPhase,
    getSupAnuPhaseOptions,
} from "../../services/config-helper";
import axios from "../../services/axios";
import PriceField from "../../components/numbers/PriceField";
import PercentageField from "../../components/numbers/PercentageField";
import moment from "moment/moment";

export default class AddEntity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            block_loading: false,

            entity_id: '',
            // entity_type: getEntityType().value,
            name: '',
            taxable_income: 0,
            income_growth_projection: 0,
            tax_info: {},

            tax_rate: 0,
            sup_anu_phase: '',
            expected_retire_year: '',

            start_year: moment().format('YYYY'),
            end_year: moment().add(50,'years').format('YYYY'),

            change_entity_id: '',
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.addEdit = this.addEdit.bind(this);
        this.supperannuationChange = this.supperannuationChange.bind(this);
        this.yearChange = this.yearChange.bind(this);
        this.changeEntity = this.changeEntity.bind(this);
        this.selectEntity = this.selectEntity.bind(this);
    }

    onInputChange(event) {
        let name = event.target.name;
        if (name === 'taxable_income' || name === 'income_growth_projection' || name === 'tax_rate') {
            if (!isNaN(event.target.value) || event.target.value === '') {
                if (name === 'income_growth_projection' || name === 'tax_rate') {
                    if (event.target.value >= 0 && event.target.value <= 100) {
                        this.setState({
                            [name]: event.target.value
                        });
                    }
                } else {
                    this.setState({
                        [name]: event.target.value
                    });
                }
            }
        } else {
            this.setState({
                [name]: event.target.value
            });
        }
    }

    selectChange(selected) {
        this.setState({
            entity_type: selected.value
        });
    }

    supperannuationChange(selected) {
        this.setState({
            sup_anu_phase: selected.value
        });
    }
    yearChange(selected) {
        this.setState({
            expected_retire_year: selected.value
        });
    }

    addEdit() {
        let data = {
            client_id: this.props.client_id,
            entity_type: this.state.entity_type,
            name: this.state.name,
            taxable_income: this.state.taxable_income,
            income_growth_projection: this.state.income_growth_projection,
            tax_info: {},
        };

        if (this.state.entity_type === config.entity_type_values.COMPANY || this.state.entity_type === config.entity_type_values.TRUST) {
            data.tax_info.tax_rate = this.state.tax_rate;
        }

        if (this.state.entity_type === config.entity_type_values.SMSF) {
            data.tax_info.sup_anu_phase = this.state.sup_anu_phase;

            if (this.state.sup_anu_phase === 'ACCUMULATION_PHASE') {
                data.tax_info.expected_retire_year = this.state.expected_retire_year;
            }
        }

        this.props.addUpdateEntity(data);
    }

    changeEntity() {
        if (this.state.change_entity_id !== '') {
            this.props.setData('change_entity', this.state.change_entity_id)
        }
    }

    selectEntity(change_entity_id) {
        this.setState({
            change_entity_id: change_entity_id
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.is_edit) {
            this.setState({
                entity_id: '',
                entity_type: getEntityType().value,
                name: '',
                taxable_income: 0,
                income_growth_projection: 0,
                tax_info: {},

                tax_rate: 0,
                sup_anu_phase: '',
                expected_retire_year: '',

                change_entity_id: '',
            })
        }

        if (nextProps.is_edit && this.props.entity_id !== nextProps.entity_id && !isNaN(nextProps.entity_id)) {
            this.setState({
                block_loading: true
            });
            let data = {
                client_id: nextProps.client_id,
            };
            axios.post(config.get_entity + nextProps.entity_id, data).then(res => {
                if (res.success) {
                    let entity = res.data.entity;
                    this.setState({
                        entity_id: entity.entity_id,
                        entity_type: entity.entity_type,
                        name: entity.name,
                        taxable_income: entity.taxable_income,
                        income_growth_projection: entity.income_growth_projection,
                        tax_info: entity.tax_info,

                        tax_rate: (typeof entity.tax_info.tax_rate !== 'undefined') ? entity.tax_info.tax_rate : 0,
                        sup_anu_phase: (typeof entity.tax_info.sup_anu_phase !== 'undefined') ? entity.tax_info.sup_anu_phase : '',
                        expected_retire_year: (typeof entity.tax_info.expected_retire_year !== 'undefined') ? entity.tax_info.expected_retire_year : '',
                    })
                } else {
                    this.props.showNotification(res.message, 'danger')
                }
                this.setState({
                    block_loading: false
                });
            });
        }
    }

    render() {
        if (this.props.change_entity) {
            return <div className={this.props.open_entity ? 'add-entity open' : 'add-entity'}>
                <div className="rect_bx">
                    <div className="head_tron d-flex justify-content-between">
                        <h2 className="head fsize22 fx_w t_green">Change Entity</h2>

                        <span className=" close-sider mbtm0 fsize14 d-flex" onClick={this.props.openCloseEntity.bind(this, false, null)}>
                            <i className="f20 icon-close" />
                        </span>
                    </div>

                    <div className="inner_bx">
                        <Form id="change-entity-form">

                            <EntityMap
                                entities={this.props.entities}
                                change_entity_id={this.state.change_entity_id}
                                selectEntity={this.selectEntity}
                            />

                            <div className="tp-btm-row text-center">
                                <Button className={"block-btn bg_grey active"} size="lg" onClick={this.props.openCloseEntity.bind(this, false, null)} type="button">
                                    <div>
                                        Close
                                    </div>
                                </Button>
                                <Button disabled={this.state.change_entity_id === ''} className={"block-btn bg_green active"} size="lg" type="button" onClick={this.changeEntity}>
                                    <div>
                                        Save
                                    </div>
                                    <div className={(this.props.loading) ? 'btn-loading left right' : ' hide'}>
                                        <div className="btn-loader" />
                                    </div>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        }

        return <div className={this.props.open_entity ? 'add-entity open' : 'add-entity'}>
            <div className="rect_bx">
                <div className="head_tron d-flex justify-content-between">
                    <h2 className="head fsize22 fx_w t_green">{this.props.is_edit ? 'Edit' : 'Add'} Owner</h2>

                    <span className=" close-sider mbtm0 fsize14 d-flex" onClick={this.props.openCloseEntity.bind(this, false, null)}>
                            <i className="f20 icon-close" />
                    </span>
                </div>

                <div className={this.state.block_loading ? `inner_bx` : `hide`}>
                    <div className="page-loader show" />
                </div>

                <div className={this.state.block_loading ? `hide` : `inner_bx`}>
                    <Form id="entity-form">
                        <div className="tp_row pbtm0 ptop0">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Portfolio Type</h4>
                                <div className="slct d-flex">
                                    <Select
                                        defaultValue={getEntityType(this.state.entity_type)}
                                        options={getEntityTypeOptions()}
                                        value={getEntityType(this.state.entity_type)}
                                        onChange={this.selectChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Name</h4>
                                <Input
                                    className="form-control"
                                    types="text"
                                    name="name"
                                    placeholder="Name of Owner"
                                    value={this.state.name}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        <div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Gross Income (p.a.)</h4>
                                <PriceField
                                    placeholder="Enter Income"
                                    name="taxable_income"
                                    value={this.state.taxable_income}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        {/*<div className="tp_row">
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Income Growth Projection (p.a.)</h4>
                                <PercentageField
                                    placeholder="Enter Capital Growth Rate"
                                    name="income_growth_projection"
                                    value={this.state.income_growth_projection}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>*/}

                        <div className={(this.state.entity_type === config.entity_type_values.COMPANY || this.state.entity_type === config.entity_type_values.TRUST) ? "tp_row" : "hide"}>
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Tax Rate</h4>
                                <PercentageField
                                    placeholder="Enter tax rate"
                                    name="tax_rate"
                                    value={this.state.tax_rate}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        <div className={(this.state.entity_type === config.entity_type_values.SMSF) ? "tp_row pbtm0 ptop0" : "hide"}>
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Superannuation Phase</h4>
                                <div className="slct d-flex">
                                    <Select
                                        defaultValue={getSupAnuPhase(this.state.sup_anu_phase)}
                                        options={getSupAnuPhaseOptions()}
                                        value={getSupAnuPhase(this.state.sup_anu_phase)}
                                        onChange={this.supperannuationChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={(this.state.entity_type === config.entity_type_values.SMSF && this.state.sup_anu_phase === 'ACCUMULATION_PHASE') ? "tp_row pbtm0 ptop0" : "hide"}>
                            <div className="hd_row d-flex">
                                <h4 className="sm_tit fx_w">Expected Retirement Year</h4>
                                <div className="slct d-flex">
                                    <Select
                                        defaultValue={getLicence(this.state.start_year, this.state.end_year, this.state.expected_retire_year)}
                                        options={getLicenceOptions(this.state.start_year, this.state.end_year)}
                                        value={getLicence(this.state.start_year, this.state.end_year, this.state.expected_retire_year)}
                                        onChange={this.yearChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="note">
                            <p>Note: This information is used to calculate your portfolio pre and post tax income projections.</p>
                        </div>

                        <div className="tp-btm-row text-center">
                            <Button className={"block-btn bg_grey active"} size="lg" onClick={this.props.openCloseEntity.bind(this, false, null)} type="button">
                                    <div>
                                       Close
                                    </div>
                            </Button>
                            <Button className={"block-btn bg_green active"} size="lg" type="button" onClick={this.addEdit}>
                                    <div>
                                       Save
                                    </div>
                                    <div className={(this.props.loading) ? 'btn-loading left right' : ' hide'}>
                                        <div className="btn-loader" />
                                    </div>
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    }
}


const EntityMap = ({entities, change_entity_id, selectEntity}) => {
    if (!entities || entities.length === 0) {
        return ''
    }

    return entities.map((entity, key) => {
        return (
            <div className="inner-row" key={key}>
                <div className="d-flex align-items-center justify-content-between">
                    <div  className="left-aside w100">
                        <div className="d-flex  align-items-center justify-content-between">
                            <div className="hd_row d-flex  w100 inner_row">
                                <h4 className="sm_tit fx_w">
                                </h4>

                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                    <span className="d-flex height60 w100">
                                        <span className="value w100 t_black pmedium">
                                            <div className="form-check w100 form-check-inline">
                                                <label className="radio-panel">
                                                  <CustomInput 
                                                        type="radio" 
                                                        name="entity_change"
                                                        id={'entity_' + entity.entity_id}
                                                        checked={(parseInt(change_entity_id) === parseInt(entity.entity_id))}
                                                         onClick={selectEntity.bind(this, entity.entity_id)}
                                                  />
                                                   <p className="mbtm0">{entity.name}  <br />
                                                     <span className="mbtm0 fsize12 t_gray"> {entity.entity_type}</span>
                                                    </p>
                                                </label>  
                                                {/* <CustomInput
                                                    type="checkbox"
                                                    name="entity_change"
                                                    id={'entity_' + entity.entity_id}
                                                    checked={(parseInt(change_entity_id) === parseInt(entity.entity_id))}
                                                    onClick={selectEntity.bind(this, entity.entity_id)}
                                                />
                                                {entity.name} ({entity.entity_type}) */}
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    });
}
