import React from 'react';
import Select, {components} from "react-select";
import {onMenuOpen } from "../../services/helper";

const groupStyles = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group = (props) => (
    <div style={groupStyles}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        background: state.isFocused ? 0 : 0,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};


export default Option = (props) => {
    return (
        <Select
            components={{ Group }}
            styles={style1}
            onMenuOpen={onMenuOpen}
            className ={"MyDropdown"}
            classNamePrefix={"MyDropdown"}
            {...props}
        />
    );
};