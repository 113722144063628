import React from 'react';
import {
    Button,
} from 'reactstrap';

export default class MiddleButton extends React.Component {

	render() {
        let {text, onClick, show} = this.props;

        if (text === '') {
            return '';
        }

        if (typeof show === 'undefined') {
            show = true
        }

        return (
            <div className={show ? "center_bx" : "hide"}>
                <Button className="middle_btn" size="lg" type="button" onClick={onClick} block >
                    <div>
                        {text}
                    </div>
                </Button>
            </div>
        );
	}
}