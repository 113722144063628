import React from 'react';
import {
    Row,
    Col,
    CardTitle,
    Form,
    Input,
} from 'reactstrap';
import {getMyProfile, getNotificationObject} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import {Redirect} from 'react-router';
import config from 'config/config';
import LeftLink from "../../components/properlytics/left-link";
import RightButton from "../../components/properlytics/right-button";
import Autocomplete from 'react-google-autocomplete';
import axios from "../../services/axios";


class CustomSubscription extends React.Component {
    constructor(props) {
        super(props);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            name:'',
            business_name: '',
            phone: '',
            email: '',
            people: '',
            location: '',

            redirect_to_dashboard: false,
            loading: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'people') {
            if (!isNaN(event.target.value) || event.target.value === '') {
                this.setState({
                    [name]: value
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    onAddressChange(place) {
        this.setState({
            location: place.formatted_address,
        });
    }

    onSubmit() {
        this.setState({
            loading: true
        })
        let postData = new FormData();
        postData.append('name', this.state.name);
        postData.append('business_name', this.state.business_name);
        postData.append('phone', this.state.phone);
        postData.append('email', this.state.email);
        postData.append('people', this.state.people);
        postData.append('location', this.state.location);

        axios.post(config.request_custom_account, postData).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                loading: false
            })
        });
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                name: profile.name,
                business_name: profile.company_name,
                phone: profile.phone,
                email: profile.email,
                people: '',
                location: '',
            });

        } else {
            this.setState({
                redirect_to_dashboard: true,
            })
        }
    }

    render() {

        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //web
        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Subscription
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Add information for the custom subscription</p>
                        </Col>
                    </Row>

                    <div id="subscription">
                        <div className="rect_bx mt-5">
                            <div className="head_tron justify-content-between">
                                <h2 className="head t_green text-center">Custom subscription</h2>
                            </div>

                            <div className="inner_bx">
                                <Form id="prp_detail">
                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Name</h4>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Name"
                                                name="name"
                                                id="name"
                                                value={this.state.name}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Business Name</h4>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Business Name"
                                                name="business_name"
                                                id="business_name"
                                                value={this.state.business_name}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Phone</h4>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Phone No."
                                                name="phone"
                                                id="phone"
                                                value={this.state.phone}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Email</h4>
                                            <Input
                                                type="email"
                                                className="form-control"
                                                value={this.state.email}
                                            />
                                        </div>
                                    </div>

                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">People</h4>
                                            <Input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter no of people in your organization"
                                                name="people"
                                                id="people"
                                                value={this.state.people}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Address</h4>
                                            <Autocomplete
                                                className="form-control"
                                                onPlaceSelected={this.onAddressChange}
                                                types={[]}
                                                name="location"
                                                value={this.state.location}
                                                onChange={this.onInputChange}
                                                componentRestrictions={{country: "au"}}
                                            />
                                        </div>
                                    </div>

                                    <div className="tp_row_btm d-flex justify-content-between">
                                        <LeftLink url={config.site_url.subscription} text="Exit" />
                                        <RightButton text="Submit" onClick={this.onSubmit} loading={this.state.loading}/>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default CustomSubscription;
