import React from 'react';
import {
    Form,
    CustomInput,
    Input,
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import config from "config/config";
import PriceField from "../../../../components/numbers/PriceField";

export default class DepreciationOfFittings extends React.Component {

    render() {
        const {
            onInputChange, furniture_value, furniture_life, stove_value, stove_life, hot_water_sys_value,
            hot_water_sys_life, extra, total, year_one_depreciation, depreciation_method, diminishing_value,
            onBlur, show_dialog, addFittings, removeFittings, handleOnInputChange
        } = this.props;

        return <div className={show_dialog ? 'add-entity open  green-slideover slideover' : 'add-entity'}>
                    <div className="rect_bx">
                        <div className="head_tron d-flex justify-content-between">
                            <h2 className="head fsize22 fx_w w-nowrap t_green">Cost of Fittings</h2>

                            <span className=" close-sider mbtm0 fsize14 d-flex">
                                <i className="f20 icon-close" onClick={this.props.hideShowDialog} />
                            </span>
                        </div>

                    <div className="inner_bx p-t-0">
                        <Form>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span className="t_black">ITEM</span>
                                        <span className="t_black">VALUE($)</span>
                                        <span className="text-right t_black">LIFE (YEAR)</span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                            <span>
                                                Stove
                                            </span>
                                        <span>
                                            <PriceField
                                                placeholder="$0"
                                                name="stove_value"
                                                value={stove_value}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                        <span>
                                            <Input
                                                type="text"
                                                className="form-control text-right"
                                                placeholder="0"
                                                name="stove_life"
                                                value={stove_life}
                                                maxLength={config.number_max_length}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Hot Water System
                                        </span>
                                        <span>
                                            <PriceField
                                                placeholder="$0"
                                                name="hot_water_sys_value"
                                                value={hot_water_sys_value}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                        <span>
                                            <Input
                                                type="text"
                                                className="form-control text-right"
                                                placeholder="0"
                                                name="hot_water_sys_life"
                                                value={hot_water_sys_life}
                                                maxLength={config.number_max_length}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Furniture
                                        </span>
                                        <span>
                                            <PriceField
                                                placeholder="$0"
                                                name="furniture_value"
                                                value={furniture_value}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                        <span>
                                            <Input
                                                type="text"
                                                className="form-control text-right"
                                                placeholder="0"
                                                name="furniture_life"
                                                value={furniture_life}
                                                maxLength={config.number_max_length}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <MapExtra
                                extra={extra}
                                removeFittings={removeFittings}
                                handleOnInputChange={handleOnInputChange}
                            />

                            {/* Change request: 
                            Include new "Add Fittings" dynamic field */}
                            <div className="tp_row table_row pr-0 d-flex height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <div><span>Add Fittings</span>
                                        <p data-tip="Please enter the combined value of all items that have the same effective life which you manually input after clicking “Add Fittings Value”. This will save time entering individual items." className="tooltip-pop"><i className="fa fa-info-circle" /></p>
                                        </div>
                                        <span>
                                            
                                        </span>
                                        <span className="txt-gray act_icon">
                                            <i className="f20 icon-plus" onClick={addFittings}/>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Total
                                        </span>
                                        <span>
                                            <PriceField
                                                placeholder="$0"
                                                name="total"
                                                value={total}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Year One Depreciation
                                        </span>
                                        <span>
                                            <PriceField
                                                placeholder="$0"
                                                name="year_one_depreciation"
                                                value={year_one_depreciation}
                                                readOnly={true}
                                            />
                                        </span>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            <CustomInput
                                                type="radio"
                                                id="depreciation_method1"
                                                name="depreciation_method"
                                                label="Diminishing Value"
                                                className="w-nowrap"
                                                value={2}
                                                checked={(parseInt(depreciation_method) === 2)}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                        <span>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="0"
                                                name="diminishing_value"
                                                value={diminishing_value}
                                                maxLength={config.percentage_max_length}
                                                onChange={onInputChange.bind(this)}
                                                onBlur={onBlur.bind(this)}
                                            />
                                        </span>
                                        <span/>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            <CustomInput
                                                type="radio"
                                                id="depreciation_method2"
                                                name="depreciation_method"
                                                label="Prime Cost"
                                                value={1}
                                                checked={(parseInt(depreciation_method) === 1)}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                        <span/>
                                        <span/>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row_btm d-flex justify-content-between">
                                {/* <LeftButton text="Close" onClick={this.props.hideShowDialog} /> */}

                                <RightButton text="Save" onClick={this.props.hideShowDialog} />
                            </div>
                        </Form>
                    </div>  
                </div>
            </div>  
        
    }
}


const MapExtra = ({extra, handleOnInputChange, removeFittings}) => {
    if(extra.length > 0){
        return extra.map( (dp, key) => {
            return (
                <div className="tp_row table_row new-cost-row d-flex  height60" key={key}>
                    <div className="hd_row w100">
                        <div className="d-flex w100 tab_grid">
                            <span>
                                <Input
                                    type="text"
                                    className="form-control text-right"
                                    placeholder="Name"
                                    name="name[]"
                                    value={dp.name}
                                    onChange={handleOnInputChange.bind(this, key, 'name')}
                                    onBlur={handleOnInputChange.bind(this, key, 'name')}
                                />
                            </span>
                            <span>
                                <PriceField
                                    placeholder="$0"
                                    name="value[]"
                                    value={dp.value}
                                    onChange={handleOnInputChange.bind(this, key, 'value')}
                                    onBlur={handleOnInputChange.bind(this, key, 'value')}
                                />
                            </span>
                            <span>
                                <Input
                                    type="text"
                                    className="form-control text-right"
                                    placeholder="0"
                                    name="years[]"
                                    value={dp.years}
                                    maxLength={config.number_max_length}
                                    onChange={handleOnInputChange.bind(this, key, 'years')}
                                    onBlur={handleOnInputChange.bind(this, key, 'years')}
                                />

                                <i
                                    className="f20 txt-gray icon-close"
                                    onClick={removeFittings.bind(this, key)}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            )
        })
    }
    return null;
}