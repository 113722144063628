import config from '../config/config';
import {
    displayPropertySubType, getBuyerType, getDefaultCapitalGrowth, getDefaultInflation, getHouseType,
    getPropertyUsed, getRent, getSelectedYear, getState, getWhoPayYear, getDefaultLoanCosts, getLoanType,
    getDefaultAgentComissionRate, getDefaultDepreciationOfBuilding, getDefaultDiminishingValue, getDefaultDPLife,
    getDefaultDepreciationMethod, getTypeOfProperty, setEntityId,setTypeOfProperty, setEntityType,
    setEntityName
} from "./config-helper";
import moment from "moment/moment";

export function getHeaders() {
    return {
        headers: {
            Authorization: window.localStorage.getItem('app.token'),
        }
    };
}

export function storeInLocalStorage(profile) {
    window.localStorage.setItem('app.email', profile.email);
    window.localStorage.setItem('app.name', profile.name);
    window.localStorage.setItem('app.initials', profile.initials);
    window.localStorage.setItem('app.picture', profile.picture);
    window.localStorage.setItem('app.user_id', profile.user_id);
    window.localStorage.setItem('app.user_data', JSON.stringify(profile));
}

export function getMyProfile() {
    if (window.localStorage.getItem('app.user_data')) {
        return JSON.parse(window.localStorage.getItem('app.user_data'));
    }
    return false;
}

export function canAddProperty(currentCountOfProperty, account_type) {
    if (parseInt(account_type) === 1) {
        if (window.localStorage.getItem('app.user_data')) {
            let data = JSON.parse(window.localStorage.getItem('app.user_data'));
            let max_allow = data.config.max_personal_property_limit;
            if (parseInt(max_allow) <= parseInt(currentCountOfProperty)) {
                return false;
            }
        }
    }
    return true;
}

export function getSubscriptionPageData() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));
        let redirect_to_dashboard = (!data.is_main_user);
        let redirect_to_manage = (data.subscription_detail.subscription_type.toString() !== '');
        let redirect_to_purchase = (data.subscription_detail.subscription_type.toString() === 'WEB' && data.subscription_detail.subscription_expire);
        let subscription_type = data.subscription_detail.subscription_plan.toString();
        let subscription_interval = data.subscription_detail.payment_interval.toString();
        let has_trial = !(redirect_to_purchase);
        return {
            has_trial: has_trial,
            redirect_to_dashboard: redirect_to_dashboard,
            redirect_to_manage: redirect_to_manage,
            redirect_to_purchase: redirect_to_purchase,
            subscription_type: subscription_type || 'PERSONAL',
            subscription_interval: subscription_interval || 'MONTHLY_INTERVAL',

            //plan prices
            personal_monthly: getPlanPrice('PERSONAL', 'MONTHLY_INTERVAL', 1),
            personal_yearly: getPlanPrice('PERSONAL', 'YEARLY_INTERVAL', 1),
            professional_monthly: getPlanPrice('PROFESSIONAL', 'MONTHLY_INTERVAL', 1),
            professional_yearly: getPlanPrice('PROFESSIONAL', 'YEARLY_INTERVAL', 1),
            enterprise_monthly: getPlanPrice('ENTERPRISE', 'MONTHLY_INTERVAL', 1),
            enterprise_yearly: getPlanPrice('ENTERPRISE', 'YEARLY_INTERVAL', 1),
        };
    }
    return {};
}

export function isMainUser() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));
        return data.is_main_user;
    }
    return false;
}

export function getPlanPrice(name, interval, qty = 1) {
    qty = parseInt(qty);
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));
        let plan = data.config.plan_pricing;
        for (let [key, item] of Object.entries(plan)) {
            if (key.toString() === 'PERSONAL' && name === 'PERSONAL') {
                if (interval === 'MONTHLY_INTERVAL') {
                    return item.MONTHLY_INTERVAL.price;
                } else {
                    return item.YEARLY_INTERVAL.price;
                }
            }

            if (key.toString() === 'PROFESSIONAL' && name === 'PROFESSIONAL') {
                if (interval === 'MONTHLY_INTERVAL') {
                    return item.MONTHLY_INTERVAL.price;
                } else {
                    return item.YEARLY_INTERVAL.price;
                }
            }

            if (key.toString() === 'ENTERPRISE' && name === 'ENTERPRISE') {
                if (interval === 'MONTHLY_INTERVAL') {
                    return item.MONTHLY_INTERVAL.price;
                } else {
                    return item.YEARLY_INTERVAL.price;
                }
            }
        }
    }
    return 0;
}

export function getPlan(name, interval) {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));
        let plan = data.config.plan_pricing;
        for (let [key, item] of Object.entries(plan)) {
            if (key.toString() === 'PERSONAL' && name === 'PERSONAL') {
                if (interval === 'MONTHLY_INTERVAL') {
                    return item.MONTHLY_INTERVAL;
                } else {
                    return item.YEARLY_INTERVAL;
                }
            }

            if (key.toString() === 'PROFESSIONAL' && name === 'PROFESSIONAL') {
                if (interval === 'MONTHLY_INTERVAL') {
                    return item.MONTHLY_INTERVAL;
                } else {
                    return item.YEARLY_INTERVAL;
                }
            }

            if (key.toString() === 'ENTERPRISE' && name === 'ENTERPRISE') {
                if (interval === 'MONTHLY_INTERVAL') {
                    return item.MONTHLY_INTERVAL;
                } else {
                    return item.YEARLY_INTERVAL;
                }
            }
        }
    }
    return {};
}

export function getConfig() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));

        if (data.config) {
            return data.config;
        }
    }
    return false;
}

export function isLogin() {
    return !!(window.localStorage.getItem('app.user_id'));
}

export function subscriptionExpired() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));

        if (typeof data.subscription_detail !== 'undefined') {
            if (typeof data.subscription_detail.subscription_expire !== 'undefined') {
                return (data.subscription_detail.subscription_expire);
            }
        }
    }
    return true;
}

export function clearLocalStorage() {
    window.localStorage.removeItem('app.email');
    window.localStorage.removeItem('app.name');
    window.localStorage.removeItem('app.initials');
    window.localStorage.removeItem('app.profile_pic');
    window.localStorage.removeItem('app.token');
    window.localStorage.removeItem('app.user_id');
    window.localStorage.removeItem('app.user_data');
    window.localStorage.removeItem('app.time_out');
    window.localStorage.removeItem('app.settings');
}

//return notification object
export const getNotificationObject = (type, message) => {
    let title = type;
    if (title === 'danger') {
        title = 'error';
    }

    return {
        title: title.charAt(0).toUpperCase() + title.slice(1),
        message: message,
        type: type,
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {duration: 4000},
        dismissable: {click: true}
    }
}

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", displayNagativeSign = true) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        let negativeSign = "";
        if (displayNagativeSign && (amount < 0)) {
            negativeSign = "-";
        }

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
        return amount;
    }
};


export function convertToNumber(val) {
    val = val.replace("%", "").replace("$", "").replace(/,/g, '');
    return (val === '') ? 0 : parseFloat(val);
}

export const calculatePropertyStatus = (status_per) => {
    try {
        let status = (630 * status_per) / 100;
        return status.toFixed(0);
    } catch (e) {
        console.log(e)
        return status_per;
    }
};

export const getSettings = () => {
    let return_val = {
        client_id: '',
        client_name: '',
        entity_id: '',
        entity_type: '',
        entity_name: '',
        type_of_property: '',
        year: '',
    }
    try {
        let settings = JSON.parse(window.localStorage.getItem('app.settings'));
        if (settings) {
            return settings;
        } else {
            return return_val;
        }
    } catch (e) {
        return return_val;
    }
};

export const getYearWiseValue = (values, year) => {
    try {
        year = parseInt(year);
        let name = 'yr' + year;
        if (typeof values[name] !== 'undefined') {
           return values[name];
        } else {
            return 0;
        }
    } catch (e) {
        return 0;
    }
};

export const getOverviewMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", displayNagativeSign = false) => {
    if (amount < 0) {
        //return '($' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign) + ')';
        return '-$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    } else {
        return '$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    }
};

export const getWhoPayMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", displayNagativeSign = false) => {
    if (amount < 0) {
        return '-$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    } else {
        return '+$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    }
};

export const dataURLtoFile = (dataUrl) => {
    return base64ToBlob(dataUrl);
}

export const base64ToBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]
    const sliceSize = 1024;
    const byteChars = window.atob(arr[1]);
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        let slice = byteChars.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, getFilename(dataurl),{type: mime});
}

export const getFilename = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];

    return Math.round(+new Date()/1000) + '.' + mime.split('/').pop();
}

export const setPropertyTabState = (property) => {

    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        setEntityId(prop.entity_id);
        setEntityType(prop.entity_type);
        setEntityName(prop.entity_name);
        setTypeOfProperty(prop.type_of_property);

        let fun = property.funding_structure;
        state = {
            property_id: prop.property_id,
            client_id: prop.client_id,
            entity_id: prop.entity_id,
            address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            property_type: prop.property_type,
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            year_purchase: prop.year_purchase,
            year_build: prop.year_build,
            builder_name: prop.builder_name,
            purchase_price: prop.purchase_price,
            current_price: prop.current_price,
            display_sub_type: displayPropertySubType(prop.property_type),
            property_sub_type: prop.property_sub_type,
            land_price: prop.land_price,
            build_price: prop.build_price,
            adv_link: prop.adv_link,
            type_of_property: prop.type_of_property,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            interest_term: (typeof fun.interest_term !== 'undefined') ? fun.interest_term : 5,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 30,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            interest_term_back: (typeof fun.interest_term_back !== 'undefined') ? fun.interest_term_back : 5,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 30,
            loans: (typeof fun.loans !== 'undefined') ? fun.loans : [],

            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,

            is_existing_property: (parseInt(prop.type_of_property) === 1),
        }
    }

    return state;
};

export const setAcquisitionTabState = (property) => {
    let state = setDefaultTabState(property);
    if (property.property_detail) {
        let prop = property.property_detail;
        let conveyancing_cost = 0;
        if (!state.is_existing_property) {
            conveyancing_cost = (typeof property.acquisition_cost.conveyancing_cost !== 'undefined') ? property.acquisition_cost.conveyancing_cost : 0;
        }

        state.conveyancing_cost = conveyancing_cost;
        state.house_type = (typeof property.acquisition_cost.house_type !== 'undefined') ? property.acquisition_cost.house_type : getHouseType().value;
        state.property_used = (typeof property.acquisition_cost.property_used !== 'undefined') ? property.acquisition_cost.property_used : getPropertyUsed().value;
        state.buyer_type = (typeof property.acquisition_cost.buyer_type !== 'undefined') ? property.acquisition_cost.buyer_type : getBuyerType().value;
        state.stamp_duty = (typeof property.acquisition_cost.stamp_duty !== 'undefined') ? property.acquisition_cost.stamp_duty : 0;
        state.state = (typeof property.acquisition_cost.state_id !== 'undefined' && property.acquisition_cost.state_id !== '') ? property.acquisition_cost.state_id : getState().value;
        state.transfer_of_title = (typeof property.acquisition_cost.transfer_of_title !== 'undefined') ? property.acquisition_cost.transfer_of_title : '';
        state.other_costs = (typeof property.acquisition_cost.other_costs !== 'undefined') ? property.acquisition_cost.other_costs : [];
        state.furnitures_value = (typeof property.acquisition_cost.furniture_value !== 'undefined') ? property.acquisition_cost.furniture_value : '';
        state.calculateSD = (parseInt(prop.type_of_property) === 2);
    }

    return state;
};

export const setFundingTabState = (property) => {
    let state = setDefaultTabState(property);
    if (property.property_detail) {
        const defaultLoanCosts = getDefaultLoanCosts();
        let fun = property.funding_structure;

        state.invested_cash= (typeof fun.invested_cash !== 'undefined') ? fun.invested_cash : false;
        state.deposite_cash= (typeof fun.deposite_cash !== 'undefined') ? fun.deposite_cash : 0;
        state.front_end_loan= (typeof fun.front_end_loan !== 'undefined') ? fun.front_end_loan : false;
        state.loan_amount= (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0;
        state.lender_name= (typeof fun.lender_name !== 'undefined') ? fun.lender_name : '';
        state.loan_type= (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value;
        state.interest_rate= (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0;
        state.from_year= (typeof fun.from_year !== 'undefined') ? fun.from_year : 1;
        state.to_year= (typeof fun.to_year !== 'undefined') ? fun.to_year : '';
        state.interest_term= (typeof fun.interest_term !== 'undefined') ? fun.interest_term : '';
        state.loan_repayment= (typeof fun.loan_repayment !== 'undefined') ? fun.loan_repayment : 0;
        state.additional_loan_amount= (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0;
        state.secure_against= (typeof fun.secure_against !== 'undefined') ? fun.secure_against : '';
        state.back_end_loan= (typeof fun.back_end_loan !== 'undefined') ? fun.back_end_loan : false;
        state.loan_amount_back= (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0;
        state.lender_name_back= (typeof fun.lender_name_back !== 'undefined') ? fun.lender_name_back : '';
        state.loan_type_back= (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value;
        state.interest_rate_back= (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0;
        state.from_year_back= (typeof fun.from_year_back !== 'undefined') ? fun.from_year_back : 1;
        state.to_year_back= (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : '';
        state.interest_term_back= (typeof fun.interest_term_back !== 'undefined') ? fun.interest_term_back : '';
        state.loan_repayment_back= (typeof fun.loan_repayment_back !== 'undefined') ? fun.loan_repayment_back : 0;
        state.additional_loan_amount_back= (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0;
        state.secure_against_back= (typeof fun.secure_against_back !== 'undefined') ? fun.secure_against_back : '';
        state.other_costs= (typeof fun.other_costs !== 'undefined') ? fun.other_costs : [];
        state.loans= (typeof fun.loans !== 'undefined') ? fun.loans : [];

        if (state.is_existing_property) {
            state.establishment_fees= 0;
            state.mortgage_insurance= 0;
            state.establishment_fees_back= 0;
            state.mortgage_insurance_back= 0;
            state.morgagees_fees= 0;
            state.valuation_fees= 0;
            state.reg_morgage_1= 0;
            /*state.reg_morgage_2= 0;*/
            state.reg_of_title= 0;
            state.search_fees= 0;
        } else {
            state.establishment_fees= (typeof fun.establishment_fees !== 'undefined') ? fun.establishment_fees : 0;
            state.mortgage_insurance= (typeof fun.mortgage_insurance !== 'undefined') ? fun.mortgage_insurance : 0;
            state.establishment_fees_back= (typeof fun.establishment_fees_back !== 'undefined') ? fun.establishment_fees_back : 0;
            state.mortgage_insurance_back= (typeof fun.mortgage_insurance_back !== 'undefined') ? fun.mortgage_insurance_back : 0;
            state.morgagees_fees= (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.morgagees_fees : defaultLoanCosts.morgagees_fees;
            state.valuation_fees= (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.valuation_fees : defaultLoanCosts.valuation_fees;
            state.reg_morgage_1= (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.reg_morgage_1 : defaultLoanCosts.reg_morgage_1;
            /*state.reg_morgage_2= (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.reg_morgage_2 : defaultLoanCosts.reg_morgage_2;*/
            state.reg_of_title= (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.reg_of_title : defaultLoanCosts.reg_of_title;
            state.search_fees= (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.search_fees : defaultLoanCosts.search_fees;
        }
    }

    return state;
};

export const setOutcomesTabState = (property) => {
    let state = setDefaultTabState(property);
    if (property.property_detail) {
        let out = property.investment_outcome;
        let ac = property.acquisition_cost;

        state.rent_amount= (typeof out.rent_amount !== 'undefined') ? out.rent_amount : '';
        state.rent_duration= (typeof out.rent_duration !== 'undefined') ? out.rent_duration : getRent(52).value;
        state.potential_yearly_return= (typeof out.potential_yearly_return !== 'undefined') ? out.potential_yearly_return : '';
        state.vacancy_rate= (typeof out.vacancy_rate !== 'undefined') ? out.vacancy_rate : '';
        state.actual_yearly_rent= (typeof out.actual_yearly_rent !== 'undefined') ? out.actual_yearly_rent : '';
        state.gross_percent= (typeof out.gross_percent !== 'undefined') ? out.gross_percent : '';

        state.is_owner_occupied = (typeof ac.buyer_type !== 'undefined' && parseInt(ac.buyer_type) === 4);
    }

    return state;
};

export const setExpensesTabState = (property) => {
    let state = setDefaultTabState(property);
    if (property.property_detail) {
        let exp = property.investment_expense;
        let out = property.investment_outcome;

        let potential_yearly_return = (typeof out.potential_yearly_return !== 'undefined') ? out.potential_yearly_return : 0;
        let letting_fee = 0;
        if (potential_yearly_return !== 0) {
            letting_fee = potential_yearly_return / 52;
        }

        state.has_property_manager= (typeof exp.has_property_manager !== 'undefined') ? exp.has_property_manager : false;
        state.property_manager= (typeof exp.property_manager !== 'undefined') ? exp.property_manager : '';
        state.agent_commission= (typeof exp.agent_commission !== 'undefined') ? exp.agent_commission : 0;
        state.agent_commision_per= (typeof exp.agent_commision_per !== 'undefined') ? exp.agent_commision_per : getDefaultAgentComissionRate();
        state.letting_fee= letting_fee;
        state.rates= (typeof exp.rates !== 'undefined') ? exp.rates : 0;
        state.has_insurance= (typeof exp.has_insurance !== 'undefined') ? exp.has_insurance : false;
        state.has_building_ins= (typeof exp.has_building_ins !== 'undefined') ? exp.has_building_ins : false;
        state.building_ins_name= (typeof exp.building_ins_name !== 'undefined') ? exp.building_ins_name : '';
        state.building_ins_amount= (typeof exp.building_ins_amount !== 'undefined') ? exp.building_ins_amount : 0;
        state.has_landlord_ins= (typeof exp.has_landlord_ins !== 'undefined') ? exp.has_landlord_ins : false;
        state.landlord_ins_name= (typeof exp.landlord_ins_name !== 'undefined') ? exp.landlord_ins_name : '';
        state.landlord_ins_amount= (typeof exp.landlord_ins_amount !== 'undefined') ? exp.landlord_ins_amount : 0;
        state.maintenance= (typeof exp.maintenance !== 'undefined') ? exp.maintenance : 0;
        state.body_corporate_amount= (typeof exp.body_corporate_amount !== 'undefined') ? exp.body_corporate_amount : 0;
        state.has_other_expenses= (typeof exp.has_other_expenses !== 'undefined') ? exp.has_other_expenses : false;
        state.cleaning= (typeof exp.cleaning !== 'undefined') ? exp.cleaning : 0;
        state.post_control= (typeof exp.post_control !== 'undefined') ? exp.post_control : 0;
        state.mowing= (typeof exp.mowing !== 'undefined') ? exp.mowing : 0;
        state.other_expenses= (typeof exp.other_expenses !== 'undefined') ? exp.other_expenses : 0;
        state.total_expenses= (typeof exp.total_expenses !== 'undefined') ? exp.total_expenses : 0;
    }

    return state;
};

export const setEconomicTabState = (property) => {
    let state = setDefaultTabState(property);
    if (property.property_detail) {
        let eco = property.economic_assumption;
        //rental expense
        let has_rental_income = true;
        if (typeof eco.has_rental_income !== 'undefined') {
            has_rental_income = eco.has_rental_income
        } else if (typeof eco.rental_income !== 'undefined') {
            has_rental_income = eco.rental_income
        }

        state.capital_growth_rate = (typeof eco.capital_growth_rate !== 'undefined') ? eco.capital_growth_rate : getDefaultCapitalGrowth();
        state.inflation = (typeof eco.inflation !== 'undefined') ? eco.inflation : getDefaultInflation();
        state.year1 = (typeof eco.year1 !== 'undefined') ? eco.year1 : '';
        state.year2 = (typeof eco.year2 !== 'undefined') ? eco.year2 : '';
        state.year3 = (typeof eco.year3 !== 'undefined') ? eco.year3 : '';
        state.year4 = (typeof eco.year4 !== 'undefined') ? eco.year4 : '';
        state.year5 = (typeof eco.year5 !== 'undefined') ? eco.year5 : '';
        state.selected_year = (typeof eco.selected_year !== 'undefined') ? eco.selected_year : getSelectedYear().value;
        state.has_rental_income = has_rental_income;
        state.texable_income = (typeof eco.texable_income !== 'undefined') ? eco.texable_income : true;
        state.living_expenses = (typeof eco.living_expenses !== 'undefined') ? eco.living_expenses : true;
        state.price = 0
        if (state.is_existing_property) {
            state.price = property.property_detail.current_price
        } else {
            state.price = state.purchase_price
        }
    }

    return state;
};

export const setDeductionTabState = (property) => {
    let state = setDefaultTabState(property);
    if (property.property_detail) {
        let prop = property.property_detail;

        let ded = property.deduction;
        let dp = (typeof ded.depreciation_fitting_details !== 'undefined') ? ded.depreciation_fitting_details : {};
        let lifes = getDefaultDPLife();

        state.cash_deduction = (typeof ded.cash_deduction !== 'undefined') ? ded.cash_deduction : 0;
        state.depreciation_of_building = (typeof ded.depreciation_of_building !== 'undefined') ? ded.depreciation_of_building : getDefaultDepreciationOfBuilding();
        state.building_cost = (typeof ded.building_cost !== 'undefined') ? ded.building_cost : 0;
        state.depreciation_amount = (typeof ded.depreciation_amount !== 'undefined') ? ded.depreciation_amount : 0;
        state.depreciation_fitting = (typeof ded.depreciation_fitting !== 'undefined') ? ded.depreciation_fitting : 0;
        state.loan_cost = (typeof ded.loan_cost !== 'undefined') ? ded.loan_cost : 0;
        state.renovation_year = (typeof ded.renovation_year !== 'undefined') ? ded.renovation_year : '';
        state.renovation_cost = (typeof ded.renovation_cost !== 'undefined') ? ded.renovation_cost : 0;
        state.build_year = (typeof prop.year_build !== 'undefined') ? prop.year_build: '';

        //dp
        state.stove_value = (typeof dp.stove_value !== 'undefined') ? dp.stove_value : 0;
        state.stove_life = (typeof dp.stove_life !== 'undefined') ? dp.stove_life : lifes.stove_life;
        state.hot_water_sys_value = (typeof dp.hot_water_sys_value !== 'undefined') ? dp.hot_water_sys_value : 0;
        state.hot_water_sys_life = (typeof dp.hot_water_sys_life !== 'undefined') ? dp.hot_water_sys_life : lifes.hot_water_sys_life;
        state.furniture_value = (typeof dp.furniture_value !== 'undefined') ? dp.furniture_value : 0;
        state.furniture_life = (typeof dp.furniture_life !== 'undefined') ? dp.furniture_life : lifes.furniture_life;
        state.extra = (typeof dp.extra !== 'undefined') ? dp.extra : [];
        state.total = (typeof dp.total !== 'undefined') ? dp.total : 0;
        state.year_one_depreciation = (typeof dp.year_one_depreciation !== 'undefined') ? dp.year_one_depreciation : 0;
        state.depreciation_method = (typeof dp.depreciation_method !== 'undefined') ? dp.depreciation_method : getDefaultDepreciationMethod();
        state.diminishing_value = (typeof dp.diminishing_value !== 'undefined') ? dp.diminishing_value : getDefaultDiminishingValue();
    }

    return state;
};

export const setIncomeTabState = (property) => {
    let state = setDefaultTabState(property);
    if (property.property_detail) {
        let inc = property.income;
        let en = property.entity;
        let tb = (typeof property.income.tax_benefits !== 'undefined') ? property.income.tax_benefits : {};
        let it_type = 1;
        if (property.entity.entity_type === config.entity_type_values.SMSF) {
            it_type = 2;
        } else if (property.entity.entity_type === config.entity_type_values.COMPANY) {
            it_type = 3;
        }


        let i_salary = 0;

        if (typeof en.taxable_income !== 'undefiend') {
            i_salary = en.taxable_income;
        }

        if (typeof inc.i_salary !== 'undefined') {
            i_salary = inc.i_salary;
        }

        state.is_joint= (typeof inc.is_joint !== 'undefined') ? inc.is_joint : false;
        state.investor= (typeof inc.investor !== 'undefined') ? inc.investor : 100;
        state.partner= (typeof inc.partner !== 'undefined') ? inc.partner : 0;
        state.i_salary= i_salary;
        state.p_salary= (typeof inc.p_salary !== 'undefined') ? inc.p_salary : 0;
        state.salary= (typeof inc.salary !== 'undefined') ? inc.salary : 0;
        state.i_other_income= (typeof inc.i_other_income !== 'undefined') ? inc.i_other_income : 0;
        state.p_other_income= (typeof inc.p_other_income !== 'undefined') ? inc.p_other_income : 0;
        state.other_income= (typeof inc.other_income !== 'undefined') ? inc.other_income : 0;
        state.i_work_expenses= (typeof inc.i_work_expenses !== 'undefined') ? inc.i_work_expenses : 0;
        state.p_work_expenses= (typeof inc.p_work_expenses !== 'undefined') ? inc.p_work_expenses : 0;
        state.work_expenses= (typeof inc.work_expenses !== 'undefined') ? inc.work_expenses : 0;
        state.i_other_expenses= (typeof inc.i_other_expenses !== 'undefined') ? inc.i_other_expenses : 0;
        state.p_other_expenses= (typeof inc.p_other_expenses !== 'undefined') ? inc.p_other_expenses : 0;
        state.other_expenses= (typeof inc.other_expenses !== 'undefined') ? inc.other_expenses : 0;
        state.i_tax_offset= (typeof inc.i_tax_offset !== 'undefined') ? inc.i_tax_offset : 0;
        state.p_tax_offset= (typeof inc.p_tax_offset !== 'undefined') ? inc.p_tax_offset : 0;
        state.tax_offset= (typeof inc.tax_offset !== 'undefined') ? inc.tax_offset : 0;
        state.i_nt_income= (typeof inc.i_nt_income !== 'undefined') ? inc.i_nt_income : 0;
        state.p_nt_income= (typeof inc.p_nt_income !== 'undefined') ? inc.p_nt_income : 0;
        state.nt_income= (typeof inc.nt_income !== 'undefined') ? inc.nt_income : 0;
        state.i_non_taxable= (typeof inc.i_non_taxable !== 'undefined') ? inc.i_non_taxable : 0;
        state.p_non_taxable= (typeof inc.p_non_taxable !== 'undefined') ? inc.p_non_taxable : 0;
        state.non_taxable= (typeof inc.non_taxable !== 'undefined') ? inc.non_taxable : 0;
        state.total_net_income= (typeof inc.total_net_income !== 'undefined') ? inc.total_net_income : 0;

        //tb
        state.i_income= (typeof tb.i_income !== 'undefined') ? tb.i_income : 0;
        state.p_income= (typeof tb.p_income !== 'undefined') ? tb.p_income : 0;
        state.income= (typeof tb.income !== 'undefined') ? tb.income : 0;
        state.i_rental_income= (typeof tb.i_rental_income !== 'undefined') ? tb.i_rental_income : 0;
        state.p_rental_income= (typeof tb.p_rental_income !== 'undefined') ? tb.p_rental_income : 0;
        state.rental_income= (typeof tb.rental_income !== 'undefined') ? tb.rental_income : 0;
        state.i_total_income= (typeof tb.i_total_income !== 'undefined') ? tb.i_total_income : 0;
        state.p_total_income= (typeof tb.p_total_income !== 'undefined') ? tb.p_total_income : 0;
        state.total_income= (typeof tb.total_income !== 'undefined') ? tb.total_income : 0;
        state.i_rental_deduction= (typeof tb.i_rental_deduction !== 'undefined') ? tb.i_rental_deduction : 0;
        state.p_rental_deduction= (typeof tb.p_rental_deduction !== 'undefined') ? tb.p_rental_deduction : 0;
        state.rental_deduction= (typeof tb.rental_deduction !== 'undefined') ? tb.rental_deduction : 0;
        state.i_new_tax_income= (typeof tb.i_new_tax_income !== 'undefined') ? tb.i_new_tax_income : 0;
        state.p_new_tax_income= (typeof tb.p_new_tax_income !== 'undefined') ? tb.p_new_tax_income : 0;
        state.new_tax_income= (typeof tb.new_tax_income !== 'undefined') ? tb.new_tax_income : 0;
        state.i_present_tax= (typeof tb.i_present_tax !== 'undefined') ? tb.i_present_tax : 0;
        state.p_present_tax= (typeof tb.p_present_tax !== 'undefined') ? tb.p_present_tax : 0;
        state.present_tax= (typeof tb.present_tax !== 'undefined') ? tb.present_tax : 0;
        state.i_new_tax= (typeof tb.i_new_tax !== 'undefined') ? tb.i_new_tax : 0;
        state.p_new_tax= (typeof tb.p_new_tax !== 'undefined') ? tb.p_new_tax : 0;
        state.new_tax= (typeof tb.new_tax !== 'undefined') ? tb.new_tax : 0;
        state.i_tax_saving= (typeof inc.i_tax_offset !== 'undefined') ? inc.i_tax_offset : 0;
        state.p_tax_saving= (typeof inc.p_tax_offset !== 'undefined') ? inc.p_tax_offset : 0;
        state.tax_saving= (typeof tb.tax_saving !== 'undefined') ? tb.tax_saving : 0;
        state.it_type= (typeof tb.it_type !== 'undefined') ? tb.it_type : it_type;
    }

    return state;
};

export const setTaxBenefitState = (tb) => {
    let state = {};
    if (tb) {
        state = {
            i_tax_offset: (typeof tb.i_tax_offset !== 'undefined') ? tb.i_tax_offset : 0,
            p_tax_offset: (typeof tb.p_tax_offset !== 'undefined') ? tb.p_tax_offset : 0,
            tax_offset: (typeof tb.tax_offset !== 'undefined') ? tb.tax_offset : 0,

            //tb
            i_income: (typeof tb.i_income !== 'undefined') ? tb.i_income : 0,
            p_income: (typeof tb.p_income !== 'undefined') ? tb.p_income : 0,
            income: (typeof tb.income !== 'undefined') ? tb.income : 0,
            i_rental_income: (typeof tb.i_rental_income !== 'undefined') ? tb.i_rental_income : 0,
            p_rental_income: (typeof tb.p_rental_income !== 'undefined') ? tb.p_rental_income : 0,
            rental_income: (typeof tb.rental_income !== 'undefined') ? tb.rental_income : 0,
            i_total_income: (typeof tb.i_total_income !== 'undefined') ? tb.i_total_income : 0,
            p_total_income: (typeof tb.p_total_income !== 'undefined') ? tb.p_total_income : 0,
            total_income: (typeof tb.total_income !== 'undefined') ? tb.total_income : 0,
            i_rental_deduction: (typeof tb.i_rental_deduction !== 'undefined') ? tb.i_rental_deduction : 0,
            p_rental_deduction: (typeof tb.p_rental_deduction !== 'undefined') ? tb.p_rental_deduction : 0,
            rental_deduction: (typeof tb.rental_deduction !== 'undefined') ? tb.rental_deduction : 0,
            i_new_tax_income: (typeof tb.i_new_tax_income !== 'undefined') ? tb.i_new_tax_income : 0,
            p_new_tax_income: (typeof tb.p_new_tax_income !== 'undefined') ? tb.p_new_tax_income : 0,
            new_tax_income: (typeof tb.new_tax_income !== 'undefined') ? tb.new_tax_income : 0,
            i_present_tax: (typeof tb.i_present_tax !== 'undefined') ? tb.i_present_tax : 0,
            p_present_tax: (typeof tb.p_present_tax !== 'undefined') ? tb.p_present_tax : 0,
            present_tax: (typeof tb.present_tax !== 'undefined') ? tb.present_tax : 0,
            i_new_tax: (typeof tb.i_new_tax !== 'undefined') ? tb.i_new_tax : 0,
            p_new_tax: (typeof tb.p_new_tax !== 'undefined') ? tb.p_new_tax : 0,
            new_tax: (typeof tb.new_tax !== 'undefined') ? tb.new_tax : 0,
            i_tax_saving: (typeof tb.i_tax_offset !== 'undefined') ? tb.i_tax_offset : 0,
            p_tax_saving: (typeof tb.p_tax_offset !== 'undefined') ? tb.p_tax_offset : 0,
            tax_saving: (typeof tb.tax_saving !== 'undefined') ? tb.tax_saving : 0,
        }
    }

    return state;
};

export const setCashflowTabState = (property) => {
    return setDefaultTabState(property);
};

export const setWhoPayState = (data) => {
    let state = {};
    if (data) {
        let whopay = data.who_pay_detail;
        let cash = data.cashflow_details;
        let cash_percentage = (typeof whopay.cash_percentage !== 'undefined') ? whopay.cash_percentage : 0;
        let tax_benefit_percentage = (typeof whopay.tax_benefit_percentage !== 'undefined') ? whopay.tax_benefit_percentage : 0;
        let rent_percentage = (typeof whopay.rent_percentage !== 'undefined') ? whopay.rent_percentage : 0;
        state = {
            after_cashflow: (typeof cash.after_cashflow !== 'undefined') ? cash.after_cashflow : 0,
            your_cost: (typeof cash.your_cost !== 'undefined') ? cash.your_cost : 0,
            year: (typeof whopay.year !== 'undefined') ? whopay.year : getWhoPayYear().value,
            principal_amount: (typeof whopay.principal_amount !== 'undefined') ? whopay.principal_amount : 0,
            interest_cost: (typeof whopay.interest_cost !== 'undefined') ? whopay.interest_cost : 0,
            rental_expenses: (typeof whopay.rental_expenses !== 'undefined') ? whopay.rental_expenses : 0,
            total_cost: (typeof whopay.total_cost !== 'undefined') ? whopay.total_cost : 0,
            rent: (typeof whopay.rent !== 'undefined') ? whopay.rent : 0,
            tax_benefit: (typeof whopay.tax_benefit !== 'undefined') ? whopay.tax_benefit : 0,
            cash: (typeof whopay.cash !== 'undefined') ? whopay.cash : 0,
            cash_weekly: (typeof whopay.cash_weekly !== 'undefined') ? whopay.cash_weekly : 0,
            total: (typeof whopay.total !== 'undefined') ? whopay.total : 0,
            rent_percentage: rent_percentage,
            tax_benefit_percentage: tax_benefit_percentage,
            cash_percentage: cash_percentage,
            chart_data: {
                labels: [
                    'You',
                    'Tax Credit',
                    'Tenant',
                ],

                datasets: [{
                    data: [cash_percentage, tax_benefit_percentage, rent_percentage],
                    backgroundColor: [
                        config.who_pay_color.you,
                        config.who_pay_color.tax_credit,
                        config.who_pay_color.tenant,
                    ],
                    hoverBackgroundColor: [
                        config.who_pay_color.you,
                        config.who_pay_color.tax_credit,
                        config.who_pay_color.tenant,
                    ]
                }]
            },
        }
    }

    return state;
};

export const setDefaultTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        setEntityId(prop.entity_id);
        setEntityType(prop.entity_type);
        setEntityName(prop.entity_name);
        setTypeOfProperty(prop.type_of_property);

        let fun = property.funding_structure;
        state = {
            //property details
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            // repayment
            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            interest_term: (typeof fun.interest_term !== 'undefined') ? fun.interest_term : 5,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 30,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            interest_term_back: (typeof fun.interest_term_back !== 'undefined') ? fun.interest_term_back : 5,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 30,
            loans: (typeof fun.loans !== 'undefined') ? fun.loans : [],

            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,

            is_existing_property: (parseInt(prop.type_of_property) === 1)
        }
    }

    return state;
};

export const setPurchaseSubscriptionState = (subscription_type, subscription_interval, qty, has_trial = false, discount = 0, discount_per = 0) => {

    let trial_days = config.trial_days;
    let interval = 'month';
    if (subscription_interval === 'YEARLY_INTERVAL') {
        interval = 'year';
    }
    let start_date = '';
    let end_date = '';
    if (has_trial) {
        start_date = moment().add(trial_days,'days').format('DD/MM/YYYY');
        end_date = moment().add(trial_days,'days').add(1, interval).format('DD/MM/YYYY');
    } else {
        start_date = moment().format('DD/MM/YYYY');
        end_date = moment().add(1, interval).format('DD/MM/YYYY');
    }

    let plan = getPlan(subscription_type, subscription_interval);
    let sub_total = 0;
    let tax = 0;
    let total = plan.price;
    let min_qty = plan.min_qty;
    let max_qty = plan.max_qty;
    if (qty < plan.default_user) {
        qty = plan.default_user;
    }
    let has_extra_user = plan.has_extra_user;
    if (has_extra_user) {
        if (qty > plan.default_user) {
            let netQty = qty - plan.default_user;
            total += (netQty * plan.extra_user_price);
        }
    }
    total = parseFloat(total).toFixed(2);
    let gst_percentage = config.gst_percentage;

    if (total && gst_percentage) {
        tax = parseFloat((gst_percentage * total) / (100 + gst_percentage)).toFixed(2);
        sub_total = parseFloat(total - tax).toFixed(2);
    }

    if (discount_per) {
        discount = (total * discount_per) / 100;
        discount = parseFloat(discount).toFixed(2);
    }

    if (discount) {
        if (discount > total) {
            discount = total;
        }
        total = parseFloat(total - discount).toFixed(2);
        if (total < 0) {
            total = 0;
        }
    }

    return {
        subscription_type: subscription_type,
        subscription_interval: subscription_interval,
        has_trial: has_trial,
        start_date: start_date,
        end_date: end_date,
        qty: qty,
        min_qty: min_qty,
        max_qty: max_qty,
        has_extra_user: has_extra_user,
        sub_price: sub_total,
        tax: tax,
        discount: discount,
        discount_per: discount_per,
        total: total,
    };
};

export const downloadReport = (data, fileName) => {

    let pdf_data = data.pdf;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        let byteCharacters = atob(pdf_data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], {
            type: 'application/pdf'
        });
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        // decode base64 string, remove space for IE compatibility
        let binary = atob(pdf_data.replace(/\s/g, ''));
        let len = binary.length;
        let buffer = new ArrayBuffer(len);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"
        let blob = new Blob([view], {type: "application/pdf"});
        window.URL = window.URL || window.webkitURL;
        let url = window.URL.createObjectURL(blob);
        //if want to open the pdf then uncomment below line
        //window.open(url);

        //to download new invoice file
        let downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = fileName;  //Name the file here
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
};

export const isInPropertyTab = () => {
    let link = window.location.hash;
    link = link.replace('#/', '');
    link = link.split("\/");
    link = '/' + link[0];
    let propertyLinks = [
        config.site_url.property_detail,
        config.site_url.acquisition_cost,
        config.site_url.funding_structure,
        config.site_url.investment_outcome,
        config.site_url.investment_expenses,
        config.site_url.economic_assumption,
        config.site_url.deduction,
        config.site_url.income,
        config.site_url.cashflow,
    ];

    return propertyLinks.includes(link);
};

export const isInDashboard = () => {
    let link = window.location.hash;
    link = link.replace('#/', '');
    link = link.split("\/");
    link = '/' + link[0];
    return (link.toString() === config.site_url.dashboard.toString());
};

export const onMenuOpen = () => {
    setTimeout(()=>{
        const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
        if(selectedEl){
            selectedEl.scrollIntoView({behavior:'smooth', block:'nearest', inline: 'start'});
        }
    },15);
};